function ImageTransform(params: {}) {
  return (
    <div>
      <p>请选出按正确规则和顺序变化后的图形组合。每题只有1个正确答案。需注意：</p>
      <p>
        （1）当图形带有多个操作符号时，根据先后顺序对图形进行连续变换；（2）后面的操作是基于前面变化后的图形进行的。
      </p>
    </div>
  )
}

export default ImageTransform
