import './index.scss'
import { message, Button, Timeline } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { startExam, examPlanSummary } from '../../../api/exam'
import queryString from 'query-string'
import { TitleEnum, EXAM_TYPE } from '../../../constants'
import { IQuestionSet } from '../../../types/exam'
import moment from 'moment'
import MultipleQuestion from '../../common/multipleQuestion'
import GroupQuestion from '../../common/groupQuestion'
import GroupCutQuestion from '../../common/groupCutQuestion'

function ExamDetail(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location.search)
  const planId = query.id || ''

  // 是否是正式考试 练习模式每一题结束后可以查看答案 考试模式不行
  const [isTest, setIsTest] = useState(false)

  const [loading, setLoading] = useState(false)

  // 套题总数
  const [totalQuestionSets, setTotalQuestionSets] = useState<number>(0)
  // 当前套题序号
  const [currentQuestionSetIndex, setCurrentQuestionSetIndex] = useState<number>(0)
  // 当前套题类型
  const [currentQuestionSetSubject, setCurrentQuestionSetSubject] = useState<string>('')
  // 当前套题测试用的examId
  const [examId, setExamId] = useState<string>('')
  // 考试自动刚结束时间戳,毫秒级
  const [timeout, setTimeout] = useState<number>(new Date().getTime())
  // 显示单个题型
  const [showMultipleQuestion, setShowMultipleQuestion] = useState(false)
  // 显示多个提组合提交题型
  const [showGroupQuestion, setShowGroupQuestion] = useState(false)
  // 显示多个提组合提交 提交数量为显示题型 -1
  const [showGroupCutQuestion, setShowGroupCutQuestion] = useState(false)
  // 问题数
  const [questions, setQuestions] = useState<IQuestionSet[]>([])

  useEffect(() => {
    onStartExam()
    getExamPlanSummary()
  }, [])

  const getExamPlanSummary = () => {
    setQuestions([])
    examPlanSummary({ planId }).then((res) => {
      if (res.data?.questionSets?.length) {
        setQuestions(res.data.questionSets)
      }
    })
  }

  const onStartExam = () => {
    setShowMultipleQuestion(false)
    setShowGroupQuestion(false)
    setShowGroupCutQuestion(false)
    setLoading(true)
    startExam({ planId })
      .then((res) => {
        // if (res.code === 6010) {
        //   // 跳转结果查看页
        //   history.push(`/exam-history?planId=${planId}`)
        //   return
        // }
        if (res.code !== 0) {
          throw res
          return
        }
        if (res.data.planState === 'END') {
          return message.info('考试已结束')
        }
        if (res.data.planState === 'TIME_UP_END') {
          return message.info('考试时间已结束')
        }
        if (!res.data.examId) {
          return message.error(`考试 ${planId} 缺少 examId，请联系老师`)
        }
        setIsTest(res.data.type === EXAM_TYPE.Practice)
        // 测试用的
        // setIsTest(true)
        setExamId(res.data.examId)
        setTimeout(res.data.timeout)
        setCurrentQuestionSetIndex(res.data.currentQuestionSetIndex)
        setCurrentQuestionSetSubject(res.data.currentQuestionSetSubject)
        setTotalQuestionSets(res.data.totalQuestionSets)

        handleStartExamTest(res.data.currentQuestionSetSubject)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error?.msg || '获取考试套题失败')
      })
  }

  const handleStartExamTest = (type: string) => {
    if (type === 'E808B' || type === 'E808D') {
      // 显示每页一组的试题
      setShowGroupQuestion(true)
    } else if (type === 'E808B2' || type === 'E808C') {
      // 显示每页一组，提交n-1
      setShowGroupCutQuestion(true)
    } else {
      // 显示每页一题的试题
      setShowMultipleQuestion(true)
    }
  }

  const cofirmCallBack = () => {
    onStartExam()
  }

  const onShowResult = () => {
    history.push(`/exam-history?id=${planId}`)
  }

  const handleBackHome = () => {
    history.replace('/')
  }

  const type = TitleEnum[currentQuestionSetSubject] || ''
  const title = isTest ? '模拟考试' : '随堂练习'
  return (
    <div className="exam-detail">
      <div className="time-line-box">
        <h4>题型列表</h4>
        <Timeline>
          {(questions || []).map((question, index) => {
            return (
              <Timeline.Item
                color={question.subject === currentQuestionSetSubject ? 'red' : 'blue'}
                key={question.subject + index}
              >
                {TitleEnum[question.subject] || question.subjectName || '-'}
              </Timeline.Item>
            )
          })}
        </Timeline>
      </div>

      {!showMultipleQuestion && !showGroupQuestion && !showGroupCutQuestion ? (
        <div className="text-center padding-top-200">
          <h3>{title}结束</h3>
          {isTest ? (
            <div>
              <p>您参加的考试已结束，考试成绩请咨询老师.</p>
              <Button onClick={handleBackHome}>回到首页</Button>
            </div>
          ) : (
            <p>
              <Button onClick={() => onShowResult()}>查看成绩</Button>
            </p>
          )}
        </div>
      ) : (
        <div>
          <h2 className="title text-center">{title}</h2>
          <p className="text-center">
            结束时间：{moment(timeout).format('YYYY-MM-DD HH:mm:ss')}，当前第{' '}
            {currentQuestionSetIndex} 个题型，总共 {totalQuestionSets} 个题型
            {type ? <span>，当前题型类型：{type}</span> : ''}
          </p>

          {showMultipleQuestion ? (
            <MultipleQuestion
              examId={examId}
              isExam={true}
              callback={() => {
                cofirmCallBack()
              }}
            />
          ) : (
            ''
          )}
          {showGroupQuestion ? (
            <GroupQuestion
              examId={examId}
              isExam={true}
              callback={() => {
                cofirmCallBack()
              }}
            />
          ) : (
            ''
          )}
          {showGroupCutQuestion ? (
            <GroupCutQuestion
              examId={examId}
              isExam={true}
              callback={() => {
                cofirmCallBack()
              }}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}

export default ExamDetail
