import {
  Button,
  Select,
  Image,
  Modal,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Radio,
  InputNumber,
  message,
  Divider,
} from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { getDefaultSettings } from 'http2'
import { useEffect, useState } from 'react'
import {
  addExamPlanQuestionSet,
  deleteExamPlanQuestionSet,
  getExamDetail,
  getExamPlanDetail,
  IPlanPayload,
  IPlanType,
  IUnitInfo,
  previewQuestionSet,
  queryExamPlanQuestionSet,
  updateExamPlanSubject,
} from '../../../../../api/admin/exam'
import Cube from '../../../../../component/Cube'
import { TitleEnum } from '../../../../../constants'
import { SUCCESS_CODE } from '../../../../../lib/config'
import {
  IExamPlanQuestionSetRequest,
  IExamPlanQuestionSetResponse,
  IPreviewProbleType,
  PreviewDataItem,
} from '../../../../../types/exam'
import { isThreeProblem } from '../../../../../utils/util'
import './index.scss'
import LogicPreivew from './logicPorblem'

const { Option } = Select

interface IProps {
  planId: number
  onShow: () => void
  onClose: () => void
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

export const ProblemGroupLimit: Record<any, number> = {
  'FlashDiamond': 1,
  'ImageTransform': 1,
  'BlockRotate': 1,
  'FillWall': 1,
  'CubeComplement': 1,
  'E808A': 1,
  'E808APlus': 1,
  'E808E': 1,
  'E808EPlus': 1,
  'Moss': 1,
  'MossCopy': 1,
  'WhackAMole': 1,
  'HarderMoss': 1,
  'LogicalReasoning': 1,
  'E808B': 12,
  'E808B2': 12,
  'E808C': 12,
  'E808D': 5,
};

export default function ProblemsList(props: IProps) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isFirstConfig, setIsFirstConfig] = useState<boolean>(false)
  const [isSecondConfig, setIsSecondConfig] = useState<boolean>(false)
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false)
  const [isPreviewLogicalModalVisible, setIsPreviewLogicalModalVisible] = useState<boolean>(false)
  const [threeProblem, setThreeProblem] = useState<IPreviewProbleType>()
  const [tempExam, setTempExam] = useState<string>('')
  const [previewData, setPreviewData] = useState<PreviewDataItem[]>([])
  const [currentSet, setCurrentSet] = useState<IUnitInfo>()
  const [randSeed, setRandSeed] = useState<number>(-1)
  const [firstProblems, setFirstProblems] = useState<IUnitInfo[]>([])
  const [secondProblems, setSecondProblems] = useState<IUnitInfo[]>([])
  const [planData, setPlanData] = useState<IPlanPayload>({} as IPlanPayload)

  useEffect(() => {
    getDetailPlan()
  }, [props.planId])

  const getDetailPlan = async () => {
    const { data } = await getExamPlanDetail({ id: props.planId })
    setPlanData(data)
    const { data: firstData } = await queryExamPlanQuestionSet({ testId: data.tests.first.id })
    setFirstProblems(firstData)
    const { data: secondData } = await queryExamPlanQuestionSet({ testId: data.tests.second.id })
    setSecondProblems(secondData)
  }

  const handleDeleteProblemSet = (setId: number) => {
    deleteExamPlanQuestionSet({ id: setId }).then((res) => {
      if (res.code === SUCCESS_CODE) {
        message.success('删除成功')
        getDetailPlan()
      }
    })
  }

  const handlePreviewProblemSet = (item: IUnitInfo) => {
    setCurrentSet(item)
    previewQuestionSet({
      size: item.totalQuestions,
      unitId: item.id
    }).then((res) => {
      if (res.code === SUCCESS_CODE) {
        // setThreeProblem(res.data)
        setRandSeed(res.data.randSeed)
        setTempExam(res.data.examId)
        setIsPreviewModalVisible(true)
      }
    })
  }

  const handlePreviewProblemLogical = (item: IUnitInfo) => {
    setCurrentSet(item)
    setIsPreviewLogicalModalVisible(true)
  }

  const renderModal = () => {
    const [form] = Form.useForm()
    const [subject, setSubject] = useState<string>('')

    const handleEnterAdd = () => {
      form
        .validateFields()
        .then((values) => {
          console.log(values)
          if (values.questionGroupIds) {
            values.questionGroupIds = values.questionGroupIds.split(',')
          }
          values.totalQuestions = Number(values.totalQuestions) * ProblemGroupLimit[values.type]
          let testId
          if (isFirstConfig) {
            testId = planData.tests.first.id
          } else {
            testId = planData.tests.second.id
          }
          addExamPlanQuestionSet({
            testId,
            ...values,
          }).then((res) => {
            if (res.code === SUCCESS_CODE) {
              message.success('添加成功')
              setIsModalVisible(false)
              getDetailPlan()
            }
          })
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
        })
    }

    const handleChangeSetType = (val: string) => {
      setSubject(val)
    }

    return (
      <Modal
        title="题型配置"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText="确定"
        cancelText="取消"
        onOk={handleEnterAdd}
        maskClosable={false}
      >
        <Form name="添加题型" form={form} {...formItemLayout}>
          <Form.Item
            name="type"
            label="题目类型"
            rules={[{ required: true, message: '请选择题目类型' }]}
          >
            <Select value={subject} style={{ width: 120 }} onChange={handleChangeSetType}>
              {Object.keys(TitleEnum).map((item) => (
                <Option key={item} value={item}>
                  {TitleEnum[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="totalQuestions"
            label="题目数量"
            rules={[{ required: true, message: '请设置题目数量' }]}
          >
            <InputNumber min={0} style={{ width: 120 }} />
          </Form.Item>
          <Form.Item
            name="totalMinutes"
            label="单元时间（分钟）"
            rules={[{ required: true, message: '请设置考试时间(单位秒)' }]}
          >
            <InputNumber min={0} style={{ width: 120 }}></InputNumber>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const renderLogicalModal = () => {
    return (
      <Modal
        title="题目预览配置"
        visible={isPreviewLogicalModalVisible}
        onCancel={() => {
          setIsPreviewLogicalModalVisible(false)
        }}
        width={'100%'}
        footer={null}
        okText="确定"
        cancelText="取消"
        onOk={() => { }}
        maskClosable={false}
      >
        <LogicPreivew data={currentSet?.questionIds!} onSave={(_) => {
          const paylaod = { ...currentSet }
          paylaod.questionIds = _
          updateExamPlanSubject(paylaod as IUnitInfo).then((res) => {
            if (res.code === SUCCESS_CODE) {
              getDetailPlan()
              setIsPreviewLogicalModalVisible(false)
            }
          })
        }} limit={currentSet?.totalQuestions!}></LogicPreivew>
      </Modal>
    )
  }

  const renderThreePreviewModal = () => {
    const [currentProblemIndex, setCurrentProblemIndex] = useState<number>(0)
    const [delay, setDelay] = useState<boolean>(false)

    useEffect(() => {
      console.log(currentSet)
      setCurrentProblemIndex(0)
      if (currentSet) {
        let currentPreviewData = JSON.parse(currentSet.runtimeParams ?? '[]')
        currentPreviewData = currentPreviewData?.map((item: string) => {
          return JSON.parse(item)
        })
        console.log(currentPreviewData)
        setPreviewData(currentPreviewData as PreviewDataItem[])
        getExamDetail({ examId: tempExam }).then(res => {
          setThreeProblem(res.data)
        })
      }
      setTimeout(() => {
        setDelay(true)
      }, 2000)
      return () => {
        setDelay(false)
      }
    }, [isPreviewModalVisible])

    const onHandleSaveImage = (isDesc: boolean, localIndex: number, imgUrl: string) => {
      console.log(currentProblemIndex, imgUrl, isDesc, localIndex)
      const tempPreviewData = [...previewData]
      if (!tempPreviewData[currentProblemIndex]) {
        tempPreviewData[currentProblemIndex] = { question: '', answers: [] }
      }
      if (isDesc) {
        tempPreviewData[currentProblemIndex].question = imgUrl
      } else {
        tempPreviewData[currentProblemIndex].answers[localIndex] = imgUrl
      }
      console.log(tempPreviewData)
      setPreviewData(tempPreviewData)
      message.success('保存成功')
    }

    const checkIsSaveAll = () => {
      const currentProblem = previewData && previewData[currentProblemIndex]
      if (
        currentProblem?.question &&
        currentProblem?.answers?.length ===
        threeProblem?.list[currentProblemIndex]?.answers.length &&
        Array.from(currentProblem?.answers)?.every((item) => !!item)
      ) {
        return true
      }
      return false
    }

    const handleClickNext = () => {
      if (checkIsSaveAll()) {
        if (threeProblem) {
          if (currentProblemIndex < threeProblem.list.length - 1) {
            setCurrentProblemIndex((val) => val + 1)
          } else {
            // update examplan
            if (currentSet) {
              const payloadPreviewData = previewData.map((data) => {
                return JSON.stringify(data)
              })
              const paylaod = { ...currentSet }
              paylaod.runtimeParams = JSON.stringify(payloadPreviewData)
              updateExamPlanSubject(paylaod).then((res) => {
                if (res.code === SUCCESS_CODE) {
                  getDetailPlan()
                }
              })
            }
            setIsPreviewModalVisible(false)
          }
        }
      } else {
        message.warning('本题没有完成所有图片的保存，请检查')
      }
    }

    return (
      <Modal
        title="题目预览配置"
        visible={isPreviewModalVisible}
        onCancel={() => {
          setIsPreviewModalVisible(false)
          setDelay(false)
        }}
        width={'100%'}
        footer={null}
        okText="确定"
        cancelText="取消"
        onOk={() => { }}
        maskClosable={false}
      >
        {!delay && <span>3d渲染中, 2s后开始配置...</span>}
        {delay && threeProblem && (
          <>
            <Divider>题目: {TitleEnum[threeProblem.subject]}</Divider>
            <div className="preview-top">
              <Cube
                onSaveAsImage={onHandleSaveImage}
                isShowOperator={true}
                isDesc={true}
                localIndex={0}
                data={threeProblem.list[currentProblemIndex].question}
                width={300}
                height={300}
                rotateCamera={false}
                isHand={false}
              />
              <div className="preview-top-right">
                <div className="preview-top-right-des">
                  <Image width={100} src={previewData[currentProblemIndex]?.question ?? ''}></Image>
                </div>
                <div>
                  {threeProblem?.list[currentProblemIndex]?.answers.map((item, i) => (
                    <Image
                      key={i}
                      width={100}
                      src={previewData[currentProblemIndex]?.answers[i]}
                    ></Image>
                  ))}
                </div>
              </div>
            </div>
            <Divider>选项预览</Divider>
            <div className="preview-answers">
              {threeProblem?.list[currentProblemIndex]?.answers.map((item, i) => (
                <div key={i} className="preview-item">
                  <Cube
                    isShowOperator={true}
                    onSaveAsImage={onHandleSaveImage}
                    isDesc={false}
                    localIndex={i}
                    data={item}
                    width={170}
                    height={170}
                    rotateCamera={false}
                    isHand={false}
                  />
                </div>
              ))}
            </div>
            <div className="preview-button">
              <Button
                onClick={() => {
                  if (currentProblemIndex > 0) {
                    setCurrentProblemIndex((val) => val - 1)
                  } else {
                    setIsPreviewModalVisible(false)
                  }
                }}
              >
                {currentProblemIndex > 0 ? '上一题' : '关闭'}
              </Button>
              <Button onClick={handleClickNext}>
                {currentProblemIndex < threeProblem.list.length - 1 ? '下一题' : '提交预览结果'}
              </Button>
            </div>
          </>
        )}
      </Modal>
    )
  }

  const columns: ColumnsType<IUnitInfo> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      render: (record: string) => {
        return TitleEnum[record]
      },
    },
    {
      title: '数量',
      dataIndex: 'totalQuestions',
      key: 'totalQuestions',
      render: (record) => {
        return record || '-'
      },
    },
    {
      title: '时间',
      dataIndex: 'totalMinutes',
      key: 'totalMinutes',
      render: (record) => {
        return `${record || '-'}分钟`
      },
    },
    // {
    //   title: 'id列表',
    //   dataIndex: 'questionIds',
    //   key: 'questionIds',
    //   render: (record) => {
    //     return record || '-'
    //   },
    // },
    {
      title: '配置状态',
      dataIndex: 'questionIds',
      key: 'questionIds',
      render: (_, record) => {
        if (record.type === 'LogicalReasoning') {
          return record.questionIds?.length === record.totalQuestions ? '配置完成' : '未配置'
        } else if (isThreeProblem(record.type)) {
          return record.runtimeParams?.length > 0 ? '配置完成' : '未配置'
        } else {
          return '配置完成'
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handleDeleteProblemSet(record.id)}>删除</a>
          {(isThreeProblem(record.type)) && (
            <a onClick={() => handlePreviewProblemSet(record)}>配置3D</a>
          )}
          {record.type === 'LogicalReasoning' && (
            <a onClick={() => handlePreviewProblemLogical(record)}>配置逻辑推理</a>
          )}
        </Space>
      ),
    },
  ]

  return (
    <>
      <Modal
        title="考试题目配置"
        onCancel={() => {
          props.onClose && props.onClose()
        }}
        width={'100%'}
        visible={true}
        okText="确定"
        cancelText="取消"
        onOk={() => {
          props.onShow && props.onShow()
        }}
        maskClosable={false}
      >
        <h3>初试</h3>
        <Button onClick={() => {
          setIsModalVisible(true);
          setIsSecondConfig(false)
          setIsFirstConfig(true)
        }
        } type="primary" style={{ marginBottom: 16 }}>
          增加初试题型
        </Button>
        <Table rowKey={(record) => record.id} columns={columns} dataSource={firstProblems} />
        <h3>复试</h3>
        <Button onClick={() => {
          setIsModalVisible(true)
          setIsFirstConfig(false)
          setIsSecondConfig(true)
        }
        } type="primary" style={{ marginBottom: 16 }}>
          增加复试题型
        </Button>
        <Table rowKey={(record) => record.id} columns={columns} dataSource={secondProblems} />
      </Modal>
      {renderModal()}
      {renderThreePreviewModal()}
      {renderLogicalModal()}
    </>
  )
}
