import { memo, useEffect, useState, useRef, useCallback } from 'react'
import { throttle, debounce } from 'lodash'
import './WhackAMole.scss'
import { message } from 'antd'

interface IWhackAMoleProps {
  data: number[]
  addSelect: (select: number[]) => void
}

function WhackAMoles(props: IWhackAMoleProps) {
  const { addSelect, data } = props
  const [isRight, setIsRight] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [currentClick, setCurrentClick] = useState<number>(-1)
  const [select, setSelect] = useState<number[]>([])
  const [currentRightLoc, setCurrentRightLoc] = useState<number>(-1)

  const [top, setTop] = useState<number>(0)
  const [left, setLeft] = useState<number>(0)
  const activeArea = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelect([])
  }, [data])

  useEffect(() => {
    activeArea.current?.addEventListener(
      'mousemove',
      throttle((e) => {
        if (e.layerX >= 0 && e.layerY >= 0) {
          setTop(e.pageY - 33)
          setLeft(e.pageX - 33)
        }
      }, 30),
    )
    return () => {
      activeArea.current?.removeEventListener('mousemove', () => {})
    }
  }, [])

  const onHandleClick = useCallback(() => {
    if (!loading) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [loading])

  const handleClickBox = (i: number) => {
    if (!loading) {
      setCurrentClick(i)
      console.log(i)
      const tempSelect = [...select, i]
      const index = tempSelect.length - 1
      setCurrentRightLoc(data[index])

      if (data[index] === i) {
        setIsRight(true)
      } else {
        setIsRight(false)
      }
      setSelect(tempSelect)
      addSelect(tempSelect)
    }
  }

  return (
    <div ref={activeArea} onClick={onHandleClick} className="whack-container">
      <img
        className={['whack-tool', `${loading ? 'run' : ''}`].join(' ')}
        style={{
          top: top + 'px',
          left: left + 'px',
        }}
        alt=""
        width={65}
        src="https://edudata.computingis.fun/chuizi.png"
      ></img>
      <div className="whack-result-content">
        {isRight && loading && (
          <img alt="" width={90} src="https://edudata.computingis.fun/bang.png"></img>
        )}
        {!isRight && loading && (
          <img alt="" width={90} src="https://edudata.computingis.fun/cuo.png"></img>
        )}
      </div>
      <div className="whack-content">
        <div className="mole-content">
          {[0, 1, 2, 3].map((item) => (
            <div
              key={item}
              className={['box', `box-${item}`, `${currentClick === item ? 'run-shake' : ''}`].join(
                ' ',
              )}
              onClick={() => {
                handleClickBox(item)
              }}
            >
              {currentRightLoc === item && loading && (
                <img
                  className="whack-item"
                  alt=""
                  width={85}
                  src="https://edudata.computingis.fun/dishu.png"
                ></img>
              )}{' '}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(WhackAMoles)
