import React, { useEffect, useState } from 'react'
import './index.scss'
import { Card, Button, InputNumber, Drawer, Form, message } from 'antd'
import { queryExamSubjectDailyLimit, updateExamSubjectDailyLimit } from '../../../api/admin/answer'

import { Category, Subject, ITypeSubjectItem } from '../../../constants'

function answerCountManage() {
  const [size, setSize] = useState(0)
  const [key, setKey] = useState('')
  const [title, setTitle] = useState('')
  const [visible, setVisible] = useState(false)
  const [sizes, setSizes] = useState<any>({})

  useEffect(() => {
    queryExamSubjectDailyLimit().then((res) => {
      if (res.data) {
        setSizes(res.data)
      }
    })
  }, [])

  const handleUpdate = (item: ITypeSubjectItem) => {
    setSize(0)
    setKey(item.key)
    setTitle(item.maps)
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
    setSize(0)
    setTitle('')
  }

  const handleSubmit = () => {
    if (typeof size !== 'number') {
      return
    }
    if (size === sizes[key]) {
      return
    }
    updateExamSubjectDailyLimit({ examSubject: key, size }).then((res) => {
      if (res.code === 0) {
        setSizes({ ...sizes, [key]: size })
        message.success('修改成功')
        onClose()
      } else {
        message.error('修改失败：' + res.code)
      }
    })
  }
  const handleChange = (value: number | null) => {
    if (value) setSize(value)
  }

  const getList = (items: ITypeSubjectItem[]) => {
    return items.map((item) => (
      <div className="item" key={item.key}>
        <p className="item-name">
          {item.maps}（{sizes[item.key] || 0}）
        </p>
        <Button size="small" type="primary" onClick={() => handleUpdate(item)}>
          修改
        </Button>
      </div>
    ))
  }

  const CardList = Object.keys(Subject).map((key) => {
    return (
      <Card className="type-card" title={Category[key] || ''} key={key}>
        {getList(Subject[key])}
      </Card>
    )
  })
  return (
    <div className="answer-count-manage">
      {CardList}

      {visible && (
        <Drawer
          title={'修改' + title}
          placement="right"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="次数"
              name="username"
              rules={[{ required: true, message: '次数不能为空' }]}
            >
              <InputNumber value={size} onChange={handleChange} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" onClick={handleSubmit}>
                修改
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
    </div>
  )
}

export default answerCountManage
