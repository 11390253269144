import { Menu, message, Badge, Divider, Drawer, Button } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { AppstoreOutlined, SettingOutlined } from '@ant-design/icons'
import { queryExamSubjectDailyReamins } from '../../api/subjectTest'
import { useHistory } from 'react-router-dom'
import Tips from '../tips'
import { GlobalStoreContext } from '../../store'
import { Subject, Category, ITypeSubjectItem, GroupList, GroupCutList } from '../../constants'
import HeaderNav from '../../component/HeaderNav'
// import E808A from '../../component/E808A'
import './index.scss'
import { startTest } from '../../api/answer'
import { getUserInfo } from '../../api/userInfo'
import { IUserInfo } from '../../types/user'
import ExamList from './exam-list/index'

const { SubMenu } = Menu

const data = [
  { x: 0, y: 0, z: 0 },
  { x: 1, y: 0, z: 0 },
  { x: 2, y: 0, z: 0 },
]

function Home() {
  const history = useHistory()
  const { state, dispatch } = useContext(GlobalStoreContext)
  const [current, setCurrent] = useState<string>('training')
  const [currentCategory, setCurrentCategory] = useState<string>('')
  const [visible, setVisible] = useState(false)
  const [userInfo, setUserInfo] = useState<IUserInfo>()
  const [remains, setRemains] = useState<any>()

  useEffect(() => {
    queryExamSubjectDailyReamins().then((data) => {
      console.log(data)
      setRemains(data)
    })
  }, [])

  useEffect(() => {
    getUserInfo().then((res) => {
      console.log(res)
      // setUserInfo(res)
      if (res?.data?.info) {
        setUserInfo(res.data.info)
        dispatch({
          type: 'setUserInfo',
          payload: res.data.info,
        })
      }
    })
  }, [])

  const handleClick = (e: any) => {
    setCurrent(e.key)
  }

  const onHandleClickType = (type: string) => {
    setVisible(true)
    setCurrentCategory(type)
  }

  const onHandleClickSubCategory = (sub: ITypeSubjectItem) => {
    // 次数-99代表上课中
    if (remains[sub.key] > 0 || remains[sub.key] === -99) {
      // switch (sub.key) {
      // case 'FlashDiamond':
      // startTest = startDiamondTransformTest
      // break
      // case 'ImageTransform':
      // startTest = startImageTransformTest
      // break
      // case 'BlockRotate':
      // startTest = startBlockRotateTest
      // break
      // case 'FillWall':
      // startTest = startFillWallTest
      // break
      // case 'CubeComplement':
      // startTest = startCubeComplementTest
      // break
      // case 'E808A':
      // startTest = startE808ATest
      // break
      // case 'E808APlus':
      // startTest = startE808APlusTest
      // break
      // case 'E808B':
      // startTest = startE808BTest
      // break
      // case 'E808B2':
      // startTest = startE808B2Test
      // break
      // case 'E808C':
      // startTest = startE808CTest
      // break
      // case 'E808D':
      // startTest = startE808DTest
      // break
      // case 'E808E':
      // startTest = startE808ETest
      // break
      // case 'E808EPlus':
      // startTest = startE808EPlusTest
      // break
      // case 'LogicalReasoning':
      // startTest = startLogicalReasoningTest
      // break
      // default:
      // startTest = startDiamondTransformTest
      // break
      // }
      startTest(sub.key).then((res) => {
        console.log(res)
        const examId = res.data.examId

        if (GroupList.includes(sub.key)) {
          // 跳转至每页一组的试题
          history.push(`/answer-group?examId=${examId}&subject=${sub.key}`)
        } else if (GroupCutList.includes(sub.key)) {
          history.push(`/answer-e808b2?examId=${examId}&subject=${sub.key}`)
        } else {
          // 跳转至每页一题的试题
          history.push(`/answer?examId=${examId}&subject=${sub.key}`)
        }
      })
    } else {
      message.success('已超过练习次数上限，请明日再来哦～')
    }
  }

  const onCloseDrawer = () => {
    setVisible(false)
  }

  const renderTrainingView = () => {
    return (
      <div className="training-main">
        <h3 className="">一般能力检测专题</h3>
        <div className="training-title">
          <div onClick={() => onHandleClickType('reasoning')} className="training-subtitle hover">
            {Category.reasoning}
          </div>
          <div
            onClick={() => onHandleClickType('stereoscopic')}
            className="training-subtitle hover"
          >
            {Category.stereoscopic}
          </div>
          <div onClick={() => onHandleClickType('member')} className="training-subtitle hover">
            {Category.member}
          </div>
        </div>
        <Divider />
        <h3 className="">808神经类型测试专题</h3>
        <div className="training-title">
          <div
            onClick={() => onHandleClickType('e808Invariant')}
            className="training-subtitle hover"
          >
            {Category.e808Invariant}
          </div>
          <div onClick={() => onHandleClickType('e808Vanish')} className="training-subtitle hover">
            {Category.e808Vanish}
          </div>
          <div
            onClick={() => onHandleClickType('e808Ealculate')}
            className="training-subtitle hover"
          >
            {Category.e808Ealculate}
          </div>
        </div>
        <Divider />
        <h3 className="">考试练习</h3>
        <div className="training-title">
          <div className="training-subtitle disabled">初试</div>
          <div className="training-subtitle disabled">复试</div>
        </div>
      </div>
    )
  }

  const renderExamView = () => {
    return <div className="exam-view">统考练习功能即将开放</div>
    // return <ExamList />
  }

  const renderDrawer = () => {
    return (
      <div>
        <Drawer
          title={Category[currentCategory]}
          placement="bottom"
          onClose={onCloseDrawer}
          visible={visible}
        >
          <div className="category-buttons">
            {Subject[currentCategory]?.map((sub) => {
              return (
                <div className="badge" key={sub.key}>
                  <Badge
                    count={
                      remains[sub.key] === -99
                        ? '上课中'
                        : remains[sub.key] < 0
                        ? 0
                        : remains[sub.key]
                    }
                  >
                    <Button
                      onClick={() => onHandleClickSubCategory(sub)}
                      type="ghost"
                      size="large"
                      shape="round"
                    >
                      {sub.maps}
                    </Button>
                  </Badge>
                </div>
              )
            })}
          </div>
        </Drawer>
      </div>
    )
  }

  return (
    <div>
      <HeaderNav
        name={userInfo?.nickName || ''}
        title={'速记星超常儿童智力甄别和训练系统'}
        isAdmin={userInfo?.userTag === 'ADMIN'}
        redirect={'/'}
      />
      <div className="container">
        <Menu
          onClick={handleClick}
          mode="horizontal"
          items={[
            { key: 'training', label: '我的练习', icon: <AppstoreOutlined /> },
            { key: 'exam', label: '课堂小考', icon: <SettingOutlined /> },
          ]}
        ></Menu>
        {current === 'training' ? renderTrainingView() : renderExamView()}
        {renderDrawer()}

        {/* <E808A data="GRB" width={100} height={100} /> */}
      </div>
    </div>
  )
}

export default Home
