import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const COLOR_ENUM: any = {
  G: 'green',
  R: 'red',
  B: 'blue',
  Y: 'yellow',
}

function E808D({ data, width, height }: { data: string; width: number; height: number }) {
  const [colorList, setColorList] = useState<string[]>([])

  const [canvasId, setCanvasId] = useState<string>(uuidv4())

  useEffect(() => {
    if (data) {
      const arr: string[] = data.split('')
      if (arr.length === 4) {
        setColorList([...arr])
      }
    }
  }, [data])

  useEffect(() => {
    renderCanvas()
  }, [colorList])

  const renderCanvas = () => {
    if (colorList.length !== 4) {
      return
    }
    const canvasDom: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
    if (!canvasDom) {
      return
    }
    const ctx: CanvasRenderingContext2D = canvasDom.getContext('2d') as CanvasRenderingContext2D
    // 找出三个点位置
    const centerX = Math.floor(width / 2)
    const centerY = Math.floor(height / 2)

    // 三角形的三个定位点
    const top: [number, number] = [centerX, 2]
    const left: [number, number] = [2, height - 1]
    const right: [number, number] = [width - 2, height - 2]

    // 立体三角形的顶点
    const peak: [number, number] = [centerX, 2 + Math.floor((height / 11) * 6)]

    // 颜色
    const colors: string[] = colorList.map((c) => COLOR_ENUM[c] || 'rgba: 0,0,0,0')

    ctx.clearRect(0, 0, width, height) // 清除画布

    const renderSymbol: any = {
      front: () => {
        ctx.beginPath()
        ctx.moveTo(...peak)
        ctx.lineTo(...left)
        ctx.lineTo(...right)
        ctx.lineTo(...peak)
        // ctx.strokeStyle = colors[0]
        // ctx.stroke()
        ctx.fillStyle = colors[0]
        ctx.fill()
        ctx.closePath()
      },
      left: () => {
        ctx.beginPath()
        ctx.moveTo(...peak)
        ctx.lineTo(...left)
        ctx.lineTo(...top)
        ctx.lineTo(...peak)
        // ctx.strokeStyle = colors[0]
        // ctx.stroke()
        ctx.fillStyle = colors[1]
        ctx.fill()
        ctx.closePath()
      },
      right: () => {
        ctx.beginPath()
        ctx.moveTo(...peak)
        ctx.lineTo(...right)
        ctx.lineTo(...top)
        ctx.lineTo(...peak)
        // ctx.strokeStyle = colors[0]
        // ctx.stroke()
        ctx.fillStyle = colors[2]
        ctx.fill()
        ctx.closePath()
      },
    }

    ctx.lineWidth = 2
    // 正面
    renderSymbol.front()
    // 左面
    renderSymbol.left()
    // 右面
    renderSymbol.right()
  }

  if (colorList.length !== 4) {
    return null
  }
  return <canvas id={canvasId} width={width} height={height}></canvas>
}
export default E808D
