// 图形变化规则
const ImageTransform = {
  SwapNo1: '与第一个图形交换',
  SwapNo2: '与第二个图形交换',
  SwapNo3: '与第三个图形交换',
  SwapNo4: '与第四个图形交换',
  ReplaceAsNo1: '替换为第一个图形',
  ReplaceAsNo2: '替换为第二个图形',
  ReplaceAsNo3: '替换为第三个图形',
  ReplaceAsNo4: '替换为第四个图形',
  FlipHorizontal: '水平翻转',
  FlipVertical: '垂直翻转',
  CW90: '顺时针旋转90度',
  CW180: '顺时针旋转180度',
  CCW90: '逆时针旋转90度',
  ReversedPhaseAll: '图形内白色和黑色互换',
  BlackToWhite: '图形内黑色部分变白色',
  WhiteToBlack: '图形内白色部分变黑色',
}

// 舒尔特方格规则
const FlashDiamond = {
  ReversedPhaseAll: '所有格子变为相反色',
  FlipHorizontal: '上部和下部互换',
  FlipVertical: '左部和右部互换',
  CW90: '顺时针旋转90度',
  CCW90: '逆时针旋转90度',
  AboveBlackToWhite: '上部分黑色变白色',
  BelowBlackToWhite: '下部分黑色变白色',
  LeftBlackToWhite: '左部分黑色变白色',
  RightBlackToWhite: '右部分黑色变白色',
  AboveWhiteToBlack: '上部分白色变黑色',
  BelowWhiteToBlack: '下部分白色变黑色',
  LeftWhiteToBlack: '左部分白色变黑色',
  RightWhiteToBlack: '右部分白色变黑色',
  ReversedPhase0: '第1格变为相反色',
  ReversedPhase1: '第2格变为相反色',
  ReversedPhase2: '第3格变为相反色',
  ReversedPhase3: '第4格变为相反色',
}

// 规则
export const TRANSFORM_RULE_TEXT = {
  ImageTransform,
  FlashDiamond,
}
