import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Card,
  Button,
  Select,
  Image,
  notification,
  Table,
  InputNumber,
  Space,
  Drawer,
  Form,
  message,
  Modal,
  Upload,
  Spin,
  Input,
} from 'antd'
import * as qiniu from 'qiniu-js'
import { IPageInfo } from '../../../../types/common'
import { IQuestion } from '../../../../types/question'
import {
  deleteLogicalreasoning,
  getLogicalreasoningList,
  getQuestionGroupList,
  zipUpload,
} from '../../../../api/admin/question'
import { getQiniuTokenn } from '../../../../api/common'

function questionManage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<IQuestion[]>([])
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [total, setTotal] = useState<number>(0)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [errorsQuestion, setErrorsQuestion] = useState<string[]>([])
  const [successCnt, setSuccessCnt] = useState<number>(0)
  const [zipUrl, setZipUrl] = useState<string>('')
  const [failCnt, setFailCnt] = useState<number>(0)
  const [questionGroupList, setQuestionGroupList] = useState<{ groupName: string; id: number }[]>(
    [],
  )
  const [zipModalVisible, setZipModalVisible] = useState<boolean>(false)
  const [selectGroup, setSelectGroup] = useState<number>()

  useEffect(() => {
    getList()
    getQuestionGroup()
  }, [pageInfo])

  const getQuestionGroup = () => {
    getQuestionGroupList({ page: 0, pageSize: 100000 }).then((res) => {
      if (res.code === 0) {
        setQuestionGroupList(res.data.list)
      }
    })
  }

  const getList = () => {
    getLogicalreasoningList(pageInfo).then((res) => {
      if (res.code === 0) {
        setTotal(res.data.total)
        setList(res.data.list)
      }
    })
  }

  const handleChangeGroup = (val: string) => {
    setSelectGroup(Number(val))
  }

  const uploadFile = (file: File, key: string, token: string) => {
    const observer = {
      next(res: any) {
        // ...
      },
      error(err: any) {
        console.log(err)
        // ...
      },
      complete(res: any) {
        console.log(res, '🚀 res')
        notification.success({
          message: '题目包上传',
          description: '题目包上传成功,选择分组后完成继续完题目解析',
        })
        setLoading(true)
        const zipUrl = `https://edudata.computingis.fun/${res.key}`
        setZipUrl(zipUrl)
        setZipModalVisible(true)
      },
    }
    const observable = qiniu.upload(file, key, token)
    const subscription = observable.subscribe(observer) // 上传开始
  }
  const handleBeforeUpload = async (file: any) => {
    const res = await getQiniuTokenn()
    uploadFile(file, new Date().getTime() + '-' + file.name, res.data)
  }

  const handleUpload = () => {
    zipUpload({ zipUrl: zipUrl, groupId: selectGroup! }).then((result) => {
      setLoading(false)
      setErrorsQuestion(result.data.errors)
      setFailCnt(result.data.fail)
      setSuccessCnt(result.data.success)
      setIsShowModal(true)
    })
  }

  const handleDelete = (question: IQuestion) => {
    Modal.confirm({
      title: '删除确认',
      content: `你确认要删除题目（${question.id}）吗？`,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        deleteLogicalreasoning({ id: question.id }).then((res) => {
          if (res.code === 0) {
            message.success('删除成功')
            setPageInfo({ ...pageInfo, page: 0 })
          } else {
            message.success('删除失败：', res.message)
          }
        })
      },
    })
  }

  const handleOk = () => {
    setIsShowModal(false)
  }

  const handleCancel = () => {
    setIsShowModal(false)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '问题类型',
      dataIndex: 'questionGroupName',
    },
    {
      title: '题目预览',
      dataIndex: 'questionField',
      render: (record: string) => {
        const question = JSON.parse(record)
        return <Image height={40} src={question[0]} />
      }
    },
    {
      title: '选项预览',
      dataIndex: 'optionField',
      render: (record: string) => {
        const option = JSON.parse(record)
        return <Image height={40} src={option[0]} />
      }
    },
    {
      title: '操作',
      render: (text: string, question: IQuestion) => (
        <Space size="middle">
          <Button danger size="small" onClick={() => handleDelete(question)}>
            删除
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <Spin spinning={loading} tip="题目包解析中，请留意解析结果">
      <div className="question-manage">
        <div className="header-actions">
          <Upload
            name="file"
            accept=".zip"
            showUploadList={false}
            maxCount={1}
            beforeUpload={handleBeforeUpload}
          >
            <Button type="primary">上传题目</Button>
          </Upload>
        </div>
        <Table
          columns={columns}
          dataSource={list}
          rowKey={(record) => record.id}
          pagination={{
            pageSize: pageInfo.pageSize,
            total: total,
            onChange: (page: number, pageSize) => {
              setPageInfo({ ...pageInfo, page: page - 1 })
            },
          }}
        />
        <Modal title="解析结果" visible={isShowModal} onOk={handleOk} onCancel={handleCancel}>
          <p>成功解析{successCnt}题 </p>
          <p>解析失败{failCnt}题</p>
          <p>失败详情如下:</p>
          {errorsQuestion && errorsQuestion.map((e, index) => <p key={index}>{e}</p>)}
        </Modal>
      </div>
      <Modal
        visible={zipModalVisible}
        onOk={handleUpload}
        onCancel={() => setZipModalVisible(false)}
      >
        请选择要添加的组
        <Select
          allowClear
          style={{ width: '100%' }}
          onChange={handleChangeGroup}
          placeholder="Please select"
        >
          {questionGroupList &&
            questionGroupList.map((g) => (
              <Select.Option key={g.id} value={g.id}>
                {g.groupName}
              </Select.Option>
            ))}
        </Select>
      </Modal>
    </Spin>
  )
}

export default questionManage
