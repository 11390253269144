function FillWall(params: {}) {
  return (
    <div>
      <p>
        请仔细观察题目中墙面空缺的部分，下面选项中的某个立方体的一面恰好可以从墙的正面完全填补这个空缺，请你找出这个立方体。
      </p>
    </div>
  )
}

export default FillWall
