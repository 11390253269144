import { Col, Row, Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import Input, { InputProps } from 'antd/lib/input'

export interface FormInputItemProps {
  name: string
  rules: any[]
  formProps: FormItemProps
  inputProps: InputProps & { visibilityToggle?: boolean }
  countStatic?: number
  onGetMobileCode?: (cb: () => void) => void
}

function FormInputItem(props: FormInputItemProps) {
  return (
    <Form.Item {...props.formProps} name={props.name} rules={props.rules}>
      {(() => {
        switch (props.inputProps.type) {
          case 'password':
            return <Input.Password {...props.inputProps}></Input.Password>
          case 'code':
            return (
              <Row gutter={10}>
                <Col span={16}>
                  <Input {...props.inputProps} type="text" />
                </Col>
              </Row>
            )
          default:
            return <Input {...props.inputProps}></Input>
        }
      })()}
    </Form.Item>
  )
}

export default FormInputItem
