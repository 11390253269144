import { useEffect, useState } from 'react'
import '../index.scss'
import './index.scss'
import { Button, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
// import RhombCanvas from '../../../utils/rhombCanvas'
import { decodePath, formatTime, formatTimeByNumber, transformRule } from '../../../utils/util'
import { queryCurrentQuestion, submitAnswerGroup, queryHistoryExam } from '../../../api/answer'
// import CountDownTime from '../../../component/CountDownTime'
import E808B2 from '../../../component/E808B2'
import E808C from '../../../component/E808C'
import { cloneDeep } from 'lodash'

// 每一种题型一次显示的数量
const ShowSize: any = {
  E808B2: 12,
  E808C: 12,
}

// 每一种题型的答案选项
const ANSWER: any = {
  E808B2: ['0', '1', '3'],
  E808C: ['0', '1', '3'],
}

/**
 * 每次显示多个题目的试卷，如 E808B2
 */
function GroupAnswer(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location.search)
  // const query = props.location.query;
  let id = ''
  if (query.examId) {
    id = query.examId
  }

  const [total, setTotal] = useState<number>(0)
  // 试卷结束时间
  const [endTimeStamp, setEndTimeStamp] = useState<number>(0)
  // 题型
  const [subject, setSubject] = useState<string>('')
  // 试题
  const [list, setList] = useState<any[]>([])
  // 当前选择项
  const [select, setSelect] = useState<string[]>([])
  const [select1, setSelect1] = useState<string[]>([])
  // 当前在第几组
  const [groupIndex, setGroupIndex] = useState<number>(0)
  // 当前起始题在第几组
  const [startGroupIndex, setStartGroupIndex] = useState<number>(0)
  // 倒计时显示的值
  const [showEndTime, setShowEndTime] = useState<string>('')
  // 倒计时还剩多少秒
  const [endTime, setEndTime] = useState<number>(-1)
  // 提交按钮loading状态
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  useEffect(() => {
    initData()
  }, [])
  useEffect(() => {
    if (endTime === 0) {
      endTimeCallBack()
    }
  }, [endTime])

  useEffect(() => {
    initCurrentAnswer()
  }, [subject, groupIndex])
  useEffect(() => {
    countDownTime()
  }, [endTimeStamp])

  let timer: any = null
  const countDownTime = () => {
    timer && clearTimeout(timer)
    if (!endTimeStamp) {
      return
    }
    const now = Date.now()
    const s = Math.floor((endTimeStamp - now) / 1000)
    if (s < 0) {
      return
    }
    setShowEndTime(formatTimeByNumber(s))
    setEndTime(s)
    timer = setTimeout(() => {
      countDownTime()
      timer = null
    }, 1000)
  }

  const getActiveList = () => {
    const size = ShowSize[subject]
    const startIndex = groupIndex * size
    return list.slice(startIndex, startIndex + size)
  }

  const getShowTotal = () => {
    return total - Math.ceil(total / ShowSize[subject])
  }

  const fetchSubmitAnswer = async (isForce = false) => {
    const list = getActiveList()
    const reqData: any = {
      examId: id,
    }
    // 跳过后端的时间校验
    if (isForce) {
      reqData.force = 'force'
    }
    select.forEach((_, index) => {
      reqData[list[index].questionIndex] = _ + select1[index]
    })
    await submitAnswerGroup(reqData).then((res) => {
      if (res.code === 5006) {
        getAnswer()
        return
      }
    })
  }

  // 倒计时结束后提交
  const endTimeCallBack = async () => {
    // fix 回调中拿不到state
    console.log(`倒计时结束`)
    await fetchSubmitAnswer(true)
    setTimeout(() => {
      getAnswer()
    }, 1000)
  }

  // 是否是最后一组
  const isLast = () => {
    return (groupIndex + 1) * ShowSize[subject] >= list.length
  }

  const handleNext = async () => {
    // 只要有其中一题没有选择
    // if (select.some((_) => !_)) {
    //   return
    // }
    setSaveLoading(true)
    // 1. 提交答案
    await fetchSubmitAnswer().finally(() => {
      setSaveLoading(false)
    })
    // 2. 更新页面
    if (isLast()) {
      setTimeout(() => {
        getAnswer()
      }, 400)
    } else {
      setGroupIndex(groupIndex + 1)
    }
  }

  const initCurrentAnswer = () => {
    // setCurrentFlash(activeAnswer.flash || [])
    const arr = []
    let i = 0
    const list = getActiveList()
    const len = list.length
    while (i < len) {
      arr.push('')
      i++
    }
    setSelect(arr)
    setSelect1([...arr])
  }

  const getAnswer = async () => {
    queryHistoryExam({ examId: id }).then((res) => {
      const { rightAnswers, wrongAnswers, starTime, endTime } = res.data
      const showTotal = getShowTotal()
      Modal.success({
        title: '答题结果',
        content: (
          <div>
            <p>正确：{rightAnswers || 0}题</p>
            <p>错误：{wrongAnswers || 0}题</p>
            <p>未答：{showTotal - rightAnswers - wrongAnswers || 0}题</p>
            <p>用时：{formatTime(starTime, endTime)}</p>
          </div>
        ),
        onOk: () => {
          history.push(`/answer-e808b2-history?examId=${id}`)
        },
      })
    })
  }

  const handleSelect = (ans: string, i: number) => {
    const cloneSelect = cloneDeep(select)
    cloneSelect[i] = ans
    setSelect(cloneSelect)
  }

  const handleSelect1 = (ans: string, i: number) => {
    const cloneSelect = cloneDeep(select1)
    cloneSelect[i] = ans
    setSelect1(cloneSelect)
  }

  const initData = () => {
    // 先请求看当前有没有正在进行中的题，没有的话显示一个开始考试按钮，有的情况下初始化单前项
    queryCurrentQuestion({ examId: id }).then(({ data, code }) => {
      if (code !== 0) {
        history.replace('/')
        return
      }
      if (data.examId) {
        setGroupIndex(0)
        setTotal(data.total)
        setList(data.list)
        if (data.list && data.list.length) {
          setStartGroupIndex(Math.floor(data.list[0].questionIndex / ShowSize[data.subject]))
        }
        setEndTimeStamp(data.endTimeStamp)
        setSubject(data.subject)
      } else {
        history.replace('/')
      }
    })
  }

  const ExamDescription = (subject: string) => {
    return {
      E808B2: (
        <div>
          <p>观察相邻两图三角形的边的颜色，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>再观察相邻两图的符号形状，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>边的颜色在上，符号的形状在下。</p>
        </div>
      ),
      E808C: (
        <div>
          <p>将图脑补完整，再按照以下要求选择选项；</p>
          <p>观察相邻两图三角形的边的颜色，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>再观察相邻两图的符号形状，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>边的颜色在上，符号的形状在下。</p>
        </div>
      ),
    }[subject]
  }

  const DescAndAction = (subject: string) => {
    const activeQuestionList = getActiveList()
    if (subject === 'E808B2') {
      return (
        <div className="e808b2-questions">
          <div className="e808b2-question-box">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="e808b2-question" key={index}>
                  <E808B2 data={item.question} width={80} height={72} />
                  {index < activeQuestionList.length - 1 && (
                    <div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={
                                'e808b2-ans-item' +
                                (select[index] && select[index].indexOf(_) > -1 ? ' active' : '')
                              }
                              onClick={() => handleSelect(_, index)}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={
                                'e808b2-ans-item' +
                                (select1[index] && select1[index].indexOf(_) > -1 ? ' active' : '')
                              }
                              onClick={() => handleSelect1(_, index)}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if (subject === 'E808C') {
      return (
        <div className="e808b2-questions">
          <div className="e808b2-question-box">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="e808b2-question" key={index}>
                  <E808C data={item.question} width={80} height={72} />
                  {index < activeQuestionList.length - 1 && (
                    <div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={
                                'e808b2-ans-item' +
                                (select[index] && select[index].indexOf(_) > -1 ? ' active' : '')
                              }
                              onClick={() => handleSelect(_, index)}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={
                                'e808b2-ans-item' +
                                (select1[index] && select1[index].indexOf(_) > -1 ? ' active' : '')
                              }
                              onClick={() => handleSelect1(_, index)}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
  // const disabledNextButton = select.some((item) => !item)

  const ButtonText = isLast() ? '提交答案' : '下一组'

  const showTotal = getShowTotal()

  const disabledNextButton =
    select.filter((item) => !!item).length < ShowSize[subject] - 1 ||
    select1.filter((item) => !!item).length < ShowSize[subject] - 1

  return (
    <div className="answer-page">
      <h2 className="title">第一单元-练习题</h2>
      {/* 题目描述 */}
      {ExamDescription(subject)}

      {/* 公用 */}
      <p className="current-test">
        当前第 {groupIndex + 1 + startGroupIndex} 组，每组 {ShowSize[subject] - 1} 题 ，共{' '}
        {showTotal} 题{/* <CountDownTime endTimeStamp={endTimeStamp} /> */}
        <span>{showEndTime ? '，剩余时间：' + showEndTime : ''}</span>
      </p>

      {/* 题目示意 包含变化区和展示区 */}
      {DescAndAction(subject)}

      {/* 底部提交按钮 */}
      <div className="footer">
        {
          <div>
            <Button
              type="primary"
              disabled={disabledNextButton}
              loading={saveLoading}
              onClick={() => handleNext()}
            >
              {ButtonText}
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default GroupAnswer
