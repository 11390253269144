import http, { IResponse, IResponseListType } from '../../lib/http'
import { IPageInfo } from '../../types/common'
import { IQuestion } from '../../types/question'
import { IGroupInfo } from '../../types/user'

export async function getLogicalreasoningList(params: IPageInfo): Promise<any> {
  return http.get('/manager/logical/reasoning/list', { params })
}

export async function updateLogicalreasoning(data: IQuestion): Promise<any> {
  return http.post('/manager/logical/reasoning/update', data)
}

export async function deleteLogicalreasoning(params: { id: number }): Promise<any> {
  return http.get('/manager/logical/reasoning/delete', { params })
}

export async function zipUpload({
  zipUrl,
  groupId,
}: {
  zipUrl: string
  groupId: number
}): Promise<{
  data: { errors: string[]; success: number; fail: number }
  code: number
  msg: string
}> {
  return http
    .post('/manager/logical/reasoning/zipUpload', { data: { zipUrl, groupId } })
    .then((res: any) => {
      if (res) {
        return res
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function getQuestionGroupList(
  params: IPageInfo,
): Promise<IResponse<IResponseListType<{ id: number; groupName: string }>>> {
  return http.get('/manager/logical/reasoning/groupList', { params })
}

export async function addQuestionGroup(groupName: string): Promise<IResponse<IGroupInfo>> {
  return http.post('/manager/logical/reasoning/addGroup', { groupName })
}

export async function deleteQuestionGroup(groupId: number): Promise<any> {
  return http.get('/manager/logical/reasoning/deleteGroup', { params: { groupId } })
}
