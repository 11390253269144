import http from '../../lib/http'

export async function queryExamSubjectDailyLimit(): Promise<any> {
  return http.get('/user/exam/queryExamSubjectDailyLimit')
}

// TODO 需要更换为正式接口
export async function updateExamSubjectDailyLimit(params: {
  examSubject: string
  size: number
}): Promise<any> {
  return http.get('/test/modifyExamDailyChange', { params })
}
