import http from '../lib/http'
import Cookie from 'js-cookie'
import { COOKIE_KEY } from '../lib/config'

export interface LoginPayload {
  phone: string
  password: string
}

// 登录接口
export async function login(
  payload: LoginPayload,
): Promise<{ data: null; code: number; msg: string }> {
  return http
    // .post('/m/accountLogin', payload)
    .post('/open/login/managerLogin', payload)
    .then((res: any) => {
      if (res) {
        console.log('login res: ', res)
        Cookie.set(COOKIE_KEY, res.data.accessToken)
        return res
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}
