import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Card,
  Button,
  Table,
  InputNumber,
  Select,
  Space,
  Drawer,
  DatePicker,
  Form,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Divider,
} from 'antd'
import { IPageInfo } from '../../../../types/common'
import { deleteLogicalreasoning, getLogicalreasoningList } from '../../../../api/admin/question'
import {
  addExamPlan,
  addExamPlanParticipant,
  deleteExamPlan,
  deleteExamPlanParticipant,
  getBindlist,
  getExamPlanDetail,
  getExamPlanList,
  IPlanPayload,
  IPlanType,
  queryExamPlanResult,
  updateExamPlan,
} from '../../../../api/admin/exam'
import {
  ExamType,
  ExamTypeZh,
  IExamPlanAddRequest,
  ParticipantType,
} from '../../../../types/exam'
import Input from 'antd/lib/input/Input'
import { IGroupInfo } from '../../../../types/user'
import { getGroupList, getGroupUsers, getStudentList, IStudentInfo } from '../../../../api/admin/user'
import { ItemGroup } from 'rc-menu'
// import StudentList from './studentList'
import ProblemsList from './problemsList'
import dayjs from 'dayjs'

const timeFormat = 'YYYY-MM-DD HH:mm:ss'
const { RangePicker } = DatePicker
const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}
const rangeConfig = {
  rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
}

function ExamListManage() {
  const [list, setList] = useState<IPlanType[]>([])
  const [planInfo, setPlanInfo] = useState<IPlanPayload>({} as IPlanPayload)
  const [type, setType] = useState<string>('')
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [spageInfo, setSpageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [groups, setGroups] = useState<IGroupInfo[]>([])
  const [students, setStudents] = useState<IStudentInfo[]>([])
  const [total, setTotal] = useState<number>(0)
  const [totalStudentList, setTotalStudentList] = useState<number>(0)
  const [totalPlanList, setTotalPlanList] = useState<number>(0)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [studentModalVisible, setStudentModalVisible] = useState<boolean>(false)
  const [bindStudent, setBindStudent] = useState<any[]>([])
  const [problemModalVisible, setProblemModalVisible] = useState<boolean>(false)
  const [currentPlanId, setCurrentPlanId] = useState<number>(0)
  const [isConfigStudentModalVisible, setIsConfigStudentModalVisible] = useState<boolean>(false)
  const [
    isConfigRemoveStudentModalVisible,
    setIsConfigRemoveStudentModalVisible,
  ] = useState<boolean>(false)
  const [isConfigProModalVisible, setIsConfigProModalVisible] = useState<boolean>(false)
  const [form] = Form.useForm()

  useEffect(() => {
    getList()
  }, [pageInfo])

  useEffect(() => {
    getStudent()
    // getGroup()
  }, [])

  useEffect(() => {
    getBindStudent()
  }, [currentPlanId])

  const getBindStudent = () => {
    getBindlist({ ...spageInfo, planId: currentPlanId }).then(res => {
      setBindStudent(res.data.list)
    })
  }

  const getList = () => {
    getExamPlanList(pageInfo).then((res) => {
      if (res.code === 0) {
        setTotalPlanList(res.data.total)
        setList(res.data.list)
      }
    })
  }

  const getGroup = () => {
    getGroupList({ page: 0, pageSize: 10000 }).then((res) => setGroups(res.data.list))
  }

  const getStudent = () => {
    getStudentList({ page: 0, pageSize: 10000 }).then((res) => {
      setTotalStudentList(res.data.total)
      setStudents(res.data.list)
    })
  }

  const handleDelete = (exam: IPlanType) => {
    Modal.confirm({
      title: '删除确认',
      content: `你确认要删除题目（${exam.id}）吗？`,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        deleteExamPlan({ id: exam.id }).then((res) => {
          if (res.code === 0) {
            message.success('删除成功')
            setPageInfo({ ...pageInfo, page: 0 })
          } else {
            message.success('删除失败：', res.message)
          }
        })
      },
    })
  }

  const handleQueryResult = (exam: IPlanType) => {
    message.info('查询结果完善中')
    // queryExamPlanResult({ planId: exam.id }).then(res => {
    //   console.log(res)
    // })
  }

  const handleUpdatePlan = (item: IPlanType) => {
    setCurrentPlanId(item.id)
    getExamPlanDetail({ id: item.id }).then(res => {
      const plan = res.data
      plan.estimateReportTime = dayjs(plan.estimateReportTime)
      plan.tests.first.startTime = dayjs(plan.tests.first.startTime)
      plan.tests.second.startTime = dayjs(plan.tests.second.startTime)
      form.setFieldsValue(plan)
      setPlanInfo(plan)
      setType('edit')
      setIsModalVisible(true)
    })
  }

  const handleConfigStudents = (item: IPlanType) => {
    setCurrentPlanId(item.id)
    setIsConfigStudentModalVisible(true)
  }

  const handleConfigRemoveStudents = (item: IPlanType) => {
    // if (item.participantType === ParticipantType.SOMEONE) {
    //   setIsConfigRemoveStudentModalVisible(true)
    //   setCurrentPlanId(item.id)
    // } else {
    //   message.warn('不支持配置学生，src/views/admin/exam-manage/list/problemsList/index.tsx因为当前考试范围为所有人')
    // }
  }

  const handleConfigProblem = (item: IPlanType) => {
    setProblemModalVisible(true)
    setCurrentPlanId(item.id)
  }

  const handleEnterAdd = (formData: IPlanPayload) => {
    formData.routes = ['', '']
    formData.tests.first.startTime = dayjs(formData.tests.first.startTime).format(timeFormat)
    formData.tests.second.startTime = dayjs(formData.tests.second.startTime).format(timeFormat)
    formData.estimateReportTime = dayjs(formData.estimateReportTime).format(timeFormat)
    const fn = type === 'add' ? addExamPlan : updateExamPlan
    if (type === 'edit') {
      formData.id = currentPlanId
    }
    fn(formData).then((res) => {
      if (res.code === 0) {
        message.success('考试计划添加成功，请在列表选择计划配置考试题目')
        getList()
        setIsModalVisible(false)
      } else {
        message.error(`code: ${res.code}, ` + (res?.msg || '考试计划添加失败'))
      }
    })
  }

  const renderPlanModal = () => {
    return (
      <div>
        <Modal
          title="考试计划"
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          destroyOnClose
          visible={isModalVisible}
          maskClosable={false}
        >
          {planInfo && (
            <Form name="添加考试计划" form={form} {...formItemLayout} onFinish={(data) => handleEnterAdd(data)}>
              <Form.Item
                name="title"
                label="考试计划名称"
                rules={[{ required: true, message: '请输入考试名称' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="level"
                label="考试等级"
                rules={[{ required: true, message: '请选择考试等级' }]}
              >
                <Radio.Group>
                  <Radio value={'初级'}>初级</Radio>
                  <Radio value={'中级'}>中级</Radio>
                  <Radio value={'高级'}>高级</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider />
              <h4>初试</h4>
              <Form.Item
                name={['tests', 'first', 'subTitle']}
                label="初试名称"
                rules={[{ required: true, message: '请输入初试名称' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['tests', 'first', 'startTime']}
                label="时间"
                rules={[{ required: true, message: '请选择考试时间' }]}
              >
                <DatePicker format={timeFormat} showTime />
              </Form.Item>
              <Form.Item
                name={['tests', 'first', 'classRoom']}
                label="初试教室"
                rules={[{ required: true, message: '请输入初考试教室' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['tests', 'first', 'type']}
                label="考试类型"
                rules={[{ required: true, message: '请选择考试类型' }]}
              >
                <Radio.Group>
                  <Radio value={'上机测试'}>上机测试</Radio>
                  <Radio value={'在线考试'}>在线考试</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={['tests', 'first', 'remark']}
                label="初试说明"
                rules={[{ required: true, message: '请输入初试说明' }]}
              >
                <Input />
              </Form.Item>
              <h4>复试</h4>
              <Form.Item
                name={['tests', 'second', 'subTitle']}
                label="复试名称"
                rules={[{ required: true, message: '请输入复试名称' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['tests', 'second', 'startTime']}
                label="时间"
                rules={[{ required: true, message: '请选择考试时间' }]}
              >
                <DatePicker format={timeFormat} showTime />
              </Form.Item>
              <Form.Item
                name={['tests', 'second', 'classRoom']}
                label="复试教室"
                rules={[{ required: true, message: '请输入复考试教室' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['tests', 'second', 'type']}
                label="考试类型"
                rules={[{ required: true, message: '请选择考试类型' }]}
              >
                <Radio.Group>
                  <Radio value={'上机测试'}>上机测试</Radio>
                  <Radio value={'在线考试'}>在线考试</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={['tests', 'second', 'remark']}
                label="复试说明"
                rules={[{ required: true, message: '请输入复试说明' }]}
              >
                <Input />
              </Form.Item>
              <Divider />
              <h4>其他信息</h4>
              <Form.Item
                name='testAddress'
                label="线下考试地址"
                rules={[{ required: true, message: '' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='estimateReportTime'
                label="预计报告时间"
                rules={[{ required: true, message: '' }]}
              >
                <DatePicker format={timeFormat} showTime />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {type === 'add' ? '新增' : '更新'}

                </Button>
              </Form.Item>

            </Form>
          )}
        </Modal>
      </div>
    )
  }

  const renderConfigStudent = () => {
    const [selectGroup, setSelectGroup] = useState<string[]>([])
    const [selectStudent, setSelectStudent] = useState<number[]>([])

    const handleAddStudent = () => {
      const payload = {
        planId: currentPlanId,
        userIds: selectStudent
      }
      addExamPlanParticipant(payload).then((res) => {
        if (res.code === 0) {
          message.success('添加学生成功')
          getBindStudent()
        } else {
          message.error('添学生失败')
        }
      })
    }

    const handleChangeGroup = (val: string[]) => {
      setSelectGroup(val)
    }

    const handleChangeStudent = (val: number[]) => {
      setSelectStudent(val)
    }

    const handleDeleteStudent = (item: any) => {
      deleteExamPlanParticipant({ userIds: [item.userId], planId: currentPlanId }).then(res => {
        getBindStudent()
      })
    }

    const studentColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: '姓名',
        dataIndex: ['userInfo', 'nickName'],
        key: 'userInfo.nickName'
      },
      {
        title: '手机号',
        dataIndex: ['userInfo', 'phone'],
        key: 'userInfo.phone'
      },
      {
        title: '用户名',
        dataIndex: 'admission',
        key: 'admission',
      },
      {
        title: '密码',
        dataIndex: 'password',
        key: 'password',
      },
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: '操作',
        key: 'operator',
        render: (text: string, exam: IPlanType) => (
          <Space size="middle">
            <a onClick={() => handleDeleteStudent(exam)}>删除</a>
          </Space>
        ),
      },
    ]

    return (
      <div>
        <Modal
          title="选定考试参与人"
          onCancel={() => setIsConfigStudentModalVisible(false)}
          width={1000}
          destroyOnClose
          visible={isConfigStudentModalVisible}
          cancelText="取消"
          maskClosable={false}
        >
          请选择要添加的学生
          <div className="flex-add">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
              mode="multiple"
              filterOption={(input, option) => {
                return (option?.key ?? '').toString().toLowerCase().includes(input.toLowerCase())
              }
              }
              onChange={handleChangeStudent}
              placeholder="Please select"
            >
              {students &&
                students.map((g) => (
                  <Select.Option key={g.phone} value={g.id}>
                    {g.name}\{g.phone}
                  </Select.Option>
                ))}
            </Select>
            <Button type="primary" onClick={handleAddStudent}>添加</Button>
          </div>
          <Divider />
          <Table
            columns={studentColumns}
            rowKey={(record) => record.id}
            dataSource={bindStudent}
            pagination={{
              pageSize: pageInfo.pageSize,
              total: totalStudentList,
              onChange: (page: number, pageSize) => {
                setSpageInfo({ ...spageInfo, page: page - 1 })
              },
            }}
          />
        </Modal>
      </div>
    )
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '考试名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '编号',
      dataIndex: 'planNo',
      key: 'planNo',
    },
    {
      title: '开始时间',
      dataIndex: 'testTime',
      key: 'testTime',
    },
    {
      title: '状态',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: '操作',
      key: 'operator',
      render: (text: string, exam: IPlanType) => (
        <Space size="middle">
          <a onClick={() => handleUpdatePlan(exam)}>编辑计划</a>
          <a onClick={() => handleConfigStudents(exam)}>配置学生</a>
          {/* <a onClick={() => handleConfigRemoveStudents(exam)}>删除人员</a> */}
          <a onClick={() => handleConfigProblem(exam)}>配置题目</a>
          <a onClick={() => handleDelete(exam)}>删除</a>
          {/* <a onClick={() => handleQueryResult(exam)}>查询结果</a> */}
        </Space>
      ),
    },
  ]

  return (
    <div className="question-manage">
      <div className="header-actions">
        <Button type="primary" onClick={() => {
          setType('add')
          setIsModalVisible(true)
        }
        }>
          新增考试
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={list}
        pagination={{
          pageSize: pageInfo.pageSize,
          total: totalPlanList,
          onChange: (page: number, pageSize) => {
            setPageInfo({ ...pageInfo, page: page - 1 })
          },
        }}
      />
      {renderPlanModal()}
      {renderConfigStudent()}
      {/* {studentModalVisible && <StudentList planId={currentPlanId} onShow={() => setStudentModalVisible(true)} onClose={() => setStudentModalVisible(false)} />} */}
      {problemModalVisible && (
        <ProblemsList
          planId={currentPlanId}
          onShow={() => setProblemModalVisible(true)}
          onClose={() => setProblemModalVisible(false)}
        />
      )}
    </div>
  )
}

export default ExamListManage
