import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import { Canvas, useFrame } from 'react-three-fiber'
import { OrbitControls, Stats, useHelper } from '@react-three/drei'
import { BoxHelper } from 'three'

const CubeWithHelpers = () => {
  const cubeRef = useRef()
  useHelper(cubeRef, BoxHelper, 'red')

  return (
    <mesh ref={cubeRef}>
      <cubeGeometry args={[2, 2, 2]} />
    </mesh>
  )
}

function Test() {
  return (
    <Canvas style={{ height: 400, width: 400 }}>
      <CubeWithHelpers />
      <OrbitControls />
      <Stats />
    </Canvas>
  )
}

export default Test
