import { useEffect, useState } from 'react'
import './index.scss'
import { useHistory, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import MultipleQuestion from '../common/multipleQuestion'

function Answer(props: any) {
  console.log(`props.router: `, props)

  const history = useHistory()
  const query: any = queryString.parse(props.location.search)
  // const query = props.location.query;
  let id = ''
  if (query.examId) {
    id = query.examId
  }

  return (
    <div className="answer-page">
      <h2 className="title">第一单元-练习题</h2>
      <MultipleQuestion examId={id} />
    </div>
  )
}

export default withRouter(Answer)
