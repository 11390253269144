/**
 * 一次提交一组题目答案的题型
 * 此组件会被 考试引用
 */

import { useEffect, useState } from 'react'
import './index.scss'
import { Button, Modal, InputNumber } from 'antd'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { formatTime, formatTimeByNumber } from '../../../utils/util'
import {
  queryCurrentQuestion,
  submitAnswerGroup,
  queryHistoryExam,
  queryHistoryExamResult,
} from '../../../api/answer'
import E808B from '../../../component/E808B'
import E808D from '../../../component/E808D'
import { cloneDeep } from 'lodash'

// 每一种题型一次显示的数量
const ShowSize: any = {
  E808B: 12,
  E808D: 5,
}

// 每一种题型的答案选项
const ANSWER: any = {
  E808B: ['0', '1', '2', '3'],
}

/**
 * 每次显示多个题目的试卷，如 E808B
 */
function GroupQuestion(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location?.search || '')
  // const query = props.location.query;
  let id = ''
  if (props.examId) {
    id = props.examId
  } else if (query.examId) {
    id = query.examId
  }

  // 是否是考试状态
  const isExam = !!props.isExam
  const examCallBack = props.callback || (() => {})

  const [total, setTotal] = useState<number>(0)
  // 试卷结束时间
  const [endTimeStamp, setEndTimeStamp] = useState<number>(0)
  // 题型
  const [subject, setSubject] = useState<string>('')
  // 试题
  const [list, setList] = useState<any[]>([])
  // 当前选择项
  const [select, setSelect] = useState<(string | number)[]>([])
  // 当前在第几组
  const [groupIndex, setGroupIndex] = useState<number>(0)
  // 当前起始题在第几组
  const [startGroupIndex, setStartGroupIndex] = useState<number>(0)
  // 倒计时显示的值
  const [showEndTime, setShowEndTime] = useState<string>('')
  // 倒计时还剩多少秒
  const [endTime, setEndTime] = useState<number>(-1)
  // 提交按钮loading状态
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  useEffect(() => {
    initData()
  }, [])
  useEffect(() => {
    if (endTime === 0) {
      endTimeCallBack()
    }
  }, [endTime])
  useEffect(() => {
    initCurrentAnswer()
  }, [subject, groupIndex])

  let timer: any = null
  useEffect(() => {
    countDownTime()
    return () => {
      timer && clearTimeout(timer)
    }
  }, [endTimeStamp])

  const countDownTime = () => {
    timer && clearTimeout(timer)
    if (!endTimeStamp) {
      return
    }
    const now = Date.now()
    const s = Math.floor((endTimeStamp - now) / 1000)
    if (s < 0) {
      return
    }
    setShowEndTime(formatTimeByNumber(s))
    setEndTime(s)
    timer = setTimeout(() => {
      countDownTime()
      timer = null
    }, 1000)
  }

  const getActiveList = () => {
    const size = ShowSize[subject]
    const startIndex = groupIndex * size
    return list.slice(startIndex, startIndex + size)
  }

  const fetchSubmitAnswer = async (isForce = false) => {
    const list = getActiveList()
    const reqData: any = {
      examId: id,
    }
    // 跳过后端的时间校验
    if (isForce) {
      reqData.force = 'force'
    }
    select.forEach((_, index) => {
      if (`${_ || ''}`) {
        reqData[list[index].questionIndex] = `${_}`
      }
    })
    await submitAnswerGroup(reqData).then((res) => {
      if (res.code === 5006) {
        getAnswer()
        return
      }
    })
  }

  // 倒计时结束后提交
  const endTimeCallBack = async () => {
    // fix 回调中拿不到state
    console.log(`倒计时结束`)
    await fetchSubmitAnswer(true)
    setTimeout(() => {
      getAnswer()
    }, 1000)
  }

  // 是否是最后一组
  const isLast = () => {
    return (groupIndex + 1) * ShowSize[subject] >= total
  }

  const handleNext = async () => {
    // 只要有其中一题没有选择
    if (select.some((_) => !_)) {
      return
    }
    setSaveLoading(true)
    // 1. 提交答案
    await fetchSubmitAnswer().finally(() => {
      setSaveLoading(false)
    })
    // 2. 更新页面
    if (isLast()) {
      setTimeout(() => {
        getAnswer()
      }, 400)
    } else {
      setGroupIndex(groupIndex + 1)
    }
  }

  const initCurrentAnswer = () => {
    // setCurrentFlash(activeAnswer.flash || [])
    const arr = []
    let i = 0
    const list = getActiveList()
    const len = list.length
    while (i < len) {
      arr.push('')
      i++
    }
    setSelect(arr)
  }

  const getAnswer = async () => {
    if (isExam) {
      examCallBack()
      return
    }
    queryHistoryExam({ examId: id }).then((res) => {
      const { rightAnswers, wrongAnswers, starTime, endTime } = res.data
      Modal.success({
        title: '答题结果, 点击ok按钮回看题目',
        content: (
          <div>
            <p>正确：{rightAnswers || 0}题</p>
            <p>错误：{wrongAnswers || 0}题</p>
            <p>未答：{total - rightAnswers - wrongAnswers || 0}题</p>
            <p>用时：{formatTime(starTime, endTime)}</p>
          </div>
        ),
        onOk: () => {
          history.push(`/answer-group-history?examId=${id}`)
          // queryHistoryExamResult({ examId: id }).then((res) => {
          // console.log('🚀', res)
          // })
        },
      })
    })
  }

  const handleSelect = (ans: string, i: number) => {
    const cloneSelect = cloneDeep(select)
    let indexAns = `${cloneSelect[i] || ''}`.split('')
    const index = indexAns.indexOf(ans)
    if (index > -1) {
      indexAns.splice(index, 1)
    } else {
      if (indexAns.length === 2) {
        indexAns[1] = ans
      } else {
        indexAns.push(ans)
      }
      indexAns = indexAns.sort((a: string, b: string) => parseInt(a) - parseInt(b))
    }
    cloneSelect[i] = indexAns.join('')
    setSelect(cloneSelect)
  }

  const initData = () => {
    // TODO 提交应该注释 test绘制
    // const canvas1 = new RhombCanvas(document.getElementById('answer-canvas') as HTMLCanvasElement);
    // canvas1.draw(decodePath('0001'));

    // 先请求看当前有没有正在进行中的题，没有的话显示一个开始考试按钮，有的情况下初始化单前项
    queryCurrentQuestion({ examId: id }).then(({ data, code }) => {
      if (code !== 0) {
        history.replace('/')
        return
      }
      if (data.examId) {
        setGroupIndex(0)
        setTotal(data.total)
        setList(data.list)
        if (data.list && data.list.length) {
          setStartGroupIndex(Math.floor(data.list[0].questionIndex / ShowSize[data.subject]))
        }
        setEndTimeStamp(data.endTimeStamp)
        setSubject(data.subject)
      } else {
        history.replace('/')
      }
    })
  }

  const ExamDescription = (subject: string) => {
    return {
      E808B: (
        <div>
          <p>下列图中，各边与对角的颜色是否相同？</p>
          <p>1. 全同（3同），中间圆圈是红色——选"1"，中间圆圈不是红色——选"01"</p>
          <p>2. 一同，中间圆圈是绿色——选"2"，中间圆圈不是绿色——选"02"</p>
          <p>3. 全不同（0同），中间圆圈是蓝色——选"3"，中间圆圈不是蓝色——选"03"</p>
        </div>
      ),
      E808D: (
        <div>
          <p>
            正三角形表面的颜色，红色表示数字 1，绿色表示数字 2，蓝色表示数学 3，黄色表示数字 4；
          </p>
          <p>
            正三角形的位置解构：正面的正三角形的数字表达为 1，左侧面的数字表达为
            2，右侧面的数字表达为 3，地面的数字表达为 4；
          </p>
          <p>
            计算正三角体的正面和底面两个正三角形 颜色乘以位置的计算结果之和，填入图形下方输入框中。
          </p>
        </div>
      ),
    }[subject]
  }

  const DescAndAction = (subject: string) => {
    const activeQuestionList = getActiveList()
    if (subject === 'E808B') {
      return (
        <div className="group-questions">
          <div className="group-question-box e808b">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="group-question" key={index}>
                  <E808B data={item.question} width={100} height={90} />
                  <div className="e808b-ans-item-box">
                    {ANSWER[subject].map((_: string) => {
                      return (
                        <div
                          key={_ + index}
                          className={
                            'e808b-ans-item' +
                            (select[index] && `${select[index]}`.indexOf(_) > -1 ? ' active' : '')
                          }
                          onClick={() => handleSelect(_, index)}
                        >
                          [ {_} ]
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if (subject === 'E808D') {
      return (
        <div className="group-questions">
          <div className="group-question-box e808d">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="group-question" key={index}>
                  <E808D data={item.question} width={140} height={160} />
                  <div className="group-input-number-box">
                    <InputNumber
                      className="input-number"
                      value={select[index]}
                      placeholder="输入答案"
                      controls={false}
                      onInput={(e) => (select[index] = e)}
                      onChange={(value) => (select[index] = value!)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
  const disabledNextButton = select.some((item) => !item)

  const ButtonText = isLast() ? '提交答案' : '下一组'

  return (
    <div className="group-question">
      {/* 题目描述 */}
      {ExamDescription(subject)}

      {/* 公用 */}
      <p className="current-test">
        当前第 {groupIndex + 1 + startGroupIndex} 组，每组 {ShowSize[subject]} 题 ，共 {total} 题
        {/* <CountDownTime endTimeStamp={endTimeStamp} /> */}
        <span>{showEndTime ? '，剩余时间：' + showEndTime : ''}</span>
      </p>

      {/* 题目示意 包含变化区和展示区 */}
      {DescAndAction(subject)}

      {/* 底部提交按钮 */}
      <div className="footer">
        {
          <div>
            <Button
              type="primary"
              disabled={disabledNextButton}
              loading={saveLoading}
              onClick={() => handleNext()}
            >
              {ButtonText}
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default GroupQuestion
