import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const COLOR_ENUM: any = {
  G: 'green',
  R: 'red',
  B: 'blue',
}

function E808B({ data, width, height }: { data: string; width: number; height: number }) {
  const [colorList, setColorList] = useState<string[]>([])

  const [canvasId, setCanvasId] = useState<string>(uuidv4())

  useEffect(() => {
    if (data) {
      const arr: string[] = data.split('')
      if (arr.length === 7) {
        setColorList([...arr])
      }
    }
  }, [data])

  useEffect(() => {
    renderCanvas()
  }, [colorList])

  const renderCanvas = () => {
    if (colorList.length !== 7) {
      return
    }
    const canvasDom: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
    if (!canvasDom) {
      return
    }
    const ctx: CanvasRenderingContext2D = canvasDom.getContext('2d') as CanvasRenderingContext2D
    // 找出三个点位置
    const centerX = Math.floor(width / 2)
    const centerY = Math.floor(height / 2)

    // 三角形的三个定位点
    const top = [centerX, 2]
    const left = [2, height - 1]
    const right = [width - 2, height - 2]
    // 最长边
    const maxSkew = Math.floor((width + height) / 2 / 3.6)
    // 最短边
    const minSkew = Math.floor(maxSkew / 2)
    const hypotenuse = Math.floor(Math.sqrt(Math.pow(maxSkew, 2) - Math.pow(minSkew, 2)))

    const colors: string[] = colorList.map((c) => COLOR_ENUM[c] || 'rgba: 0,0,0,0')
    const addLong = Math.floor(width / 20) // 加号一半的长度

    ctx.clearRect(0, 0, width, height) // 清除画布
    ctx.lineWidth = 2

    ctx.beginPath()
    ctx.strokeStyle = colors[0]
    ctx.moveTo(top[0], top[1])
    ctx.lineTo(left[0], left[1])
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[1]
    ctx.moveTo(top[0], top[1])
    ctx.lineTo(right[0], right[1])
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[2]
    ctx.moveTo(left[0], left[1])
    ctx.lineTo(right[0], right[1])
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[3]
    const center1 = [right[0] - hypotenuse, right[1] - minSkew]
    ctx.moveTo(center1[0] - addLong, center1[1])
    ctx.lineTo(center1[0] + addLong, center1[1])
    ctx.stroke()
    ctx.moveTo(center1[0], center1[1] - addLong)
    ctx.lineTo(center1[0], center1[1] + addLong)
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[4]
    const center2 = [left[0] + hypotenuse, left[1] - minSkew]
    ctx.moveTo(center2[0] - addLong, center2[1])
    ctx.lineTo(center2[0] + addLong, center2[1])
    ctx.stroke()
    ctx.moveTo(center2[0], center2[1] - addLong)
    ctx.lineTo(center2[0], center2[1] + addLong)
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[5]
    const center3 = [top[0], top[1] + hypotenuse]
    ctx.moveTo(center3[0] - addLong, center3[1])
    ctx.lineTo(center3[0] + addLong, center3[1])
    ctx.stroke()
    ctx.moveTo(center3[0], center3[1] - addLong)
    ctx.lineTo(center3[0], center3[1] + addLong)
    ctx.stroke()
    ctx.closePath()

    ctx.beginPath()
    ctx.strokeStyle = colors[6]
    ctx.arc(
      centerX,
      Math.floor((height / 3) * 2),
      Math.floor(Math.min(centerY, centerX) / 3.4),
      0,
      Math.PI * 2,
      false,
    )
    ctx.stroke()
    ctx.closePath()
  }

  if (colorList.length !== 7) {
    return null
  }
  return <canvas id={canvasId} width={width} height={height}></canvas>
}
export default E808B
