import http from '../lib/http'

export async function queryExamSubjectDailyReamins(): Promise<any> {
  return http
    .get('/user/exam/queryExamSubjectRemains')
    .then((res: any) => {
      if (res) {
        return res.data
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}
