import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Table,
  Space,
  Select,
  Card,
  Button,
  Drawer,
  Input,
  Form,
  message,
  Modal,
  Divider,
  InputNumber,
  Radio,
  DatePicker,
} from 'antd'

import { IPageInfo } from '../../../types/common'
import {
  addPracticeChange,
  addUserToGroup,
  deletePcAccount,
  getGroupList,
  getPracticeChange,
  getStudentList,
  getStudent,
  updateStudent,
  deleteStudent,
  searchStudent,
  removeUserFromGroup,
  IStudentInfo,
  addStudent,
} from '../../../api/admin/user'
import { IGroupInfo, IRegUserResquest } from '../../../types/user'
import { TitleEnum } from '../../../constants'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs';

const DefaultFormData: IStudentInfo = {
  phone: '',
  name: '',
  accountState: '',
  birthday: '',
  idCardNo: '',
  address: '',
  gender: '',
}

const { Search } = Input

const dateFormat = 'YYYY-MM-DD';

function UserManage() {
  const [list, setList] = useState<IStudentInfo[]>([])
  const [type, setType] = useState<string>('add')
  const [currentId, setCurrentId] = useState<number>()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [total, setTotal] = useState<number>(0)
  const [searchText, setSearchText] = useState<string>('')

  const [groups, setGroups] = useState<IGroupInfo[]>([])
  const [currentUserId, setCurrentUserId] = useState<number>(-1)
  const [currentUserGroups, setCurrentUserGroups] = useState<IGroupInfo[]>([])
  const [visible, setVisible] = useState(false)
  const [visibleGroup, setVisibleGroup] = useState<boolean>(false)
  const [visibleCount, setVisibleCount] = useState<boolean>(false)
  const [visibleDeleteGroup, setVisibleDeleteGroup] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [userFormData, setUserFormData] = useState({ ...DefaultFormData })
  const [form] = Form.useForm<IStudentInfo>()

  useEffect(() => {
    getList()
  }, [pageInfo])

  // useEffect(() => {
  //   getGroup()
  // }, [])

  // const getGroup = () => {
  //   getGroupList({ page: 0, pageSize: 10000 }).then((res) => setGroups(res.data.list))
  // }

  const onSearch = (value: string) => {
    if (!value) return
    searchStudent({ content: value }).then((res) => {
      if (res.code === 0) {
        setList(res.data)
        setTotal(res.data.length)
      }
      console.log(res)
    })
  }

  const handleChangeSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const getList = () => {
    getStudentList(pageInfo).then((res) => {
      if (res.code === 0) {
        setTotal(res.data.total)
        setList(res.data.list)
      }
    })
  }

  const handleDelete = (user: IStudentInfo) => {
    Modal.confirm({
      title: '删除确认',
      content: `你确认要删除用户（${user.name}）吗？`,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        deleteStudent(user.id!).then((res) => {
          if (res.code === 0) {
            message.success('删除成功')
            setPageInfo({ ...pageInfo, page: 0 })
          } else {
            message.success('删除失败：', res.message)
          }
        })
      },
    })
  }

  const handleAdd = () => {
    setUserFormData({ ...DefaultFormData })
    setVisible(true)
  }

  const handleSubmitAdd = async () => {
    const formData = await form.validateFields()
    formData.birthday = dayjs(formData.birthday).format(dateFormat)
    let fn = addStudent
    if (type === 'edit') {
      formData.id = currentId
      fn = updateStudent
    }
    setSaveLoading(true)
    fn(formData)
      .then((res) => {
        if (res.code === 0) {
          message.success('添加人员成功')
          setVisible(false)
          setPageInfo({ ...pageInfo, page: 0 })
        } else {
          message.success('添加人员失败：', res.message)
        }
      })
      .catch((error) => {
        message.error('添加人员失败： ', error.message)
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const handleChangeFormData = (e: any, key: string) => {
    if (e.target.value && typeof e.target.value === 'string') {
      setUserFormData({ ...userFormData, [key]: e.target.value })
    }
  }

  const columns: ColumnsType<IStudentInfo> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '用户名',
      dataIndex: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      title: '生日',
      dataIndex: 'birthday',
    },
    {
      title: '地址',
      dataIndex: 'address',
    },
    {
      title: '身份证',
      dataIndex: 'idCardNo',
    },
    {
      title: '性别',
      dataIndex: 'gender',
    },
    {
      title: '操作',
      render: (text: string, record: IStudentInfo) => (
        <Space size="middle">
          <Button size="small" onClick={() => {
            const temp: any = { ...record }
            temp.birthday = dayjs(record.birthday, dateFormat)
            form.setFieldsValue(temp)
            setType('edit')
            setCurrentId(record.id)
            setVisible(true)
          }}>
            编辑
          </Button>
          <Button danger size="small" onClick={() => handleDelete(record)}>
            删除用户
          </Button>
        </Space>
      ),
    },
  ]

  const renderDeleteGroupConfig = () => {
    const [selectGroup, setSelectGroup] = useState<string[]>([])

    const handleDeleteGroup = () => {
      removeUserFromGroup(selectGroup, [currentUserId]).then((res) => {
        if (res.code === 0) {
          message.success('删除分组成功')
          setVisibleDeleteGroup(false)
          getList()
        } else {
          message.error('删除分组失败')
        }
      })
    }

    const handleChangeGroup = (val: string[]) => {
      setSelectGroup(val)
    }

    return (
      <div>
        <Modal
          title="组员"
          onCancel={() => setVisibleDeleteGroup(false)}
          visible={visibleDeleteGroup}
          okText="确定"
          cancelText="取消"
          onOk={handleDeleteGroup}
          maskClosable={false}
        >
          请选择要删除的组
          <Select
            allowClear
            style={{ width: '100%' }}
            mode="multiple"
            onChange={handleChangeGroup}
            placeholder="Please select"
          >
            {currentUserGroups &&
              currentUserGroups.map((g) => (
                <Select.Option key={g.id} value={g.id}>
                  {g.groupName}
                </Select.Option>
              ))}
          </Select>
        </Modal>
      </div>
    )
  }

  const renderCountConfig = () => {
    const [chances, setChances] = useState<{ [key: string]: number }>({})

    useEffect(() => {
      if (currentUserId !== -1)
        getPracticeChange(currentUserId).then((res) => {
          console.log(res, 'f')
          setChances(res.data[currentUserId].subjects)
        })
      return () => {
        setChances({})
      }
    }, [currentUserId])

    const handleChangeCount = (e: number, k: string) => {
      const temp = { ...chances }
      setChances({
        ...temp,
        [k]: e,
      })
    }

    const handleEnterChangeCount = () => {
      addPracticeChange(currentUserId, chances!).then((res) => {
        if (res.code === 0) {
          message.success(res.msg)
          setVisibleCount(false)
        }
      })
    }

    return (
      <div>
        <Modal
          title="题目次数"
          onCancel={() => setVisibleCount(false)}
          visible={visibleCount}
          okText="确定"
          cancelText="取消"
          onOk={handleEnterChangeCount}
          maskClosable={false}
        >
          <Space direction="vertical">
            {chances &&
              Object.keys(chances).map((chance) => (
                <div key={chance}>
                  {TitleEnum[chance]}:{' '}
                  <InputNumber
                    defaultValue={chances[chance]}
                    onChange={(e) => {
                      handleChangeCount(e!, chance)
                    }}
                  ></InputNumber>
                </div>
              ))}
          </Space>
        </Modal>
      </div>
    )
  }

  const renderGroupConfig = () => {
    const [selectGroup, setSelectGroup] = useState<string[]>([])
    const handleAddGroup = () => {
      addUserToGroup(selectGroup, [currentUserId]).then((res) => {
        if (res.code === 0) {
          setVisibleGroup(false)
          message.success('添加分组成功')
          getList()
        } else {
          message.error('添加分组失败')
        }
      })
    }

    const handleChangeGroup = (val: string[]) => {
      setSelectGroup(val)
    }

    return (
      <div>
        <Modal
          title="组员"
          onCancel={() => setVisibleGroup(false)}
          visible={visibleGroup}
          okText="确定"
          cancelText="取消"
          onOk={handleAddGroup}
          maskClosable={false}
        >
          请选择要添加的组
          <Select
            allowClear
            style={{ width: '100%' }}
            mode="multiple"
            onChange={handleChangeGroup}
            placeholder="Please select"
          >
            {groups &&
              groups.map((g) => (
                <Select.Option key={g.id} value={g.id}>
                  {g.groupName}
                </Select.Option>
              ))}
          </Select>
        </Modal>
      </div>
    )
  }

  return (
    <div className="user-manage">
      <div className="header-actions">
        <Space direction="horizontal">
          <Search
            placeholder="手机或姓名"
            allowClear
            enterButton="搜索"
            value={searchText}
            onChange={handleChangeSearch}
            onSearch={onSearch}
          />
          <Button type="primary" onClick={handleAdd}>
            添加人员
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id!}
        pagination={{
          pageSize: pageInfo.pageSize,
          total: total,
          onChange: (page: number, pageSize) => {
            console.log(page, pageSize)
            setPageInfo({ ...pageInfo, page: page - 1, pageSize })
          },
        }}
      />

      {/* 新增 */}
      {visible && (
        <Drawer
          title="编辑人员"
          placement="right"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Form labelCol={{ span: 8 }} form={form} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="手机号"
              name="phone"
              rules={[{ required: true, message: '手机号不能为空' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="姓名"
              name="name"
              rules={[{ required: true, message: '用户名不能为空' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='身份证'
              name="idCardNo"
              rules={[{ required: true, message: '不能为空' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='生日'
              name="birthday"
              rules={[{ required: true, message: '不能为空' }]}
            >
              <DatePicker showTime format={dateFormat} />
            </Form.Item>
            <Form.Item
              label='地址'
              name="address"
              rules={[{ required: true, message: '不能为空' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='性别'
              name="gender"
              rules={[{ required: true, message: '不能为空' }]}
            >
              <Radio.Group>
                <Radio value="MALE"> 男生 </Radio>
                <Radio value="FEMALE"> 女生 </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" loading={saveLoading} onClick={handleSubmitAdd}>
                确定
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
      {renderGroupConfig()}
      {renderDeleteGroupConfig()}
      {renderCountConfig()}
    </div>
  )
}

export default UserManage
