export default class RhombCanvas {
  canvas: HTMLCanvasElement | null = null
  ctx: CanvasRenderingContext2D | null = null

  lineColor = '#000000'
  activeColor = '#000000'
  defaultColor = '#ffffff'
  fontSize = '20px Verdana'
  showLabel: boolean = false
  width = 0
  height = 0

  // x方向距离中心点的偏移量
  x = 0
  // y方向距离中心点的偏移量
  y = 0

  get origin() {
    return [Math.floor(this.width / 2), Math.floor(this.height / 2)]
  }

  // canvas
  // document.getElementById(#id);
  constructor(
    canvas: HTMLCanvasElement,
    options?: { showLabel?: boolean; lineColor?: string; activeColor?: string },
  ) {
    this.canvas = canvas
    this.ctx = canvas.getContext('2d')
    this.width = this.canvas.width
    this.height = this.canvas.height
    // 四周留出20px的边距
    this.x = Math.floor((this.width - 20) / 2)
    this.y = Math.floor((this.height - 20) / 2)
    if (options && options.lineColor) {
      this.lineColor = options.lineColor
    }
    if (options && options.activeColor) {
      this.activeColor = options.activeColor
    }
    if (options && options.showLabel) {
      this.showLabel = options.showLabel
    }
  }

  draw(path: [number, number, number, number]) {
    if (!this.ctx || !this.canvas) {
      console.error('canvas初始化失败')
      return
    }
    const tX = Math.floor((this.x * 2) / 3)
    const tY = Math.floor((this.y * 2) / 3)
    this.ctx.clearRect(0, 0, this.width, this.height) // 清除画布
    // 左上
    this.ctx.beginPath()
    this.ctx.fillStyle = this.lineColor
    this.drawLabel('1', this.origin[0] - tX, this.origin[1] - tY)
    this.ctx.moveTo(this.origin[0], this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1] - this.y)
    this.ctx.lineTo(this.origin[0] - this.x, this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1])
    this.ctx.stroke()
    if (path[0]) {
      this.ctx.fillStyle = this.activeColor
    } else {
      this.ctx.fillStyle = this.defaultColor
    }
    this.ctx.fill()
    this.ctx.closePath()

    // 右上
    this.ctx.beginPath()
    this.ctx.fillStyle = this.lineColor
    this.drawLabel('2', this.origin[0] + tX, this.origin[1] - tY)
    this.ctx.moveTo(this.origin[0], this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1] - this.y)
    this.ctx.lineTo(this.origin[0] + this.x, this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1])
    this.ctx.stroke()
    if (path[1]) {
      this.ctx.fillStyle = this.activeColor
    } else {
      this.ctx.fillStyle = this.defaultColor
    }
    this.ctx.fill()
    this.ctx.closePath()

    // 左下
    this.ctx.beginPath()
    this.ctx.fillStyle = this.lineColor
    this.drawLabel('3', this.origin[0] - tX, this.origin[1] + tY)
    this.ctx.moveTo(this.origin[0], this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1] + this.y)
    this.ctx.lineTo(this.origin[0] - this.x, this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1])
    this.ctx.stroke()
    if (path[2]) {
      this.ctx.fillStyle = this.activeColor
    } else {
      this.ctx.fillStyle = this.defaultColor
    }
    this.ctx.fill()
    this.ctx.closePath()

    // 右下
    this.ctx.beginPath()
    this.ctx.fillStyle = this.lineColor
    this.drawLabel('4', this.origin[0] + tX, this.origin[1] + tY)
    this.ctx.moveTo(this.origin[0], this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1] + this.y)
    this.ctx.lineTo(this.origin[0] + this.x, this.origin[1])
    this.ctx.lineTo(this.origin[0], this.origin[1])
    this.ctx.stroke()
    if (path[3]) {
      this.ctx.fillStyle = this.activeColor
    } else {
      this.ctx.fillStyle = this.defaultColor
    }
    this.ctx.fill()
    this.ctx.closePath()

    // this.ctx.draw
  }

  drawLabel(index: string, x: number, y: number) {
    if (!this.ctx || !this.canvas) {
      console.error('canvas初始化失败')
      return
    }
    if (!this.showLabel) {
      return
    }
    this.ctx.textAlign = 'center'
    this.ctx.font = this.fontSize
    this.ctx.fillText(index, x, y)
  }
}
