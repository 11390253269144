function FlashDiamond(params: { total: number }) {
  return (
    <div>
      <p>
        请根据右侧就功能给个的闪烁顺序，对应到中间的变化规则表，对左边的原始图形进行变化，选出变化后的图形。
      </p>
      <p>
        点击“开始”按钮启动九宫格闪烁，开始作答（
        <strong>
          注意：“开始”按钮每题只能点击一次，九宫格也只会闪烁一遍，一共 {params.total}{' '}
          题，每题仅有一次作答机会
        </strong>
        ）
      </p>
    </div>
  )
}

export default FlashDiamond
