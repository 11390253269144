/**
 * HTTP Agent
 */

import instance from './axios'
import { message } from 'antd'
import { HttpCode } from './constant'
import Cookie from 'js-cookie'
import { COOKIE_KEY } from './config'

// Add request interceptor
instance.interceptors.request.use(
  // Do something before request is sent
  (config) => {
    config.headers = {
      ...config.headers,
      'x-requested-with': 'XMLHttpRequest',
      // 将token放到header中
      'x-sjx-token': Cookie.get(COOKIE_KEY) || '',
    }
    return config
  },
  // Do something with request error
  (error) => {
    return Promise.reject(error)
  },
)

// Add response interceptor
instance.interceptors.response.use(
  // Do something with response data
  (response) => {
    // 请求成功
    if (response?.data?.code === 0) {
      return response.data
    }
    // session失效 需要重新登录
    // console.log(HttpCode)
    if (response?.data?.code === HttpCode.LOGIN_REQUIRED) {
      // const loginUrl = `/login?redirectURL=${window.location.href}`
      const hostUrl = location.href.split('#/')[0]
      const loginUrl = `${hostUrl}#/login?redirectURL=${window.location.href}`
      window.location.href = loginUrl
    } else {
      message.error(response.data.msg)
    }

    // if (response?.data?.code === HttpCode.AUTH_FAIL) {
    // return {
    // code: HttpCode.AUTH_FAIL,
    // data: null,
    // msg: '鉴权失败，可能是密码输入错误导致',
    // }
    // }
    return response.data
  },
  // Do something with response error
  (error) => {
    console.log(error)
    if (error.response && error.response.status === 401) {
      // return login()
    }
    // return Promise.reject(error)
  },
)

export default instance

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

export interface IResponseListType<T> {
  page: number
  pageSize: number
  total: number
  list: T[]
}
