import React, { memo, useCallback } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { InputProps } from 'antd/lib/input'
import { FormItemProps, FormInstance } from 'antd/lib/form'
import FormInputItem from './FormItem'

export interface LoginItemType {
  Account: React.FC<LoginItemProps>
  Password: React.FC<LoginItemProps>
}

interface LoginItemConfig {
  name: string
  rules: any[]
  inputProps: InputProps & { visibilityToggle?: boolean }
}

interface LoginItemProps {
  countStatic?: number
  onGetMobileCode?: (cb: () => void) => void
  form: FormInstance
}

const config: { [key in keyof LoginItemType]: LoginItemConfig } = {
  Account: {
    name: 'username',
    inputProps: {
      prefix: <UserOutlined />,
      placeholder: '11位合法手机号',
      type: 'mobile',
    },
    rules: [{ required: true, message: '请输入合法手机号', len: 11 }],
  },
  Password: {
    name: 'password',
    inputProps: {
      prefix: <LockOutlined />,
      placeholder: '大于6位的密码',
      type: 'password',
      visibilityToggle: true,
    },
    rules: [{ required: true, message: '请输入合法密码', min: 5 }],
  },
}

const formProps: FormItemProps = {
  hasFeedback: true,
  children: null,
}

function Account(props: LoginItemProps) {
  return <FormInputItem formProps={formProps} {...config.Account} {...props} />
}

function Password(props: LoginItemProps) {
  return <FormInputItem formProps={formProps} {...config.Password} {...props} />
}

const LoginItem: LoginItemType = {
  Account: memo(Account),
  Password: memo(Password),
}

export default LoginItem
