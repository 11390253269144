import http, { IResponse, IResponseListType } from '../../lib/http'
import { IPageInfo } from '../../types/common'
import { IGroupInfo, IPracticeInfo, IRegUserResquest, IUserInfo } from '../../types/user'

export interface IStudentInfo {
  id?: number
  phone: string
  name: string
  accountState: string
  birthday: string
  idCardNo: string
  address: string
  gender: string
}

export async function getStudentList(params: IPageInfo): Promise<any> {
  return http.get('/manager/user/list', { params })
}

export async function getStudent(userId: number): Promise<IResponse<IStudentInfo>> {
  return http.get('/manager/user/query', { params: { userId } })
}

export async function addStudent(data: IStudentInfo): Promise<any> {
  return http.post('/manager/user/add', data)
}

export async function updateStudent(data: IStudentInfo): Promise<any> {
  return http.post('/manager/user/update', data)
}

export async function deleteStudent(id: number): Promise<any> {
  return http.get('/manager/user/delete', { params: { id } })
}

export async function searchStudent(data: { content: string }): Promise<any> {
  return http.post('/manager/user/search', data)
}

export async function regPcAccount(data: IRegUserResquest): Promise<any> {
  return http.post('/manager/regPcAccount', data)
}

export async function deletePcAccount(params: { id: number }): Promise<any> {
  return http.get('/manager/deleteUserById', { params })
}

export async function getGroupList(
  params: IPageInfo,
): Promise<IResponse<IResponseListType<IGroupInfo>>> {
  return http.get('/manager/group/list', { params })
}

export async function addGroup(groupName: string): Promise<IResponse<IGroupInfo>> {
  return http.post('/manager/group/createGroup', { groupName })
}

export async function deleteGroup(groupId: number): Promise<any> {
  return http.get('/manager/group/deleteGroup', { params: { groupId } })
}

export async function getGroupUsers(
  groupId: number,
  page: IPageInfo,
): Promise<IResponse<IResponseListType<IUserInfo>>> {
  return http.get('/manager/group/userList', { params: { groupId, ...page } })
}

export async function addUserToGroup(groupIds: string[], users: number[]): Promise<any> {
  return http.post('/manager/group/addToGroup', { groupIds, userIds: users })
}

export async function removeUserFromGroup(groupIds: string[], users: number[]): Promise<any> {
  return http.post('/manager/group/removeFromGroup', { groupIds, userIds: users })
}

export async function setPracticeState(groupId: number, state: string): Promise<any> {
  return http.post('/manager/exam/setPracticeState', { groupId, state })
}

export async function getPracticeChange(userId: number): Promise<IResponse<IPracticeInfo>> {
  return http.get('/manager/exam/getPracticeChance', { params: { userId } })
}

export async function addPracticeChange(
  userId: number,
  chances: { [key: string]: number },
): Promise<IResponse<any>> {
  return http.post('/manager/exam/addPracticeChance', { userId, chances })
}

export async function queryExamPlanStudent(
  planId: number,
): Promise<IResponse<IResponseListType<IUserInfo>>> {
  return http.get('/manager/exam/queryExamPlanParticipant', { params: { planId } })
}
