import { useState, useCallback } from 'react'
import queryString from 'query-string'
import MultipleQuestion from '../common/multipleQuestion'
import './index.scss'

function AnswerHistory(props: any) {
  const query: any = queryString.parse(props.location.search)
  let examId = ''
  if (query.examId) {
    examId = query.examId
  }
  return (
    <div className="answer-page">
      <h2 className="title">答案回看</h2>
      <MultipleQuestion examId={examId} isRes={true} location={props.location} />
    </div>
  )
}

export default AnswerHistory
