import { useState, useCallback } from 'react'
import { Button, Form, message } from 'antd'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { login } from '../../api/login'
import FormWrap from '../../component/FormWrap'
import LoginItem from '../../component/LoginItem'
import { Image } from 'antd'
import Logo from '../../assets/logo.jpg'
import { HttpCode } from '../../lib/constant'

interface FormProp {
  username: string
  password: string
}

function Login() {
  const history = useHistory()
  const [activeTab] = useState('account')
  const [form] = Form.useForm()

  const next = () => {
    const params = new URLSearchParams(window.location.search)
    const redirectURL = params.get('redirectURL')
    if (redirectURL) {
      window.location.href = redirectURL
      return
    }
    history.push('/admin/exam-manage')
  }

  const onSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        const values = res as FormProp
        if (values.username && values.password) {
          login({
            phone: values.username,
            password: values.password,
          })
            .then((res) => {
              if (res?.code === HttpCode.SUCCESS) {
                console.log('登录成功', res)
                next()
              }
            })
            .catch((e) => {
              console.log(e)
            })

          return
        }
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className="login-flex">
      <Image className="logo" width={300} src={Logo} preview={false} />
      <h1>速记星超常儿童智力甄别和训练系统</h1>
      <FormWrap className="page-login">
        <Form onFinish={onSubmit} form={form}>
          <>
            <LoginItem.Account form={form} />
            <LoginItem.Password form={form} />
          </>
          <Form.Item>
            <Button block htmlType="submit" type="primary">
              登录
            </Button>
          </Form.Item>
        </Form>
      </FormWrap>
    </div>
  )
}

export default Login
