import { useEffect, useState } from 'react'
import '../index.scss'
import './index.scss'
import { Button, message, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
// import RhombCanvas from '../../../utils/rhombCanvas'
import { decodePath, formatTime, formatTimeByNumber, transformRule } from '../../../utils/util'
import {
  queryCurrentQuestion,
  submitAnswerGroup,
  queryHistoryExam,
  queryHistoryExamResult,
} from '../../../api/answer'
import E808B2 from '../../../component/E808B2'
import E808C from '../../../component/E808C'
import { cloneDeep, isFunction } from 'lodash'

// 每一种题型一次显示的数量
const ShowSize: any = {
  E808B2: 12,
  E808C: 12,
}

// 每一种题型的答案选项
const ANSWER: any = {
  E808B2: ['0', '1', '3'],
  E808C: ['0', '1', '3'],
}

/**
 * 每次显示多个题目的试卷，如 E808B2
 */
function E808B2History(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location?.search || '')
  // const query = props.location.query;
  let id = ''
  if (props.examId) {
    id = props.examId
  } else if (query.examId) {
    id = query.examId
  }
  const isPlan = !!props.isPlan

  const [answers, setAnswers] = useState<{ [key: string]: { answer: string; result: boolean } }>({})
  const [total, setTotal] = useState<number>(0)
  // 试卷结束时间
  const [endTimeStamp, setEndTimeStamp] = useState<number>(0)
  // 题型
  const [subject, setSubject] = useState<string>('')
  // 试题
  const [list, setList] = useState<any[]>([])
  // 当前选择项
  const [select, setSelect] = useState<string[]>([])
  const [select1, setSelect1] = useState<string[]>([])
  // 当前在第几组
  const [groupIndex, setGroupIndex] = useState<number>(0)
  // 当前起始题在第几组
  const [startGroupIndex, setStartGroupIndex] = useState<number>(0)

  useEffect(() => {
    queryHistoryExamResult({ examId: id }).then((res) => {
      setAnswers(res.data.answers)
      setList(res.data.list)
      setTotal(res.data.total)
      setSubject(res.data.subject)
      setStartGroupIndex(Math.floor(res.data.list[0].questionIndex / ShowSize[res.data.subject]))
    })
  }, [])

  const getActiveList = () => {
    const size = ShowSize[subject]
    const startIndex = groupIndex * size
    return list.slice(startIndex, startIndex + size)
  }

  const getShowTotal = () => {
    return total - Math.ceil(total / ShowSize[subject])
  }

  // 是否是最后一组
  const isLast = () => {
    return (groupIndex + 1) * ShowSize[subject] >= list.length
  }

  const handleNext = async () => {
    if (isLast()) {
      if (isPlan) {
        Modal.confirm({
          content: '已经到最后一题，点击ok关闭',
          onOk: () => {
            isFunction(props.cancel) && props.cancel()
          },
        })
      } else {
        Modal.confirm({
          content: '已经到最后一题，点击ok返回首页',
          onOk: () => {
            history.push('/')
          },
        })
      }
    } else {
      setGroupIndex(groupIndex + 1)
    }
  }

  const handleSelect = (ans: string, i: number) => {
    const cloneSelect = cloneDeep(select)
    cloneSelect[i] = ans
    setSelect(cloneSelect)
  }

  const handleSelect1 = (ans: string, i: number) => {
    const cloneSelect = cloneDeep(select1)
    cloneSelect[i] = ans
    setSelect1(cloneSelect)
  }

  const ExamDescription = (subject: string) => {
    return {
      E808B2: (
        <div>
          <p>观察相邻两图三角形的边的颜色，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>再观察相邻两图的符号形状，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>边的颜色在上，符号的形状在下。</p>
        </div>
      ),
      E808C: (
        <div>
          <p>将图脑补完整，再按照以下要求选择选项；</p>
          <p>观察相邻两图三角形的边的颜色，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>再观察相邻两图的符号形状，全不同涂0，一个相同涂1，三个相同涂3；</p>
          <p>边的颜色在上，符号的形状在下。</p>
        </div>
      ),
    }[subject]
  }

  const DescAndAction = (subject: string) => {
    const activeQuestionList = getActiveList()
    if (subject === 'E808B2') {
      return (
        <div className="e808b2-questions">
          <div className="e808b2-question-box">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="e808b2-question" key={index}>
                  <E808B2 data={item.question} width={80} height={72} />
                  {index < activeQuestionList.length - 1 && (
                    <div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={[
                                `${
                                  answers[item.questionIndex].result === false &&
                                  answers[item.questionIndex].answer[0].indexOf(_) > -1 &&
                                  item.rightAnswer[0].indexOf(_) < 0
                                    ? 'e808b2-ans-item wrong'
                                    : ''
                                }`,
                                'e808b2-ans-item' +
                                  (`${item.rightAnswer[0]}`.indexOf(_) > -1 ? ' right' : ''),
                              ].join(' ')}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={[
                                `${
                                  answers[item.questionIndex].result === false &&
                                  answers[item.questionIndex].answer[1].indexOf(_) > -1 &&
                                  item.rightAnswer[1].indexOf(_) < 0
                                    ? 'e808b2-ans-item wrong'
                                    : ''
                                }`,
                                'e808b2-ans-item' +
                                  (`${item.rightAnswer[1]}`.indexOf(_) > -1 ? ' right' : ''),
                              ].join(' ')}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if (subject === 'E808C') {
      return (
        <div className="e808b2-questions">
          <div className="e808b2-question-box">
            {activeQuestionList.map((item, index) => {
              return (
                <div className="e808b2-question" key={index}>
                  <E808C data={item.question} width={80} height={72} />
                  {index < activeQuestionList.length - 1 && (
                    <div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={[
                                `${
                                  answers[item.questionIndex].result === false &&
                                  answers[item.questionIndex].answer[0].indexOf(_) > -1 &&
                                  item.rightAnswer[0].indexOf(_) < 0
                                    ? 'e808b2-ans-item wrong'
                                    : ''
                                }`,
                                'e808b2-ans-item' +
                                  (`${item.rightAnswer[0]}`.indexOf(_) > -1 ? ' right' : ''),
                              ].join(' ')}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                      <div className="e808b2-ans-item-box">
                        {ANSWER[subject].map((_: string) => {
                          return (
                            <div
                              key={_ + index}
                              className={[
                                `${
                                  answers[item.questionIndex].result === false &&
                                  answers[item.questionIndex].answer[1].indexOf(_) > -1 &&
                                  item.rightAnswer[1].indexOf(_) < 0
                                    ? 'e808b2-ans-item wrong'
                                    : ''
                                }`,
                                'e808b2-ans-item' +
                                  (`${item.rightAnswer[1]}`.indexOf(_) > -1 ? ' right' : ''),
                              ].join(' ')}
                            >
                              [{_}]
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
  // const disabledNextButton = select.some((item) => !item)

  const ButtonText = isLast() && !isPlan ? '返回首页' : '下一组'

  const showTotal = getShowTotal()

  return (
    <div className="answer-page">
      <h2 className="title">题目回看</h2>
      {/* 题目描述 */}
      {ExamDescription(subject)}

      {/* 公用 */}
      <p className="current-test">
        当前第 {groupIndex + 1 + startGroupIndex} 组，每组 {ShowSize[subject] - 1} 题
      </p>

      {/* 题目示意 包含变化区和展示区 */}
      {DescAndAction(subject)}

      {/* 底部提交按钮 */}
      <div className="footer">
        {
          <div>
            <Button type="primary" onClick={() => handleNext()}>
              {ButtonText}
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default E808B2History
