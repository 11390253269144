import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Button,
  Select,
  Image,
  Table,
  Space,
  Tag,
  message,
} from 'antd'
import { IQuestion } from '../../../../../../types/question'
import { IPageInfo } from '../../../../../../types/common'
import {
  getLogicalreasoningList,
  getQuestionGroupList,
} from '../../../../../../api/admin/question'

interface ILogicPreviewProps {
  limit: number
  data: number[]
  onSave: (selects: number[]) => void
}

function LogicPreivew(props: ILogicPreviewProps) {
  const { limit, onSave, data } = props;
  // let temp_data = [];
  // if (data) {
  //   temp_data = JSON.parse(data)
  // }
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<IQuestion[]>([])
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [total, setTotal] = useState<number>(0)
  const [selects, setSelects] = useState<number[]>(data ?? [])

  useEffect(() => {
    getList()
  }, [pageInfo])

  const getList = () => {
    getLogicalreasoningList(pageInfo).then((res) => {
      if (res.code === 0) {
        setTotal(res.data.total)
        setList(res.data.list)
      }
    })
  }

  const handleAdd = (question: IQuestion) => {
    if (selects?.length === limit) {
      message.warning('题目配置数量达到上限')
      return
    }
    const temp = new Set([...selects, question.id])
    console.log(Array.from(temp))
    setSelects(Array.from(temp))
  }

  const handleDel = (id: number) => {
    const temp = selects.filter(item => item !== id)
    console.log(temp)
    setSelects(temp)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '问题类型',
      dataIndex: 'questionGroupName',
    },
    {
      title: '题目预览',
      dataIndex: 'questionField',
      render: (record: string) => {
        const question = JSON.parse(record)
        return <Image height={40} src={question[0]} />
      }
    },
    {
      title: '选项预览',
      dataIndex: 'optionField',
      render: (record: string) => {
        const option = JSON.parse(record)
        return <Image height={40} src={option[0]} />
      }
    },
    {
      title: '操作',
      render: (text: string, question: IQuestion) => (
        <Space size="middle">
          <Button size="small" onClick={() => handleAdd(question)}>
            选择
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="question-manage">
      <div className="header-actions">
        <h5>已选择:</h5>
        {selects?.map(item => (
          <Tag key={item} closable onClose={() => handleDel(item)}>{item}</Tag>
        ))}
        <Button onClick={() => onSave?.(selects)} type="primary">确定添加</Button>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={{
          pageSize: pageInfo.pageSize,
          total: total,
          onChange: (page: number, pageSize) => {
            setPageInfo({ ...pageInfo, page: page - 1 })
          },
        }}
      />
    </div>
  )
}

export default LogicPreivew 
