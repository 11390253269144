//@ts-nocheck
import React, { useEffect, useRef, useState, memo, useMemo, Suspense } from 'react'
import { cloneDeep } from 'lodash'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import THREE, { BoxHelper } from 'three'
import queryString from 'query-string'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Button, Input } from 'antd'
import { useHelper, OrbitControls } from '@react-three/drei'
import * as qiniu from 'qiniu-js'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getQiniuTokenn } from '../api/common'
import './Cube.scss'
import { base64ImgtoFile } from '../utils/util'

interface IBox {
  position: number[]
}

const colors = [
  '#0F77D2',
  '#3FC0A0',
  '#6C81AA',
  '#FFB40C',
  '#F37B3F',
  '#F9B796',
  '#5DC6F1',
  '#8C66CC',
  '#A0C75A',
  '#996960',
  '#CC5B8F',
]
function randomHexColor() {
  // const colors = [
  // '#0F77D2',
  // '#3FC0A0',
  // '#6C81AA',
  // '#FFB40C',
  // '#F37B3F',
  // '#F9B796',
  // '#5DC6F1',
  // '#8C66CC',
  // '#A0C75A',
  // '#996960',
  // '#CC5B8F',
  // ]
  // const index = Math.floor(Math.random() * colors.length)
  // return colors[index]
  //随机生成十六进制颜色
  return '#' + ('00000' + ((Math.random() * 0x1000000) << 0).toString(16)).substr(-6)
}

function Box(props: JSX.IntrinsicElements['mesh']) {
  const cubeRef = useRef<THREE.Mesh>(null!)
  useHelper(cubeRef, BoxHelper, 'black')
  // useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  return (
    <mesh {...props} ref={cubeRef}>
      <boxGeometry args={[1, 1, 1]} />
      <meshNormalMaterial wireframe={false} />
    </mesh>
  )
}

function RenderCanvas({
  boxs,
  pos,
  width,
  height,
  isHand,
  isShowOperator,
  isDesc,
  localIndex,
  onSaveAsImage,
}: {
  boxs: ICubes[]
  pos: ICubes
  width: number
  height: number
  isHand: boolean
  isDesc?: boolean
  localIndex?: number
  isShowOperator?: boolean
  onSaveAsImage?: (isDesc: boolean, localIndex: number, imgUrl: string) => void
}) {
  const canvasRef = useRef(Canvas)
  const { x, y, z } = pos
  console.log(x, y, z)
  // useThree(({ camera }) => {
  // console.log(camera)
  // camera.position.set(x, y, z)
  // })

  const uploadFile = (file: File, key: string, token: string) => {
    const observer = {
      next(res: any) {
        // ...
      },
      error(err: any) {
        console.log(err)
        // ...
      },
      complete(res: any) {
        console.log(res, '🚀 res')
        const imgUrl = `https://edudata.computingis.fun/${res.key}`
        onSaveAsImage(isDesc, localIndex, imgUrl)
        setIsSaved(true)
        // ...
      },
    }
    const observable = qiniu.upload(file, key, token)
    const subscription = observable.subscribe(observer) // 上传开始
  }

  const handleSaveImage = async () => {
    const canvasNode = canvasRef.current

    const imageData = canvasNode.toDataURL('image/png')
    const img = base64ImgtoFile(imageData, new Date().getTime())
    const res = await getQiniuTokenn()
    uploadFile(img, new Date().getTime() + '-' + img.name, res.data)
  }

  const handlePreviewImage = () => {}

  return (
    <div className="canvas-contains" style={{ height: height + 'px', width: width + 'px' }}>
      <Canvas
        gl={{ preserveDrawingBuffer: true }}
        ref={canvasRef}
        camera={{ fov: 45, near: 0.1, far: 1000, position: [x, y, z] }}
      >
        <color attach="background" args={['whitesmoke']} />
        {boxs.length > 0 &&
          boxs.map((item: ICubes, index: number) => {
            return <Box key={index} position={[item.x, item.y, item.z]} color={colors[index]} />
          })}
        {isHand ? (
          <OrbitControls
            minAzimuthAngle={0.1}
            maxAzimuthAngle={1}
            minPolarAngle={0.1}
            maxPolarAngle={1}
            dampingFactor={0.3}
          />
        ) : (
          <OrbitControls />
        )}
      </Canvas>
      {isShowOperator && (
        <div className="operation">
          <a onClick={handleSaveImage}>保存</a>
        </div>
      )}
    </div>
  )
}

interface ICubes {
  x: number
  y: number
  z: number
}

interface IThreeProps {
  height: number
  width: number
  length?: number // 摄像机距离
  data: ICubes[]
  rotateCamera?: boolean
  isHand?: boolean
  isDesc?: boolean
  localIndex?: number
  isShowOperator?: boolean
  onSaveAsImage?: (isDesc: boolean, localIndex: number, imgUrl: string) => void
}

function Three(props: IThreeProps) {
  const {
    isDesc,
    localIndex,
    height,
    width,
    data,
    rotateCamera = true,
    length = 10,
    isHand = true,
    onSaveAsImage,
    isShowOperator,
  } = props
  const angelX = (Math.random() * 360 * Math.PI) / 180
  const angelY = (Math.random() * 180 * Math.PI) / 180
  const r = length * Math.sin(angelX)
  const x = r * Math.cos(angelY)
  const y = r * Math.sin(angelY)
  const z = length * Math.cos(angelX)
  // const x = Math.floor(Math.random() * 9 + 2)
  // const y = Math.floor(Math.random() * 9 + 2)
  // const z = Math.floor(Math.random() * 9 + 2)
  // const x = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 5
  // const y = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 5
  // const z = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 3
  const [cubes, setCubes] = useState<ICubes[]>([])

  const [position, setPosition] = useState<ICubes>(
    rotateCamera
      ? {
          x,
          y,
          z,
        }
      : { x: 8, y: 8, z: 8 },
  )
  const history = useHistory()

  return (
    <div className="three">
      <RenderCanvas
        isDesc={isDesc}
        localIndex={localIndex}
        onSaveAsImage={onSaveAsImage}
        boxs={data}
        pos={position}
        height={height}
        width={width}
        isHand={isHand}
        isShowOperator={isShowOperator}
      />
    </div>
  )
}

export default memo(Three)
