import http, { IResponse, IResponseListType } from '../../lib/http'
import { IPageInfo, Response } from '../../types/common'
import {
  IExamItem,
  IExamPlanAddRequest,
  IExamPlanQuestionSetRequest,
  IExamPlanQuestionSetResponse,
  IExamPlanResult,
  IPreviewProbleType,
  IStartExamResponse,
} from '../../types/exam'
import { IQuestion } from '../../types/question'

export interface IPlanType {
  id: number
  title: string
  level: string
  startTime: string
}

export interface IPlanPayload {
  id?: number
  title: string;
  level: string;
  tests: {
    first: {
      subTitle: string;
      id: number;
      startTime: any;
      classRoom: string;
      type: string;
      remark: string;
    },
    second: {
      subTitle: string;
      startTime: any;
      id: number;
      classRoom: string;
      type: string;
      remark: string;
    }
  };
  testAddress: string;
  notice: string;
  estimateReportTime: any;
  routes: string[];
}

export interface IUnitInfo {
  id: number
  testId: number
  planId: number //考试计划id
  totalQuestions: number //套题总数
  totalMinutes: number
  sortIndex: number
  randomSeed: number
  runtimeParams: string
  questionIds: number[]
  type: string // 考试类型
  questionGroupIds: number[]
}


// 查询考试计划列表
export async function getExamPlanList(
  params: IPageInfo,
): Promise<IResponse<IResponseListType<IPlanType>>> {
  return http.get('/manager/plan/list', { params })
}

// 新增考试计划
export async function addExamPlan(data: IPlanPayload): Promise<IResponse<IExamItem>> {
  return http.post('/manager/plan/add', data)
}

export async function updateExamPlan(data: IPlanPayload): Promise<IResponse<IExamItem>> {
  return http.post('/manager/plan/update', data)
}

export async function getExamPlanDetail(
  params: { id: number }
): Promise<IResponse<IPlanPayload>> {
  return http.get('/manager/plan/detail', { params })
}

// 删除考试计划
export async function deleteExamPlan(params: { id: number }): Promise<any> {
  return http.get('/manager/plan/delete', { params })
}

// 新增考试人员
export async function addExamPlanParticipant(
  data: {
    userIds: number[]
    planId: number
  },
): Promise<any> {
  return http.post('/manager/plan/bind/add', data)
}

// 查询考试分配
export async function getBindlist(
  params: IPageInfo & { planId: number },
): Promise<IResponse<any>> {
  return http.get('/manager/plan/bind/list', { params })
}


// 移除考试人员
export async function deleteExamPlanParticipant(
  data: {
    userIds: number[]
    planId: number
  },
): Promise<any> {
  return http.post('/manager/plan/bind/unbind', data)
}

// 添加考试套题
export async function addExamPlanQuestionSet(data: IUnitInfo): Promise<any> {
  return http.post('/manager/unit/add', data)
}

// 获取考试单元套题
export async function queryExamPlanQuestionSet(params: {
  testId: number
}): Promise<IResponse<IUnitInfo[]>> {
  return http.get('/manager/unit/list', { params })
}

// 更新考试套题
export async function updateExamPlanSubject(data: IUnitInfo): Promise<any> {
  return http.post('/manager/unit/update', data)
}

// 删除考试套题
export async function deleteExamPlanQuestionSet(params: { id: number }): Promise<any> {
  return http.get('/manager/unit/delete', { params })
}

export async function getExamDetail(params: { examId: string }): Promise<any> {
  return http.get('/user/exam/unit/detail', { params })
}

export async function queryExamPlan(): Promise<Response<any>> {
  return http.get('/user/exam/queryExamPlan')
}

export async function queryHistoryExamResult(params: {
  planId: number
}): Promise<IResponse<IStartExamResponse>> {
  return http.get('/user/exam/queryHistoryExamResult', { params })
}

// 查询考试结果
export async function queryExamPlanResult(params: {
  planId: number
}): Promise<IResponse<IExamPlanResult[]>> {
  return http.get('/manager/exam/queryExamPlanResult', { params })
}

// 预览套题
export async function previewQuestionSet(params: {
  size: number
  unitId: number
}): Promise<IResponse<any>> {
  return http.get('/manager/unit/previewQuestionSet', { params })
}
