import React, { useState, useCallback, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './index.scss'
import { getUserInfo } from '../../../api/userInfo'

export interface IMenuType {
  name: string
}

function Menu(props: IMenuType) {
  const history = useHistory()
  return (
    <ul className="menu-nav">
      <li>你好，{props.name}</li>
      <li
        className="logout"
        onClick={() => {
          history.push('/login')
        }}
      >
        {' '}
        退出{' '}
      </li>
    </ul>
  )
}

export default Menu
