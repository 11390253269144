import './index.scss'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { examPlanSummary } from '../../../api/exam'
import queryString from 'query-string'
import { Table, Button, Modal } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { IExamPlanSummary, IQuestionSet } from '../../../types/exam'
import { TitleEnum, GroupCutList } from '../../../constants'

import MultipleQuestion from '../../common/multipleQuestion'
import GroupQuestion from '../../answer/group/history'
import GroupCutQuestion from '../../answer/e808b2/history'
import { isNumber } from 'lodash'

function ExamHistory(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location?.search)
  const planId = query.id || ''
  const [list, setList] = useState<IQuestionSet[]>([])
  const [showAnswer, setShowAnswer] = useState<boolean>(false)
  const [showExamId, setShowExamId] = useState<string>('')
  const [showExamType, setShowExamType] = useState<string>('')

  useEffect(() => {
    initData()
  }, [])

  const initData = () => {
    examPlanSummary({ planId }).then((res) => {
      if (res.data.questionSets) {
        setList(res.data.questionSets)
      }
    })
  }

  const onShowAnswer = (exam: IQuestionSet) => {
    if (exam.session?.examId) {
      setShowExamId(exam.session?.examId)
      setShowExamType(exam.subject)
      setShowAnswer(true)
    }
  }

  const columns: ColumnsType<IQuestionSet> = [
    {
      title: 'ID',
      dataIndex: 'questionSetId',
      key: 'questionSetId',
    },
    {
      title: '题型',
      dataIndex: 'subjectName',
      key: 'subjectName',
      render: (_: unknown, exam: IQuestionSet) => {
        return TitleEnum[exam.subject] || '-'
      },
    },
    {
      title: '题目数',
      dataIndex: 'totalQuestionSize',
      key: 'totalQuestionSize',
    },
    {
      title: '正确数',
      dataIndex: 'rightAnswers',
      key: 'rightAnswers',
      render: (_: unknown, exam: IQuestionSet) => {
        return (
          <span style={{ color: 'green' }}>
            {isNumber(exam.session?.rightAnswers) ? exam.session?.rightAnswers : '-'}
          </span>
        )
      },
    },
    {
      title: '错误数',
      dataIndex: 'wrongAnswers',
      key: 'wrongAnswers',
      render: (_: unknown, exam: IQuestionSet) => {
        let renderStr = '-'
        if (isNumber(exam.session?.wrongAnswers)) {
          // fix: n-1题型 wrongAnswers 存在多1的情况
          if (exam.session?.wrongAnswers > 0 && GroupCutList.includes(exam.subject)) {
            renderStr = `${exam.session?.wrongAnswers - 1}`
          } else {
            renderStr = `${exam.session?.wrongAnswers}`
          }
        }
        return <span style={{ color: 'red' }}>{renderStr}</span>
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_: unknown, exam: IQuestionSet) => {
        return (
          <Button size="small" type="primary" onClick={() => onShowAnswer(exam)}>
            回看
          </Button>
        )
      },
    },
  ]

  const renderAnswer = () => {
    if (!showAnswer) {
      return null
    }
    const AnswerBox = () => {
      if (showExamType === 'E808B' || showExamType === 'E808D') {
        // 显示每页一组的试题
        return (
          <GroupQuestion examId={showExamId} isPlan={true} cancel={() => setShowAnswer(false)} />
        )
      } else if (showExamType === 'E808B2' || showExamType === 'E808C') {
        // 显示每页一组，提交n-1
        return (
          <GroupCutQuestion examId={showExamId} isPlan={true} cancel={() => setShowAnswer(false)} />
        )
      } else {
        // 显示每页一题的试题
        return (
          <MultipleQuestion
            examId={showExamId}
            isRes={true}
            isExam={true}
            cancel={() => setShowAnswer(false)}
          />
        )
      }
    }

    return (
      <Modal
        title="考试结果回看"
        onCancel={() => setShowAnswer(false)}
        footer={null}
        width={'100%'}
        visible={showAnswer}
        maskClosable={false}
      >
        <div>{AnswerBox()}</div>
      </Modal>
    )
  }

  const handleBackHome = () => {
    history.replace('/')
  }

  return (
    <div className="exam-history">
      <div className="exam-history-table-header">
        <h2 className="title text-center">考试结果查看</h2>
        <Button onClick={handleBackHome}>回到首页</Button>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        rowKey="questionSetId"
        pagination={{ pageSize: 100 }}
      />

      {renderAnswer()}
    </div>
  )
}

export default ExamHistory
