function CubeComplement(params: {}) {
  return (
    <div>
      <p>
        你需要先观察左侧的立方体结构，然后在右侧8个选项中找出能与之恰好拼成一个正方体（即长宽高都相同）的互补结构。
      </p>
      <p>正式甄别过程中请按题目顺序依次作答，且点击“下一题”后不能返回修改答案。</p>
    </div>
  )
}

export default CubeComplement
