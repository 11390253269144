function WhackAMole() {
  return (
    <div>
      <p> 下面四个方框中可能藏着地鼠，请你试着敲击，如果击中了藏有地鼠的方格， </p>
      <p> 你将会得到一分，注意地鼠的出现是有一定规律性的，请找到地鼠出现的规律， </p>
      <p> 尽可能的找到更多的地鼠，下面开始你的打地鼠之旅吧。</p>
    </div>
  )
}

export default WhackAMole
