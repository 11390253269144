function BlockRotate(params: {}) {
  return (
    <div>
      <p>
        每个题目中会呈现一个有 9-10
        个小立方体构成的立体图形，选项中会呈现六个类似的立体图形，其中只有一个或两个是由题目中的图形旋转得到的，它与题目中的立体图形完全相同，只是呈现的角度不同。
      </p>
      <p>请你想象题目中图形旋转后，能够得到哪个图形，请把它选出来。</p>
      <p>请在保证正确性的情况下尽快作答。</p>
    </div>
  )
}

export default BlockRotate
