import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './index.scss'
import {
  Table,
  Space,
  Tag,
  Card,
  Button,
  Drawer,
  Input,
  Form,
  message,
  Modal,
  Transfer,
} from 'antd'

import { IPageInfo } from '../../../types/common'
import { getGroupUsers } from '../../../api/admin/user'
import { IGroupInfo, IUserInfo } from '../../../types/user'
import {
  addQuestionGroup,
  deleteQuestionGroup,
  getQuestionGroupList,
} from '../../../api/admin/question'

const DefaultFormData: Partial<IGroupInfo> = {
  groupName: '',
}

function QuestionGroup() {
  const [list, setList] = useState<IGroupInfo[]>([])
  const [pageInfo, setPageInfo] = useState<IPageInfo>({ page: 0, pageSize: 20 })
  const [total, setTotal] = useState<number>(0)

  const [visible, setVisible] = useState(false)
  const [visibleGroup, setVisibleGroup] = useState<boolean>(false)
  const [currentGroupId, setCurrentGroupId] = useState<number>(-1)
  const [saveLoading, setSaveLoading] = useState(false)
  const [groupFormData, setUserFormData] = useState({ ...DefaultFormData })

  useEffect(() => {
    getList()
  }, [pageInfo])

  const getList = () => {
    getQuestionGroupList(pageInfo).then((res) => {
      if (res.code === 0) {
        setTotal(res.data.total)
        setList(res.data.list)
      }
    })
  }

  const handleGroupConfig = (item: IGroupInfo) => {
    setCurrentGroupId(item.id)
    setVisibleGroup(true)
  }

  const renderGroupConfig = () => {
    const [currentUsers, setCurrentUsers] = useState<IUserInfo[]>([])
    useEffect(() => {
      if (currentGroupId === -1) return
      getGroupUsers(currentGroupId, { page: 0, pageSize: 10000 }).then((res) => {
        setCurrentUsers(res.data.list)
      })
    }, [currentGroupId])
    return (
      <div>
        <Modal
          title="组员"
          onCancel={() => setVisibleGroup(false)}
          footer={null}
          visible={visibleGroup}
          maskClosable={false}
        >
          {currentUsers &&
            currentUsers.map((u) => (
              <Tag key={u.id}>
                {u.nickName} + {u.phone}
              </Tag>
            ))}
        </Modal>
      </div>
    )
  }

  const handleDelete = (item: IGroupInfo) => {
    Modal.confirm({
      title: '删除确认',
      content: `你确认要删除用户（${item.groupName}）吗？`,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        deleteQuestionGroup(item.id).then((res) => {
          if (res.code === 0) {
            message.success('删除成功')
            setPageInfo({ ...pageInfo, page: 0 })
          } else {
            message.success('删除失败：', res.message)
          }
        })
      },
    })
  }

  const handleAdd = () => {
    setUserFormData({ ...DefaultFormData })
    setVisible(true)
  }

  const handleSubmitAdd = () => {
    if (!groupFormData.groupName) {
      message.error('请输入组名')
      return
    }
    setSaveLoading(true)
    addQuestionGroup(groupFormData.groupName)
      .then((res) => {
        if (res.code === 0) {
          message.success('添加组成功')
          setVisible(false)
          setPageInfo({ ...pageInfo, page: 0 })
        } else {
          message.error('添加组失败')
        }
      })
      .catch((error) => {
        message.error('添加组失败： ', error.message)
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const handleChangeFormData = (e: any, key: string) => {
    if (e.target.value && typeof e.target.value === 'string') {
      setUserFormData({ ...groupFormData, [key]: e.target.value })
    }
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '题目组名',
      dataIndex: 'groupName',
    },
    {
      title: '创建时间',
      dataIndex: 'createdTime',
    },
    {
      title: '更新时间',
      dataIndex: 'updatedTime',
    },
    {
      title: '操作',
      render: (text: string, record: IGroupInfo) => (
        <Space size="middle">
          {/* <Button size="small" onClick={() => handleGroupConfig(record)}> */}
          {/*   查看组员 */}
          {/* </Button> */}
          <Button danger size="small" onClick={() => handleDelete(record)}>
            删除
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="user-manage">
      <div className="header-actions">
        <Button type="primary" onClick={handleAdd}>
          添加分组
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={{
          pageSize: pageInfo.pageSize,
          total: total,
          onChange: (page: number, pageSize) => {
            setPageInfo({ ...pageInfo, page: page - 1 })
          },
        }}
      />

      {/* 新增 */}
      {visible && (
        <Drawer
          title="新增分组"
          placement="right"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="组名"
              name="groupName"
              rules={[{ required: true, message: '不能为空' }]}
            >
              <Input
                value={groupFormData.groupName}
                onChange={(e) => handleChangeFormData(e, 'groupName')}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" loading={saveLoading} onClick={handleSubmitAdd}>
                新增
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      )}
      {renderGroupConfig()}
    </div>
  )
}

export default QuestionGroup
