import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const COLOR_ENUM: any = {
  G: 'green',
  R: 'red',
  B: 'blue',
  H: 'black', // 黑色
}

function E808E({ data, width, height }: { data: string; width: number; height: number }) {
  const [colorList, setColorList] = useState<string[]>([])

  const [canvasId, setCanvasId] = useState<string>(uuidv4())

  useEffect(() => {
    if (data) {
      const arr: string[] = data.split('')
      if (arr.length === 6) {
        setColorList([...arr])
      }
    }
  }, [data])

  useEffect(() => {
    renderCanvas()
  }, [colorList])

  const renderCanvas = () => {
    if (colorList.length !== 6) {
      return
    }
    const canvasDom: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
    if (!canvasDom) {
      return
    }
    const ctx: CanvasRenderingContext2D = canvasDom.getContext('2d') as CanvasRenderingContext2D
    // 找出三个点位置
    const centerX = Math.floor(width / 2)
    const centerY = Math.floor(height / 2)

    // 三角形的三个定位点
    const top: [number, number] = [centerX, 2]
    const left: [number, number] = [2, height - 1]
    const right: [number, number] = [width - 2, height - 2]

    // 立体三角形的顶点
    const center: [number, number] = [centerX, 2 + Math.floor((height / 11) * 6)]

    // 颜色
    const colors: string[] = colorList.map((c) => COLOR_ENUM[c] || 'rgba: 0,0,0,0')

    ctx.clearRect(0, 0, width, height) // 清除画布

    const renderSymbol: any = {
      left: () => {
        ctx.beginPath()
        ctx.moveTo(...top)
        ctx.lineTo(...left)
        ctx.strokeStyle = colors[0]
        ctx.stroke()
        ctx.closePath()
      },
      right: () => {
        ctx.beginPath()
        ctx.moveTo(...top)
        ctx.lineTo(...right)
        ctx.strokeStyle = colors[1]
        ctx.stroke()
        ctx.closePath()
      },
      bottom: () => {
        ctx.beginPath()
        ctx.moveTo(...left)
        ctx.lineTo(...right)
        ctx.strokeStyle = colors[2]
        ctx.stroke()
        ctx.closePath()
      },
      rightCenter: () => {
        ctx.beginPath()
        ctx.moveTo(...center)
        ctx.lineTo(...right)
        ctx.strokeStyle = colors[3]
        ctx.stroke()
        ctx.closePath()
      },
      leftCenter: () => {
        ctx.beginPath()
        ctx.moveTo(...center)
        ctx.lineTo(...left)
        ctx.strokeStyle = colors[4]
        ctx.stroke()
        ctx.closePath()
      },
      topCenter: () => {
        ctx.beginPath()
        ctx.moveTo(...center)
        ctx.lineTo(...top)
        ctx.strokeStyle = colors[5]
        ctx.stroke()
        ctx.closePath()
      },
    }

    ctx.lineWidth = 2

    renderSymbol.left()
    renderSymbol.right()
    renderSymbol.bottom()
    renderSymbol.rightCenter()
    renderSymbol.leftCenter()
    renderSymbol.topCenter()
  }

  if (colorList.length !== 6) {
    return null
  }
  return <canvas id={canvasId} width={width} height={height}></canvas>
}
export default E808E
