export interface ITypeSubject {
  [key: string]: ITypeSubjectItem[]
}

export interface ITypeSubjectItem {
  key: string
  maps: string
}

export const Subject: ITypeSubject = {
  member: [
    {
      key: 'FlashDiamond',
      maps: '舒尔特方格闪图',
    },
    {
      key: 'ImageTransform',
      maps: '图形变换',
    },
    {
      key: 'WhackAMole',
      maps: '打地鼠',
    },
  ],
  stereoscopic: [
    {
      key: 'BlockRotate',
      maps: '几何旋转',
    },
    {
      key: 'FillWall',
      maps: '立体补墙',
    },
    {
      key: 'CubeComplement',
      maps: '立方体补集',
    },
  ],
  // 图形一直显示的
  e808Invariant: [
    {
      key: 'E808APlus',
      maps: 'E808A',
    },
    {
      key: 'E808EPlus',
      maps: 'E808E',
    },
    {
      key: 'Moss',
      maps: '摩斯密码',
    },
    {
      key: 'HarderMoss',
      maps: '高级摩斯密码',
    },
  ],
  // 图形3s消失
  e808Vanish: [
    {
      key: 'E808A',
      maps: 'E808A',
    },
    {
      key: 'E808E',
      maps: 'E808E',
    },
    {
      key: 'MossCopy',
      maps: '摩斯密码',
    },
  ],
  e808Ealculate: [
    {
      key: 'E808B',
      maps: 'E808B',
    },
    {
      key: 'E808B2',
      maps: 'E808B2',
    },
    {
      key: 'E808C',
      maps: 'E808C',
    },
    {
      key: 'E808D',
      maps: 'E808D',
    },
  ],
  reasoning: [{ key: 'LogicalReasoning', maps: '逻辑推理' }],
}

export const Category: { [key: string]: string } = {
  member: '工作记忆',
  stereoscopic: '立体思维',
  e808Vanish: '阳性符号消失',
  e808Invariant: '阳性符号不变',
  e808Ealculate: '阳性符号简单计算',
  reasoning: '图形推理',
}

export const QuestionType = {
  ShadowGraphics: '阴影图形',
  TransformPlaneSpace: '转化平面空间',
  PositionalRelationship: '位置关系',
  StyleChange: '样式变化',
  QuantityChange: '数量变化',
  ConsistentChange: '一致变化',
  RefactoringRules: '重构规则',
  CommonElement: '共同元素',
}

export const TitleEnum: { [key: string]: string } = {
  E808B2: 'E808B2',
  E808EPlus: 'E808EPlus',
  BlockRotate: '几何旋转',
  E808C: 'E808C',
  E808D: 'E808D',
  E808A: 'E808A',
  CubeComplement: '立方体补集',
  E808APlus: 'E808APlus',
  E808E: 'E808E',
  E808B: 'E808B',
  ImageTransform: '图形变换',
  LogicalReasoning: '逻辑推理',
  FillWall: '立体补墙',
  FlashDiamond: '舒尔特方格闪图',
  Moss: '摩斯密码',
  MossCopy: '摩斯密码进阶',
  HarderMoss: '高级摩斯密码',
  WhackAMole: '打地鼠',
}

export const GroupList = ['E808B', 'E808D']
export const GroupCutList = ['E808B2', 'E808C']

export const EXAM_TYPE = {
  Practice: 'Practice', // 课堂练习
  Exam: 'Exam', // 模拟考试
  FinalTest: 'FinalTest', // 结业考试
}
