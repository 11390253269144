import http from '../lib/http'
import { IUserInfo } from '../types/user'

export async function getUserInfo(): Promise<{
  data: { info: IUserInfo }
  code: number
  msg: string
}> {
  return http
    .get('/user/info/my')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}
