function Moss() {
  return (
    <div>
      <p>如下所示，由 "." "|" "-" 三种元素组合而成10种符号，每组符号对应不同的代码,</p>
      <p> 请你尽可能的记住屏幕中显示的符号特征及相应的代码</p>
      <p>并尽量快尽量准确的根据符号特征选择符号代码。</p>
    </div>
  )
}

export default Moss
