import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import logo from './logo.svg'
import Home from './views/home'
import Login from './views/login'
import Test from '../src/component/test'
import NotFound from './views/404'
import './App.css'
import Answer from './views/answer'
import Training from './views/training'
import AnswerGroup from './views/answer/group/index'
import E808B2 from './views/answer/e808b2/index'
import Admin from './views/admin/index'
import AnswerHistory from './views/answer/history'
import GroupAnswerHistory from './views/answer/group/history'
import E808B2History from './views/answer/e808b2/history'
import ExamDetail from './views/exam/detail/index'
import ExamHistory from './views/exam//history/index'

function App() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/3d-demo" component={Training} />
      <Route exact path="/test" component={Test} />
      <Route exact path="/answer" component={Answer} />
      <Route exact path="/answer-group" component={AnswerGroup} />
      <Route exact path="/answer-group-history" component={GroupAnswerHistory} />
      <Route exact path="/answer-e808b2" component={E808B2} />
      <Route exact path="/answer-e808b2-history" component={E808B2History} />
      <Route exact path="/admin/*" component={Admin} />
      <Route exact path="/answer-history" component={AnswerHistory} />
      {/* 练习模式 */}
      <Route exact path="/exam-detail" component={ExamDetail} />
      <Route exact path="/exam-history" component={ExamHistory} />
      {/* 考试模式 */}
      <Route exact path="/test-detail" component={ExamDetail} />
      <Route path="/" exact component={Home} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default App
