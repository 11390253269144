import React, { useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import {
  UserSwitchOutlined,
  GroupOutlined,
  GoldOutlined,
  SettingOutlined,
  LaptopOutlined,
  FlagOutlined,
} from '@ant-design/icons'

import './index.scss'

import { Menu, MenuProps } from 'antd'

import { GlobalStoreContext } from '../../store'
import UserManage from './user-manage/index'
import answerCountManage from './answer-count-manage/index'
import questionManageList from './question-manage/list'
import HeaderNav from '../../component/HeaderNav'
import ExamListManage from './exam-manage/list'
// import GroupManage from './group-manage'
// import ClassOpen from './class-manage'
import QuestionGroup from './question-group'

function Admin() {
  const { state, dispatch } = useContext(GlobalStoreContext)
  const history = useHistory()

  const items: MenuProps['items'] = [
    { label: '考生管理', icon: <UserSwitchOutlined />, key: '/admin/user-manage' },
    // { label: '题目管理', icon: <GroupOutlined />, key: '/admin/answer-count-manage' },
    { label: '逻辑推理题目', icon: <GoldOutlined />, key: '/admin/question-manage-list' },
    { label: '逻辑推理分组', icon: <GoldOutlined />, key: '/admin/question-group' },
    { label: '考试管理', icon: <LaptopOutlined />, key: '/admin/exam-manage' },
    // { label: '分组管理', icon: <FlagOutlined />, key: '/admin/group-manage' },
    // { label: '上课开关', icon: <SettingOutlined />, key: '/admin/classOpen' },
  ]

  const onClick = (e: any) => {
    history.push(e.key)
  }

  return (
    <div className="admin">
      <HeaderNav
        name={state.userInfo?.nickName}
        title={'速记星后台管理系统'}
        redirect={'/admin/'}
      />
      <Menu className="menu-box" items={items} onClick={onClick} />
      <div className="admin-main">
        <Route exact path="/admin/user-manage" component={UserManage} />
        <Route exact path="/admin/answer-count-manage" component={answerCountManage} />
        <Route exact path="/admin/question-manage-list" component={questionManageList} />
        <Route exact path="/admin/question-group" component={QuestionGroup} />
        <Route exact path="/admin/exam-manage" component={ExamListManage} />
        {/* <Route exact path="/admin/group-manage" component={GroupManage} /> */}
        {/* <Route exact path="/admin/classOpen" component={ClassOpen} /> */}
      </div>
    </div>
  )
}

export default Admin
