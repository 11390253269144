/**
 * Axios Instance
 */

import axios, { AxiosRequestConfig, AxiosError } from 'axios'

/*
 * @see Full config:  https://github.com/axios/axios#request-config
 */
const config = {
  baseURL: process.env.REACT_APP_API_HOST || '',
  // baseURL: 'https://paper.computingis.fun',
  // baseURL: '',
  timeout: Number(process.env.REACT_APP_HTTP_TIMEOUT), // Timeout
  withCredentials: true, // Check Cross-Site Access-Control
  crossDomain: true,
}

// 添加请求拦截器
axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config
  },
  (error: AxiosError) => Promise.reject(error),
)

const instance = axios.create(config)

// 统一发起请求的函数
// export functionrequest<T>(options: AxiosRequestConfig) {
// return axios.request<T>(options);
// }
export default instance
