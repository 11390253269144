import http, { IResponse } from '../lib/http'

interface ISubmitAnswer {
  examId: string
  answer: string
  current: number
  force?: string
}

interface IQueryHistoryExam {
  examId: string
}

export interface TestInfo {
  startTime: string
  examPlanId: number
  examPlanName: string
  examId: string
  endTimeStamp: string
  type: string
  state: string
  endTime: string
  total: number
  current: number
  remains: number
  list: any[]
}

export interface ITestResponse {
  data: TestInfo
  code: number
  msg: string
}

export interface IHistoryAnswerList {
  questionIndex: number
  question: {
    id: number
    questionGroupId: number
    questionField: string[]
    optionField: string[]
    questionOption: string
    rightAnswer: string
    analysisFiled: string[]
  }
  rightAnswer: string
  questionImage: string
  answersImage: string[]
}

export interface IResponseHistoryAnswer {
  finishTime: string
  subject: string
  answers: {
    [key: string]: {
      result: boolean
      answer: string
    }
  }
  examPlanId: string
  examPlanName: string
  remains: number
  total: number
  type: string
  list: IHistoryAnswerList[]
  runtimeParams: string[]
}

export async function startTest(
  subject: string,
): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get(`/user/exam/startPractice?subject=${subject}`)
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startImageTransformTest(): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get('/user/exam/startImageTransformTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startCubeComplementTest(): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get('/user/exam/startCubeComplementTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startDiamondTransformTest(): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get('/user/exam/startDiamondTransformTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startFillWallTest(): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get('/user/exam/startFillWallTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startBlockRotateTest(): Promise<{
  data: TestInfo
  code: number
  msg: string
}> {
  return http
    .get('/user/exam/startBlockRotateTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808ATest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808ATest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808APlusTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808APlusTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808BTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808BTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808B2Test(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808B2Test?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808CTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808CTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808DTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808DTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808ETest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808ETest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startE808EPlusTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startE808EPlusTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function startLogicalReasoningTest(): Promise<ITestResponse> {
  return http
    .get('/user/exam/startLogicalReasoningTest?examType=Exam')
    .then((res: any) => {
      if (res) return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

export async function queryCurrentQuestion(params: { examId: string }): Promise<IResponse<any>> {
  return http
    .get('/user/exam/queryCurrentQuestion', { params })
    .then((res: any) => {
      return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}

// 提交答案
export async function submitAnswer(params: ISubmitAnswer): Promise<any> {
  // console.log(`submitAnswer params: `, params)
  return http
    .get('/user/exam/submitAnswer', { params })
    .then((res: any) => {
      return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}

// 批量提交答案
export async function submitAnswerGroup(data: any) {
  return http
    .post('/user/exam/batchSubmitAnswer', data)
    .then((res: any) => {
      return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}

// 获取测试结果
export async function queryHistoryExam(params: IQueryHistoryExam): Promise<any> {
  return http
    .get('/user/exam/queryHistoryExam', { params })
    .then((res: any) => {
      return res
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
      throw e
    })
}

// 查询练习答题结果及答案
export async function queryHistoryExamResult(params: {
  examId: string
}): Promise<IResponse<IResponseHistoryAnswer>> {
  return http.get('/user/exam/queryHistoryExamResult', { params })
}
