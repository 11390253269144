import { QuestionType } from '../../../constants'
const QUESTION_TYPE: any = QuestionType as any
function LogicalReasoning({ activeAnswer }: { activeAnswer: any }) {
  return (
    <div>
      {activeAnswer?.question?.questionType &&
        QUESTION_TYPE[activeAnswer.question?.questionType] && (
          <p>题目类型：{QUESTION_TYPE[activeAnswer.question?.questionType]}</p>
        )}
    </div>
  )
}

export default LogicalReasoning
