import React, { memo, useEffect, useRef, useState } from 'react'
import { cloneDeep } from 'lodash'
import * as THREE from 'three'
import queryString from 'query-string'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useHelper, OrbitControls } from '@react-three/drei'
import { Button, Input } from 'antd'
import { useHistory, useRouteMatch } from 'react-router-dom'
import './index.scss'
import { BoxHelper } from 'three'

interface IBox {
  position: number[]
}

const colors = [
  '#0F77D2',
  '#3FC0A0',
  '#6C81AA',
  '#FFB40C',
  '#F37B3F',
  '#F9B796',
  '#5DC6F1',
  '#8C66CC',
  '#A0C75A',
  '#996960',
  '#CC5B8F',
]
function randomHexColor() {
  //随机生成十六进制颜色
  return '#' + ('00000' + ((Math.random() * 0x1000000) << 0).toString(16)).substr(-6)
}

function Box(props: JSX.IntrinsicElements['mesh']) {
  const cubeRef = useRef<THREE.Mesh>(null!)
  useHelper(cubeRef, BoxHelper, 'black')
  // useFrame((state, delta) => (ref.current.rotation.x += 0.01))
  return (
    <mesh {...props} ref={cubeRef}>
      <boxGeometry args={[1, 1, 1]} />
    </mesh>
  )
}

function RenderCanvas({
  boxs,
  pos,
  width,
  height,
}: {
  boxs: ICubes[]
  pos: ICubes
  width: number
  height: number
}) {
  const { x, y, z } = pos
  console.log(x, y, z)
  // useThree(({ camera }) => {
  // console.log(camera)
  // camera.position.set(x, y, z)
  // })
  return (
    <div className="canvas-contains" style={{ height: height + 'px', width: width + 'px' }}>
      <Canvas camera={{ fov: 45, near: 0.1, far: 1000, position: [x, y, z] }}>
        <axesHelper args={[20]} />
        <color attach="background" args={['whitesmoke']} />
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {boxs.length > 0 &&
          boxs.map((item: ICubes, index: number) => {
            return <Box key={index} position={[item.x, item.y, item.z]} />
          })}
        <OrbitControls />
      </Canvas>
    </div>
  )
}

interface ICubes {
  x: number
  y: number
  z: number
}

interface IThreeProps {
  height: number
  width: number
  data: ICubes[]
  rotateCamera?: boolean
}

function Three(props: IThreeProps) {
  const angelX = (Math.random() * 360 * Math.PI) / 180
  const angelY = (Math.random() * 180 * Math.PI) / 180
  const r = 20 * Math.sin(angelX)
  const x = r * Math.cos(angelY)
  const y = r * Math.sin(angelY)
  const z = 20 * Math.cos(angelX)
  // const x = Math.floor(Math.random() * 9 + 2)
  // const y = Math.floor(Math.random() * 9 + 2)
  // const z = Math.floor(Math.random() * 9 + 2)
  // const x = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 5
  // const y = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 5
  // const z = Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 4 : 0 - Math.random() * 3
  const { height, width, data, rotateCamera = true } = props
  const [cubes, setCubes] = useState<ICubes[]>([])

  const [position, setPosition] = useState<ICubes>(
    rotateCamera
      ? {
          x,
          y,
          z,
        }
      : { x: 8, y: 8, z: 10 },
  )
  const history = useHistory()

  useEffect(() => {
    // console.log(history, 'fff')
    const query: any = queryString.parse(history.location.search)
    if (query.data) {
      try {
        const data = JSON.parse(query.data)
        setCubes(data)
      } catch (error) {
        alert('url 参数解析错误，请检查')
      }
    }
  }, [])

  const RenderDom = () => {
    return (
      <div>
        <Button
          type="primary"
          onClick={() => {
            setCubes([...cubes, { x: 0, y: 0, z: 0 }])
          }}
        >
          {' '}
          add cube
        </Button>
        <Button
          type="ghost"
          onClick={() => {
            const x =
              Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 8 : 0 - Math.random() * 8
            const y =
              Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 8 : 0 - Math.random() * 8
            const z =
              Math.floor(Math.random() * 100) % 2 === 0 ? Math.random() * 8 : 0 - Math.random() * 8
            setPosition({ x: Math.floor(x), y: Math.floor(y), z: Math.floor(z) })
          }}
        ></Button>
        {cubes.length > 0 &&
          cubes.map((item: ICubes, index: number) => {
            return (
              <div className="cubes" key={index}>
                <h3>方块{index}</h3>
                <div className="cube">
                  <span>x:</span>
                  <Input
                    type="number"
                    placeholder="x"
                    onChange={(e) => {
                      const currentCube: ICubes[] = cloneDeep(cubes)
                      currentCube[index].x = Number(e.target.value)
                      setCubes([...currentCube])
                    }}
                    value={item.x}
                  />
                  <span>y:</span>
                  <Input
                    type="number"
                    placeholder="y"
                    onChange={(e) => {
                      const currentCube: ICubes[] = cloneDeep(cubes)
                      currentCube[index].y = Number(e.target.value)
                      setCubes([...currentCube])
                    }}
                    value={item.y}
                  />
                  <span>z:</span>
                  <Input
                    type="number"
                    placeholder="z"
                    onChange={(e) => {
                      const currentCube: ICubes[] = cloneDeep(cubes)
                      currentCube[index].z = Number(e.target.value)
                      setCubes([...currentCube])
                    }}
                    value={item.z}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      const currentCube: ICubes[] = cloneDeep(cubes)
                      setCubes([
                        ...currentCube.slice(0, index),
                        ...currentCube.slice(index + 1, currentCube.length),
                      ])
                    }}
                  >
                    delete
                  </Button>
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  return (
    <div className="three">
      <RenderCanvas boxs={cubes} pos={position} width={800} height={800} />
      <RenderDom />
    </div>
  )

  return (
    <div className="three">
      <RenderCanvas boxs={data} pos={position} height={height} width={width} />
    </div>
  )
}

export default memo(Three)
