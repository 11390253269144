function E808E(params: {}) {
  return (
    <div>
      <p>
        点击“展示图例”按钮，竖线左侧图例区三角形完整显示 10 秒，请记住该三角形中三条内径的颜色，10
        秒后左侧图例区三角形内径变为黑色，右侧需要判断的三角形显示出来；
      </p>
      <p>请判断右侧三角形是否与未变化前左侧图例区三角形的颜色完全相同（包含旋转后相同）。</p>
    </div>
  )
}

export default E808E
