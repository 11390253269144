/**
 * 学生端 考试接口
 */

import http from '../lib/http'
import { IPageInfo, Response } from '../types/common'
import { IPlanItem, IStartExamResponse, IExamPlanSummary } from '../types/exam'

// 查询考试计划
export async function queryExamPlan(params?: {
  type?: string
}): Promise<Response<{ unavailable: IPlanItem[]; available: IPlanItem[] }>> {
  return http.get('/user/exam/queryExamPlan', { params })
}

// 开始、继续考试
export async function startExam(params: { planId: number }): Promise<Response<IStartExamResponse>> {
  return http.get('/user/exam/startExam', { params })
}

// [考生] 查询练习答题结果及答案
export async function queryHistoryExamResult(params: { examId: string }): Promise<Response<any>> {
  return http.get('/user/exam/queryHistoryExamResult', { params })
}

// [考生] 查询练习答题结果及答案
export async function examPlanSummary(params: {
  planId: number
}): Promise<Response<IExamPlanSummary>> {
  return http.get('/user/exam/examPlanSummary', { params })
}
