import localforage from 'localforage'
import { Subject } from '../constants'
const PREFIX = 'questionPaper_'

export function transformRule(path: [number, number, number, number], trans: string) {
  let res: [number, number, number, number]
  switch (trans) {
    case 'ReversedPhaseAll':
      res = [1 - path[0], 1 - path[1], 1 - path[2], 1 - path[3]]
      break
    case 'FlipHorizontal':
      res = [path[2], path[3], path[0], path[1]]
      break
    case 'FlipVertical':
      res = [path[1], path[0], path[3], path[2]]
      break
    case 'CW90':
      res = [path[2], path[0], path[3], path[1]]
      break
    case 'CCW90':
      res = [path[1], path[3], path[0], path[2]]
      break
    case 'AboveBlackToWhite':
      res = [0, 0, path[2], path[3]]
      break
    case 'BelowBlackToWhite':
      res = [path[0], path[1], 0, 0]
      break
    case 'LeftBlackToWhite':
      res = [0, path[1], 0, path[3]]
      break
    case 'RightBlackToWhite':
      res = [path[0], 0, path[2], 0]
      break
    case 'AboveWhiteToBlack':
      res = [1, 1, path[2], path[3]]
      break
    case 'BelowWhiteToBlack':
      res = [path[0], path[1], 1, 1]
      break
    case 'LeftWhiteToBlack':
      res = [1, path[1], 1, path[3]]
      break
    case 'RightWhiteToBlack':
      res = [path[0], 1, path[2], 1]
      break
    case 'ReversedPhase0':
      res = [1 - path[0], path[1], path[2], path[3]]
      break
    case 'ReversedPhase1':
      res = [path[0], 1 - path[1], path[2], path[3]]
      break
    case 'ReversedPhase2':
      res = [path[0], path[1], 1 - path[2], path[3]]
      break
    case 'ReversedPhase3':
      res = [path[0], path[1], path[2], 1 - path[3]]
      break
    default:
      res = path
  }
  return res
}

export function saveAnswer(questionPaperId: string, answer: any) {
  return localforage.setItem(`${PREFIX}${questionPaperId}`, answer)
}

export function getAnswer(questionPaperId: string) {
  // localforage
  return localforage.getItem(`${PREFIX}${questionPaperId}`)
}

export function decodePath(str: string): [number, number, number, number] {
  const arr = str.split('').map((item) => parseInt(item))
  return [arr[0] || 0, arr[1] || 0, arr[2] || 0, arr[3] || 0]
}

export function formatTimeByNumber(m: number) {
  const miao = m % 60
  const fen = Math.floor(m / 60) % 60
  let str = ''
  if (m > 60 * 60) {
    const h = Math.floor(m / 60 / 60)
    str = `${h}:`
  }
  return `${str}${fen >= 10 ? fen : '0' + fen}:${miao >= 10 ? miao : '0' + miao}`
}

export function formatTime(start: string, end: string) {
  const startTime: number = new Date(start).getTime()
  const endTime: number = new Date(end).getTime()
  const m = Math.floor((endTime - startTime) / 1000)
  return formatTimeByNumber(m)
}

function startTranform(flash: string[], index: number = 0) {
  if (!flash || flash.length < 1) {
    return
  }
}

export function isThreeProblem(name: string): boolean {
  if (Subject.stereoscopic.some((sub) => sub.key === name)) {
    return true
  }
  return false
}

export function base64ImgtoFile(dataurl: string, filename = 'file') {
  const arr = dataurl.split(',')
  const res = arr[0].match(/:(.*?);/)
  const mime = res ? res[1] : ''
  const suffix = mime.split('/')[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  })
}

export function safeJsonParse(json: string) {
  try {
    return JSON.parse(json)
  } catch (error) {
    console.warn('jsonParse Error: ', error)
    return {}
  }
}
