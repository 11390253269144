import { memo } from 'react'
import './Moss.scss'

interface IMossProps {
  data: string[]
  isShowNumber?: boolean
}

export function MossItem({ item }: { item: string }) {
  return (
    <div className="moss-content">
      {item.split('').map((it) => {
        return (
          <div>
            {it === '0' && (
              <img width={25} height={25} src="https://edudata.computingis.fun/point.png" />
            )}
            {it === '1' && <img width={25} src="https://edudata.computingis.fun/line.png" />}
            {it === '2' && (
              <img height={15} width={25} src="https://edudata.computingis.fun/heng.png" />
            )}
          </div>
        )
      })}
    </div>
  )
}

function Moss(props: IMossProps) {
  const { data, isShowNumber = true } = props

  return (
    <div className="moss-container">
      {data &&
        data.map((item, index) => {
          return (
            <div className="moss-item">
              <MossItem item={item} />
              {isShowNumber && <span className="moss-number">{index}</span>}
            </div>
          )
        })}
    </div>
  )
}

export default memo(Moss)
