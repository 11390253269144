import * as React from 'react'
import { Link } from 'react-router-dom'
import './index.scss'
import MenuNav from './MenuNav'
import Logo from '../../assets/favicon.png'

export interface IHeaderNavType {
  name: string
  isAdmin?: boolean
  title: string
  redirect: string
}

export default function HeaderNav(props: IHeaderNavType) {
  return (
    <div className="HeaderNav">
      <div className="wrapper nav-box">
        <div className="left">
          <img alt="" src={Logo}></img>
          <Link to={props.redirect}>{props.title}</Link>
        </div>
        {props.isAdmin && <Link to="/admin/">管理后台</Link>}
        <MenuNav name={props.name} />
      </div>
    </div>
  )
}
