function E808A(params: {}) {
  return (
    <div>
      <p>
        点击“展示图例”按钮，在左侧图例区会出现AB两个图例，观察AB两个图，在右侧选项中选择与AB任意一个相同（包含旋转后）的选项
      </p>
      <p>注意：每个题仅能查看一次图例，图例显示时间为 3 秒</p>
    </div>
  )
}

export default E808A
