import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const COLOR_ENUM: any = {
  G: 'green',
  R: 'red',
  B: 'blue',
}

const ranDomColor = () => {
  return ['green', 'red', 'blue'][Math.round(Math.random() * 2)]
}

function E808B2({ data, width, height }: { data: string; width: number; height: number }) {
  const [colorList, setColorList] = useState<string[]>([])

  const [canvasId, setCanvasId] = useState<string>(uuidv4())

  useEffect(() => {
    if (data) {
      const arr: string[] = data.split('')
      if (arr.length === 9) {
        setColorList([...arr])
      }
    }
  }, [data])

  useEffect(() => {
    renderCanvas()
  }, [colorList])

  const renderCanvas = () => {
    if (colorList.length !== 9) {
      return
    }
    const canvasDom: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
    if (!canvasDom) {
      return
    }
    const ctx: CanvasRenderingContext2D = canvasDom.getContext('2d') as CanvasRenderingContext2D
    // 找出三个点位置
    const centerX = Math.floor(width / 2)
    const centerY = Math.floor(height / 2)

    // 三角形的三个定位点
    const top = [centerX, 2]
    const left = [2, height - 1]
    const right = [width - 2, height - 2]
    // 最长边
    const maxSkew = Math.floor((width + height) / 2 / 3.6)
    // 最短边
    const minSkew = Math.floor(maxSkew / 2)
    const hypotenuse = Math.floor(Math.sqrt(Math.pow(maxSkew, 2) - Math.pow(minSkew, 2)))

    const colors: string[] = colorList.map((c) => COLOR_ENUM[c] || 'rgba: 0,0,0,0')
    const addLong = Math.floor(width / 20) // 加号一半的长度

    ctx.clearRect(0, 0, width, height) // 清除画布

    const renderSymbol: any = {
      left: () => {
        ctx.beginPath()
        ctx.strokeStyle = colors[0]
        ctx.moveTo(top[0], top[1])
        ctx.lineTo(left[0], left[1])
        ctx.stroke()
        ctx.closePath()
      },
      right: () => {
        ctx.beginPath()
        ctx.strokeStyle = colors[1]
        ctx.moveTo(top[0], top[1])
        ctx.lineTo(right[0], right[1])
        ctx.stroke()
        ctx.closePath()
      },
      bottom: () => {
        ctx.beginPath()
        ctx.strokeStyle = colors[2]
        ctx.moveTo(left[0], left[1])
        ctx.lineTo(right[0], right[1])
        ctx.stroke()
        ctx.closePath()
      },

      // 加号
      X: (color: string, pos: [number, number]) => {
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.moveTo(pos[0] - addLong, pos[1])
        ctx.lineTo(pos[0] + addLong, pos[1])
        ctx.stroke()
        ctx.moveTo(pos[0], pos[1] - addLong)
        ctx.lineTo(pos[0], pos[1] + addLong)
        ctx.stroke()
        ctx.closePath()
      },

      // ➗
      Y: (color: string, pos: [number, number]) => {
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.moveTo(pos[0] - addLong, pos[1])
        ctx.lineTo(pos[0] + addLong, pos[1])
        ctx.stroke()
        ctx.closePath()
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.arc(pos[0], pos[1] + 3, 1, 0, Math.PI * 2, false)
        // ctx.moveTo(pos[0], pos[1] - 3)
        // ctx.lineTo(pos[0], pos[1] - 2)
        ctx.stroke()
        ctx.closePath()
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.arc(pos[0], pos[1] - 3, 1, 0, Math.PI * 2, false)
        // ctx.moveTo(pos[0], pos[1] + 3)
        // ctx.lineTo(pos[0], pos[1] + 2)
        ctx.stroke()
        ctx.closePath()
      },

      // ➗转90度
      Z: (color: string, pos: [number, number]) => {
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.moveTo(pos[0], pos[1] - addLong)
        ctx.lineTo(pos[0], pos[1] + addLong)
        ctx.stroke()
        ctx.closePath()
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.arc(pos[0] + 3, pos[1], 1, 0, Math.PI * 2, false)
        // ctx.moveTo(pos[0] - 3, pos[1])
        // ctx.lineTo(pos[0] - 2, pos[1])
        ctx.stroke()
        ctx.closePath()
        ctx.beginPath()
        ctx.strokeStyle = color
        ctx.arc(pos[0] - 3, pos[1], 1, 0, Math.PI * 2, false)
        // ctx.moveTo(pos[0] + 3, pos[1])
        // ctx.lineTo(pos[0] + 2, pos[1])
        ctx.stroke()
        ctx.closePath()
      },
      // 中心圆
      centerArc: () => {
        ctx.beginPath()
        ctx.strokeStyle = colors[6]
        ctx.arc(
          centerX,
          Math.floor((height / 3) * 2),
          Math.floor(Math.min(centerY, centerX) / 3.4),
          0,
          Math.PI * 2,
          false,
        )
        ctx.stroke()
        ctx.closePath()
      },
    }

    ctx.lineWidth = 2
    // 三角形左边线
    renderSymbol.left()
    // 三角形右边线
    renderSymbol.right()
    // 三角形底部线
    renderSymbol.bottom()

    ctx.lineWidth = 1
    // 顶部形状
    renderSymbol[colorList[6]](colors[3], [top[0], top[1] + hypotenuse])
    // 右侧形状
    renderSymbol[colorList[7]](colors[4], [right[0] - hypotenuse, right[1] - minSkew])
    // 左侧形状
    renderSymbol[colorList[8]](colors[5], [left[0] + hypotenuse, left[1] - minSkew])
  }

  if (colorList.length !== 9) {
    return null
  }
  return <canvas id={canvasId} width={width} height={height}></canvas>
}
export default E808B2
