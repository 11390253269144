/**
 * 一次提交一题答案的题型
 * 此组件会被 考试、练习、回看共同引用 新加 逻辑时需要区分上述三个状态
 */
import { useEffect, useState } from 'react'
import './index.scss'
import { Button, Drawer, Card, Image, Modal, InputNumber, message } from 'antd'
import { useHistory, withRouter, useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'
import { QuestionType } from '../../../constants'
import RhombCanvas from '../../../utils/rhombCanvas'
import {
  decodePath,
  formatTime,
  formatTimeByNumber,
  transformRule,
  safeJsonParse,
} from '../../../utils/util'
import { TRANSFORM_RULE_TEXT } from '../../../enum/rule'
import { IHistoryExamResult, IAnswerItem } from '../../../types/exam'
import {
  queryCurrentQuestion,
  submitAnswer,
  queryHistoryExam,
  queryHistoryExamResult,
} from '../../../api/answer'
import Cube from '../../../component/Cube'
// import CountDownTime from '../../../component/CountDownTime'
import E808A from '../../../component/E808A'
import E808E from '../../../component/E808E'
import Moss, { MossItem } from '../../../component/Moss'
import WhackAMoles from '../../../component/WhackAMoles'

import TitleFlashDiamond from '../../../component/question/title/FlashDiamond'
import TitleImageTransform from '../../../component/question/title/ImageTransform'
import TitleBlockRotate from '../../../component/question/title/BlockRotate'
import TitleFillWall from '../../../component/question/title/FillWall'
import TitleCubeComplement from '../../../component/question/title/CubeComplement'
import TitleE808A from '../../../component/question/title/E808A'
import TitleE808APlus from '../../../component/question/title/E808APlus'
import TitleE808E from '../../../component/question/title/E808E'
import TitleE808EPlus from '../../../component/question/title/E808EPlus'
import TitleLogicalReasoning from '../../../component/question/title/LogicalReasoning'
import TitleMoss from '../../../component/question/title/Moss'
import TitleWhackAMole from '../../../component/question/title/WhackAMole'

import { isArray, get, isFunction } from 'lodash'

const TIME = 1000 // 闪烁间隔时间
const ANS_LETTER = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
// 单选题型
const RadioChoice = ['FlashDiamond', 'ImageTransform', 'FillWall', 'CubeComplement']
// 多选
const MultipleChoiceAsString = ['BlockRotate', 'LogicalReasoning']
// 几何旋转类题型
const Rotate = ['BlockRotate', 'FillWall', 'CubeComplement']

function decodeJson(json: string) {
  try {
    return JSON.parse(json)
  } catch (error) {
    console.error('JSON.parse 解析失败: ', error)
    console.log('json入参: ', json)
    return []
  }
}

function MultipleQuestion(props: any) {
  const history = useHistory()
  const query: any = queryString.parse(props.location.search)
  // const query = props.location.query;
  let id = ''
  if (props.examId) {
    id = props.examId
  } else if (query.examId) {
    id = query.examId
  }
  let isDebug = false
  if (query.isDebug) {
    isDebug = true
  }
  // 是否展示答案 回看模式下为true
  const isRes = !!props.isRes
  // 是否是考试状态
  const isExam = !!props.isExam
  const eaxmCallBack = props.callback || (() => {})

  const [current, setCurrent] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  // 试卷结束时间
  const [endTimeStamp, setEndTimeStamp] = useState<number>(0)
  // 题型
  const [subject, setSubject] = useState<string>('')
  // 试题
  const [list, setList] = useState<any[]>([])
  // 是否显示查看推导
  const [isShowSnapshots, setIsShowSnapshots] = useState<boolean>(false)
  // 当前题显示的初始状态
  const [initial, setInitial] = useState<string>('')
  // 是否正在变化
  const [flashing, setFlashing] = useState<boolean>(false)
  // 当前题是否播放动画结束
  const [flashEnd, setFlashEnd] = useState<boolean>(false)
  // 当前高亮的key
  const [activeKey, setActiveKey] = useState<string>('')
  // 当前选择项
  const [select, setSelect] = useState<string>('')
  // 单前选择的第几项
  const [selectIndex, setSelectIndex] = useState<number[]>([])
  // 倒计时显示的值
  const [showEndTime, setShowEndTime] = useState<string>('')
  // 倒计时还剩多少秒
  const [endTime, setEndTime] = useState<number>(-1)
  // 提交按钮的loading
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [mossVisible, setMossVisible] = useState<boolean>(false)
  const [buttonVisible, setButtonVisible] = useState<boolean>(true)

  // 显示答案模式的答案对象
  const [answers, setAnswers] = useState<{ [key: string]: IAnswerItem }>({})

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    initCurrentAnswer()
  }, [subject, current])

  let timer: any = null
  useEffect(() => {
    countDownTime()
    return () => {
      timer && clearTimeout(timer)
    }
  }, [endTimeStamp])

  useEffect(() => {
    if (endTime === 0) {
      endTimeCallBack()
    }
  }, [endTime])

  const countDownTime = () => {
    timer && clearTimeout(timer)
    if (!endTimeStamp) {
      return
    }
    const now = Date.now()
    const s = Math.floor((endTimeStamp - now) / 1000)
    if (s < 0) {
      return
    }
    setShowEndTime(formatTimeByNumber(s))
    setEndTime(s)
    timer = setTimeout(() => {
      countDownTime()
      timer = null
    }, 1000)
  }

  const onlyUpdateSelect = (s: string) => {
    if (isRes) {
      return
    }
    setSelect(s)
  }
  const updateSelect = (key: string) => {
    if (isRes) {
      return
    }
    const arr = select.split(',')
    const index = arr.indexOf(key)
    if (index > -1) {
      arr.splice(index, 1)
      setSelect(arr.join(','))
    } else {
      setSelect([...arr, key].filter((item) => !!item).join(','))
    }
  }
  const updateSelectIndex = (index: number) => {
    if (isRes) {
      return
    }
    const activeAnswer = getActive()
    const i = selectIndex.indexOf(index)
    const arr = [...selectIndex]
    if (i > -1) {
      arr.splice(i, 1)
    } else {
      arr.push(index)
    }
    setSelectIndex(arr)
    setSelect(
      arr
        .map((j) => {
          return activeAnswer.answers[j] || ''
        })
        .join(','),
    )
  }

  const getActive = () => {
    return list.find(
      ((item: any, index: number) => (item.questionIndex || index) === current) || {},
    )
  }

  // 倒计时技术自动提交答案
  const endTimeCallBack = () => {
    setTimeout(() => {
      submitAnswer({ examId: id, answer: select, current: current, force: 'force' }).then(() => {
        getAnswer()
      })
    }, 1000)
  }

  const handleNext = async () => {
    if (isRes) {
      setCurrent(current + 1)
      return
    }
    if (!select) {
      return
    }
    setSaveLoading(true)
    // 1. 提交答案
    let _select = select
    if (subject === 'MossCopy' || subject === 'Moss' || subject === 'HarderMoss') {
      _select = select.split('').join(',')
    }
    const res = await submitAnswer({ examId: id, answer: _select, current: current }).finally(
      () => {
        setSaveLoading(false)
      },
    )
    if (res.code === 5006) {
      getAnswer()
      return
    }
    // 2. 更新页面
    setCurrent(current + 1)
    setButtonVisible(true)
  }

  const initCurrentAnswer = () => {
    setFlashing(false)
    setFlashEnd(false)
    const activeAnswer = getActive()
    if (!activeAnswer) {
      return
    }
    // setCurrentFlash(activeAnswer.flash || [])
    if (isRes) {
      const ans: string = get(answers, `${activeAnswer.questionIndex}.answer`, '') as string
      if (RadioChoice.some((s) => s === subject)) {
        // 单选
        setSelect(ans)
      } else if (MultipleChoiceAsString.some((s) => s === subject)) {
        // 多选 答案以英文逗号分隔的形式存储
        setSelect(ans)
      } else {
        // 多选 答案以下标的形式存储
        const arr = ans.split(',')
        const indexs = arr
          .map((s) => {
            return (activeAnswer.answer || []).indexOf(s)
          })
          .filter((i) => i !== -1)
        setSelectIndex(indexs)
        setSelect(ans)
      }
    } else {
      // 下一题的时候重置初始状态
      setSelect('')
      setSelectIndex([])
    }
    setInitial(activeAnswer.initial)

    // 舒尔特方格
    if (subject === 'FlashDiamond') {
      renderCanvas()
    }
  }
  const renderCanvas = () => {
    const activeAnswer = getActive()
    const { initial, candidateAnswers } = activeAnswer
    // 渲染原始图菱形
    const canvas1 = new RhombCanvas(document.getElementById('answer-canvas') as HTMLCanvasElement, {
      showLabel: true,
    })
    canvas1.draw(decodePath(initial))

    // 渲染答案的图形
    ;(candidateAnswers || []).forEach((item: string, index: number) => {
      const canvas = new RhombCanvas(
        document.getElementById('res_canvas_' + index) as HTMLCanvasElement,
      )
      canvas.draw(decodePath(item))
    })
  }

  const getAnswer = async () => {
    if (isExam) {
      eaxmCallBack()
      return
    }
    queryHistoryExam({ examId: id }).then((res) => {
      const { rightAnswers, wrongAnswers, remains, starTime, endTime } = res.data
      // const noRes = ['E808APlus', 'E808A', 'E808EPlus', 'E808E'].indexOf(subject) !== -1
      let noRes = false
      let contentDiv
      if (subject === 'WhackAMole') {
        noRes = true
        contentDiv = (
          <div>
            <p>打中地鼠个数：{rightAnswers || 0}个</p>
            <p>未打中地鼠个数：{wrongAnswers || 0}个</p>
            <p>未打：{remains || 0}个 </p>
            <p>用时：{formatTime(starTime, endTime)}</p>
          </div>
        )
      } else {
        contentDiv = (
          <div>
            <p>正确：{rightAnswers || 0}题</p>
            <p>错误：{wrongAnswers || 0}题</p>
            <p>未答：{remains || 0}题</p>
            <p>用时：{formatTime(starTime, endTime)}</p>
          </div>
        )
      }
      Modal.confirm({
        title: '答题结果',
        content: contentDiv,
        cancelText: '回到首页',
        okText: noRes ? '确定' : '查看题解',
        onOk: () => {
          if (noRes) {
            history.replace('/')
          } else {
            history.replace(`/answer-history?examId=${id}`)
          }
        },
        onCancel: () => {
          history.replace('/')
        },
      })
    })
  }

  const handleStart = (subject: string) => {
    if (flashing || flashEnd) {
      return
    }
    if (subject === 'FlashDiamond') {
      start(0, decodePath(initial))
    } else if (subject === 'E808A') {
      startE808(1000 * 3)
    } else if (subject === 'E808E') {
      startE808(1000 * 10)
    }
  }

  const startE808 = (time: number) => {
    setFlashing(true)
    setTimeout(() => {
      setFlashing(false)
      setFlashEnd(true)
    }, 3000)
  }

  const start = (index: number = 0, arr: [number, number, number, number]) => {
    const activeAnswer = getActive()
    if (!activeAnswer) {
      return
    }
    setFlashing(true)
    const currentFlash = activeAnswer.flash
    if (currentFlash.length > index) {
      setActiveKey(currentFlash[index])
      const res = transformRule(arr, currentFlash[index])
      setTimeout(() => {
        start(index + 1, res)
      }, TIME)
      if (currentFlash.length - 1 === index) {
        console.log(`result: `, res.join(''))
      }
    } else {
      setActiveKey('')
      setFlashing(false)
      setFlashEnd(true)
    }
  }
  const handleSubmit = () => {
    setSaveLoading(true)
    // 提交答案
    submitAnswer({ examId: id, answer: select, current: current })
      .then((res) => {
        getAnswer()
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }
  const handleBack = () => {
    history.replace('/')
  }

  const initData = () => {
    if (isRes) {
      // 查看答案模式
      queryHistoryExamResult({ examId: id }).then((res) => {
        const data = res.data
        // console.log(`queryHistoryExamResult res: `, data)
        // 几何旋转图形解析题干
        if (Rotate.includes(data.subject) && data.runtimeParams) {
          ;(data.list || []).forEach((item, index) => {
            const parseData = safeJsonParse(data.runtimeParams?.[index] || '') as {
              question: string
              answers: string[]
            }
            item.questionImage = parseData?.question || ''
            item.answersImage = parseData?.answers || []
          })
        }

        setAnswers(data.answers)
        setCurrent(0)
        setTotal(data.total)
        setList(data.list)
        setSubject(data.subject)
      })
    } else {
      // 答题模式
      // 先请求看当前有没有正在进行中的题，没有的话显示一个开始考试按钮，有的情况下初始化单前项
      queryCurrentQuestion({ examId: id }).then(({ data, code }) => {
        if (code !== 0) {
          history.replace('/')
          return
        }
        if (data.examId) {
          if (Rotate.includes(data.subject) && data.runtimeParams) {
            ;(data.list || []).forEach((item: any, index: number) => {
              const parseData = safeJsonParse(data.runtimeParams?.[index] || '') as {
                question: string
                answers: string[]
              }
              console.log(parseData)
              item.questionImage = parseData?.question || ''
              item.answersImage = parseData?.answers || []
            })
          }
          setCurrent(data.current)
          setTotal(data.total)
          setList(data.list)
          setEndTimeStamp(data.endTimeStamp)
          setSubject(data.subject)
        } else {
          history.replace('/')
        }
      })
    }
  }

  const onCancel = () => {
    Modal.confirm({
      content: '已经到最后一题，点击ok关闭',
      onOk: () => {
        isFunction(props.cancel) && props.cancel()
      },
    })
  }

  const ExamDescription = (subject: string, activeAnswer: any) => {
    return {
      FlashDiamond: <TitleFlashDiamond total={total} />,
      ImageTransform: <TitleImageTransform />,
      BlockRotate: <TitleBlockRotate />,
      FillWall: <TitleFillWall />,
      CubeComplement: <TitleCubeComplement />,
      E808A: <TitleE808A />,
      E808APlus: <TitleE808APlus />,
      E808E: <TitleE808E />,
      E808EPlus: <TitleE808EPlus />,
      Moss: <TitleMoss />,
      MossCopy: <TitleMoss />,
      HarderMoss: <TitleMoss />,
      WhackAMole: <TitleWhackAMole />,
      LogicalReasoning: <TitleLogicalReasoning activeAnswer={activeAnswer} />,
    }[subject]
  }

  const DescAndAction = (subject: string, activeAnswer: any) => {
    if (subject === 'FlashDiamond') {
      // 舒尔特方格
      // 答案测试链接 http://localhost:3000/#/answer-history?examId=222516562597652944521be627
      // TODO 有时候返回的选项中不存在用户做题时提交错的答案 待后端修改后联调
      const isSuccess = select === activeAnswer.rightAnswer
      const gridList = (activeAnswer.board || []).map((item: any) => {
        const className: string = `grid-item${activeKey === item ? ' active' : ''}`
        return (
          <div key={item} className={className}>
            {((TRANSFORM_RULE_TEXT as any)[subject] as any)[item] || '不变化'}
          </div>
        )
      })
      const resultList = (activeAnswer.candidateAnswers || []).map(
        (item: string, index: number) => {
          return (
            <div
              key={index}
              className={
                'res-item' +
                (item === select ? ' active' : '') +
                (isRes && (item === activeAnswer.rightAnswer || (isSuccess && select === item))
                  ? ' success'
                  : '') +
                (isRes && item === select && !isSuccess ? ' error' : '')
              }
              onClick={() => onlyUpdateSelect(item)}
            >
              <canvas id={'res_canvas_' + index} width="100" height="130"></canvas>
            </div>
          )
        },
      )
      return (
        <div>
          <div className="flex-box">
            <canvas id="answer-canvas" width="300" height="390"></canvas>
            <div className="grid-box">
              <div>
                <Button type="primary" disabled={flashEnd} onClick={() => handleStart(subject)}>
                  开始
                </Button>
              </div>
              <div className="grid">{gridList}</div>
            </div>
          </div>
          <div className="result-box">{resultList}</div>
        </div>
      )
    } else if (subject === 'ImageTransform') {
      // 图形变换
      // http://localhost:3000/#/answer-history?examId=115316562612512591d12d5627
      const isSuccess = select === activeAnswer.rightAnswerTag
      const RuleItem = (activeAnswer.instructionBind
        ? [...(Object.keys(activeAnswer.instructionBind) || [])]
        : []
      ).map((rule, key) => {
        return (
          <div className="rule-item" key={key}>
            <span className="inline-center">——</span>
            <img
              className="inline-center rule-image"
              src={activeAnswer.instructionBind[rule]}
              alt=""
            />
            <span className="inline-center">
              {((TRANSFORM_RULE_TEXT as any)[subject] as any)[rule]}
            </span>
          </div>
        )
      })
      const ImageTransformAns = (activeAnswer.options
        ? [...(Object.keys(activeAnswer.options) || [])]
        : []
      ).map((key) => {
        return (
          <div
            key={key}
            className={
              'ans-item' +
              (key === select ? ' active' : '') +
              (isRes && (key === activeAnswer.rightAnswerTag || (isSuccess && select === key))
                ? ' success'
                : '') +
              (isRes && key === select && !isSuccess ? ' error' : '')
            }
            onClick={() => onlyUpdateSelect(key)}
          >
            {(activeAnswer.options[key] || []).map((url: string, optionKey: number) => {
              return (
                <div className="ans-item-i" key={optionKey}>
                  <img className="rule-image" src={url} alt="" />
                </div>
              )
            })}
            <div className="ans-desc">{key || ''}</div>
          </div>
        )
      })
      const ImageTransformExam = (activeAnswer.questions || []).map(
        (question: any, questionKey: number) => {
          return (
            <div className="question-item" key={questionKey}>
              <img className="rule-image inline-center" src={question.init} alt="" />
              <span className="inline-center">——</span>
              {(question.transforms || []).map((k: string, i: number) => {
                return (
                  <span className="inline-center" key={i}>
                    {i > 0 ? <span className="inline-center">——</span> : ''}
                    <img
                      className="rule-image inline-center"
                      src={activeAnswer.instructionBind[k]}
                      alt=""
                    />
                  </span>
                )
              })}
            </div>
          )
        },
      )
      return (
        <div className="image-transform-rule">
          <Card className="image-transform-card" title="操作规则">
            <div className="rules">{RuleItem}</div>
          </Card>
          <div className="ans-and-example">
            <div className="example-box">{ImageTransformExam}</div>
            <div className="ans-box">{ImageTransformAns}</div>
          </div>
        </div>
      )
    } else if (subject === 'BlockRotate') {
      // 几何旋转
      // http://localhost:3000/#/answer-history?examId=21211656261590531094c12627
      const rightAnswer = activeAnswer.rightAnswer
      const successRes = select.split(',').filter((key) => rightAnswer.indexOf(key) > -1)
      const errorRes = select.split(',').filter((key) => successRes.indexOf(key) === -1)
      return (
        <div className="block-rotate-box">
          <div className="block-rotate-example">
            {isExam ? (
              <img
                style={{ width: '400px', height: '400px' }}
                src={activeAnswer.questionImage}
                alt=""
              />
            ) : (
              <Cube data={activeAnswer.question} width={400} height={400} rotateCamera={false} />
            )}
          </div>
          <div className="answers">
            {activeAnswer.answers.map(
              (itemData: { x: number; y: number; z: number }[], index: number) => {
                const ansLetter: string = ANS_LETTER[index]
                return (
                  <div
                    key={ansLetter}
                    className={
                      'ans-item' +
                      (select.split(',').indexOf(ansLetter) !== -1 ? ' active' : '') +
                      (isRes
                        ? (successRes.indexOf(ansLetter) !== -1 ||
                          rightAnswer.indexOf(ansLetter) !== -1
                            ? ' success'
                            : '') + (errorRes.indexOf(ansLetter) !== -1 ? ' error' : '')
                        : '')
                    }
                  >
                    <div className="ans-item-cube">
                      {isExam ? (
                        <img
                          style={{ width: '170px', height: '170px' }}
                          src={activeAnswer?.answersImage?.[index]}
                          alt=""
                        />
                      ) : (
                        <Cube data={itemData} width={170} height={170} />
                      )}
                    </div>
                    <div className="ans-desc" onClick={() => updateSelect(ansLetter)}>
                      {ansLetter || ''}
                    </div>
                  </div>
                )
              },
            )}
          </div>
        </div>
      )
    } else if (subject === 'FillWall') {
      // http://localhost:3000/#/answer-history?examId=182816562620305985c01c1627
      const isSuccess = select === activeAnswer.rightAnswer
      return (
        <div className="block-rotate-box">
          <div className="block-rotate-example">
            {isExam ? (
              <img
                style={{ width: '300px', height: '300px' }}
                src={activeAnswer.questionImage}
                alt=""
              />
            ) : (
              <Cube data={activeAnswer.question} width={300} height={300} rotateCamera={false} />
            )}
          </div>
          <div className="answers">
            {activeAnswer.answers.map(
              (itemData: { x: number; y: number; z: number }[], index: number) => {
                const ansLetter: string = ANS_LETTER[index]
                return (
                  <div
                    key={ansLetter}
                    className={
                      'ans-item' +
                      (select.split(',').indexOf(ansLetter) !== -1 ? ' active' : '') +
                      (isRes &&
                      (ansLetter === activeAnswer.rightAnswer ||
                        (isSuccess && select === ansLetter))
                        ? ' success'
                        : '') +
                      (isRes && ansLetter === select && !isSuccess ? ' error' : '')
                    }
                  >
                    <div className="ans-item-cube">
                      {isExam ? (
                        <img
                          style={{ width: '200px', height: '200px' }}
                          src={activeAnswer?.answersImage?.[index]}
                          alt=""
                        />
                      ) : (
                        <Cube data={itemData} rotateCamera={false} width={200} height={200} />
                      )}
                    </div>
                    <div className="ans-desc" onClick={() => onlyUpdateSelect(ansLetter)}>
                      {ansLetter || ''}
                    </div>
                  </div>
                )
              },
            )}
          </div>
        </div>
      )
    } else if (subject === 'CubeComplement') {
      // http://localhost:3000/#/answer-history?examId=2587165626230527938bc33627
      const isSuccess = select === activeAnswer.rightAnswer
      return (
        <div className="block-rotate-box">
          <div className="block-rotate-example">
            {isExam ? (
              <img
                style={{ width: '300px', height: '300px' }}
                src={activeAnswer.questionImage}
                alt=""
              />
            ) : (
              <Cube
                data={activeAnswer.question}
                width={300}
                height={300}
                rotateCamera={false}
                isHand={false}
              />
            )}
          </div>
          <div className="answers">
            {activeAnswer.answers.map(
              (itemData: { x: number; y: number; z: number }[], index: number) => {
                const ansLetter: string = ANS_LETTER[index]
                return (
                  <div
                    key={ansLetter}
                    className={
                      'ans-item' +
                      (select.split(',').indexOf(ansLetter) !== -1 ? ' active' : '') +
                      (isRes &&
                      (ansLetter === activeAnswer.rightAnswer ||
                        (isSuccess && select === ansLetter))
                        ? ' success'
                        : '') +
                      (isRes && ansLetter === select && !isSuccess ? ' error' : '')
                    }
                  >
                    <div className="ans-item-cube">
                      {isExam ? (
                        <img
                          style={{ width: '180px', height: '180px' }}
                          src={activeAnswer?.answersImage?.[index]}
                          alt=""
                        />
                      ) : (
                        <Cube
                          data={itemData}
                          rotateCamera={false}
                          width={180}
                          height={180}
                          isHand={false}
                        />
                      )}
                    </div>
                    <div className="ans-desc" onClick={() => onlyUpdateSelect(ansLetter)}>
                      {ansLetter || ''}
                    </div>
                  </div>
                )
              },
            )}
          </div>
        </div>
      )
    } else if (subject === 'E808A' || subject === 'E808APlus') {
      // E808A 图例显示3s
      // E808APlus 一直显示图例
      const isE808APlus = subject === 'E808APlus'
      const rightAnswer = activeAnswer.rightAnswers
      const successRes = select.split(',').filter((key) => rightAnswer.indexOf(key) > -1)
      const errorRes = select.split(',').filter((key) => successRes.indexOf(key) === -1)
      return (
        <div>
          {!isE808APlus && !isRes ? (
            <div className="margin-b-10">
              <Button disabled={flashEnd || flashing} onClick={() => handleStart(subject)}>
                展示图例
              </Button>
            </div>
          ) : (
            ''
          )}
          <div className="e808a-box">
            <div className="example">
              <p>图例区</p>
              {(activeAnswer.questions || []).map((item: string, index: number) => {
                return (
                  <div key={index}>
                    <div
                      className={
                        'example-item' + (flashing || isE808APlus || isRes ? ' active' : '')
                      }
                    >
                      <E808A data={item} width={100} height={90} />
                    </div>
                    <p className="legend">{ANS_LETTER[index]}</p>
                  </div>
                )
              })}
            </div>
            <div className="answer-sheet">
              <p>答题区</p>
              <div className="answers">
                {(activeAnswer.answers || []).map((item: string, index: number) => {
                  const ansLetter: string = ANS_LETTER[index]
                  return (
                    <div
                      className={
                        'ans-item' +
                        (select.split(',').indexOf(ansLetter) !== -1 ? ' active' : '') +
                        (isRes
                          ? (successRes.indexOf(ansLetter) !== -1 ||
                            rightAnswer.indexOf(ansLetter) !== -1
                              ? ' success'
                              : '') + (errorRes.indexOf(ansLetter) !== -1 ? ' error' : '')
                          : '')
                      }
                      key={index}
                    >
                      <E808A data={item} width={60} height={50} />
                      <div className="ans-desc" onClick={() => updateSelect(ansLetter)}>
                        {ANS_LETTER[index] || ''}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (subject === 'E808E' || subject === 'E808EPlus') {
      // E808E 图例显示10s
      // E808EPlus 一直显示图例
      const isE808EPlus = subject === 'E808EPlus'
      let exampleE800E = 'HHHHHH'
      if (isE808EPlus || flashing) {
        exampleE800E = activeAnswer.question
      } else if (flashEnd) {
        exampleE800E = `${activeAnswer.question || ''}`.slice(0, 3) + 'HHH'
      }
      const rightAnswer = activeAnswer.rightAnswers
      const successRes = select.split(',').filter((key) => rightAnswer.indexOf(key) > -1)
      const errorRes = select.split(',').filter((key) => successRes.indexOf(key) === -1)
      return (
        <div>
          {!isE808EPlus ? (
            <div className="margin-b-10">
              <Button disabled={flashEnd || flashing} onClick={() => handleStart(subject)}>
                展示图例
              </Button>
            </div>
          ) : (
            ''
          )}
          <div className="e808a-box">
            <div className="example e808e">
              <p>图例区</p>
              <div className={'example-item active'}>
                <E808E data={exampleE800E} width={100} height={90} />
              </div>
            </div>
            <div className="answer-sheet e808e">
              <p>答题区</p>
              <div className="answers">
                {flashEnd || isE808EPlus ? (
                  (activeAnswer.answers || []).map((item: string, index: number) => {
                    const ansLetter = ANS_LETTER[index]
                    return (
                      <div
                        className={
                          'ans-item' +
                          (select.split(',').indexOf(ansLetter) !== -1 ? ' active' : '') +
                          (isRes
                            ? (successRes.indexOf(ansLetter) !== -1 ||
                              rightAnswer.indexOf(ansLetter) !== -1
                                ? ' success'
                                : '') + (errorRes.indexOf(ansLetter) !== -1 ? ' error' : '')
                            : '')
                        }
                        key={index}
                      >
                        <E808E data={item} width={60} height={50} />
                        <div className="ans-desc" onClick={() => updateSelect(ansLetter)}>
                          {ANS_LETTER[index] || ''}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <p className="e808e-hint">点击展示图例，图例展示完成后出现选项</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (subject === 'LogicalReasoning') {
      // 答案查看测试链接 http://localhost:3000/#/answer-history?examId=12431656258361156f99ddd627
      // 题目示意图
      const questionField = decodeJson(activeAnswer.question?.questionField || '')
      // 选项示意图
      const optionField = decodeJson(activeAnswer.question?.optionField || '')
      // 答案示意图 在答题回看中展示
      const analysisFiled = decodeJson(activeAnswer.question?.analysisFiled || '')
      // 选项值
      const optionsList = (activeAnswer.question?.questionOption || '').split(',')
      const rightAnswer = (activeAnswer.rightAnswer || '').split(',')
      const successRes = select.split(',').filter((key) => rightAnswer.indexOf(key) > -1)
      const errorRes = select.split(',').filter((key) => successRes.indexOf(key) === -1)

      return (
        <div className="logical-reasoning">
          <div className="question-images">
            {isArray(questionField) &&
              questionField.map((imageUrl, index) => {
                return imageUrl ? <Image src={imageUrl} key={index}></Image> : ''
              })}
          </div>

          <br />
          <p>请选择答案：</p>
          <div className="question-images">
            {isArray(optionField) &&
              optionField.map((imageUrl, index) => {
                return imageUrl ? <Image src={imageUrl} key={index}></Image> : ''
              })}
          </div>
          {isRes && isArray(analysisFiled) ? (
            <div>
              {analysisFiled.map((imageUrl, index) => {
                return imageUrl ? <Image src={imageUrl} key={index}></Image> : ''
              })}
            </div>
          ) : (
            ''
          )}
          <div className="answers-only-text">
            {optionsList.map((key: string, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    'ans-text' +
                    (select.split(',').indexOf(key) !== -1 ? ' active' : '') +
                    (isRes
                      ? (successRes.indexOf(key) !== -1 || rightAnswer.indexOf(key) !== -1
                          ? ' success'
                          : '') + (errorRes.indexOf(key) !== -1 ? ' error' : '')
                      : '')
                  }
                  onClick={() => updateSelect(key)}
                >
                  {key || ''}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else if (subject === 'Moss' || subject === 'MossCopy' || subject === 'HarderMoss') {
      return (
        <div className="group-questions">
          <div className="group-question-box">
            <div className="group-question">
              {subject === 'MossCopy' && (
                <Button
                  disabled={!buttonVisible}
                  onClick={() => {
                    setMossVisible(true)
                    setButtonVisible(false)
                    setTimeout(() => {
                      setMossVisible(false)
                    }, 8000)
                  }}
                >
                  显示题目
                </Button>
              )}
              {(mossVisible || subject === 'Moss' || subject === 'HarderMoss') && (
                <Moss data={activeAnswer.mappings} />
              )}
              <div className="moss-question-box">
                {activeAnswer.questions.map((item: string, index: number) => {
                  return (
                    <div className="moss-question-item" key={index}>
                      <MossItem item={item} />
                      <div className="moss-number">
                        {new Array(10).fill(0).map((_, i) => {
                          return (
                            <div
                              key={i}
                              className={[
                                'number-item',
                                `${
                                  !isRes && i == Number(select[index] ?? -1)
                                    ? 'number-item-selected'
                                    : ''
                                }`,
                                `${
                                  isRes &&
                                  Number(answers[current]?.answer?.split(',')[index]) !==
                                    activeAnswer.rightAnswers[index] &&
                                  Number(answers[current]?.answer.split(',')[index]) === i
                                    ? 'number-item-wrong'
                                    : ''
                                }`,
                                // `${
                                //   isRes && Number(answers[current].answer.split(',')[index]) !== activeAnswer.rightAnswers[index] && Number(answers[current].answer.split(',')[index]) === i
                                //     ? 'number-item-wrong'
                                //     : ''
                                // }`,
                                `${
                                  isRes && Number(activeAnswer.rightAnswers[index]) === i
                                    ? 'number-item-right'
                                    : ''
                                }`,
                              ].join(' ')}
                              onClick={() => {
                                const tempSelect = select.split('')
                                tempSelect[index] = `${i}`
                                setSelect(tempSelect.join(''))
                              }}
                            >
                              {i}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else if (subject === 'WhackAMole') {
      return (
        <div className="group-questions">
          <div className="group-question-box">
            <div className="group-question">
              <WhackAMoles
                data={activeAnswer.rightAnswers}
                addSelect={(select) => {
                  if (select.length >= activeAnswer.rightAnswers.length) {
                    Modal.confirm({
                      title: '提示',
                      content:
                        '恭喜本轮闯关完成！请点击"进入下一题"进行下一轮测试，注意地鼠的出现规律可能会发生变化。',
                      okText: '确认',
                      cancelText: '取消',
                      onOk: () => {
                        handleNext()
                      },
                    })
                  } else {
                    setSelect(select.toString())
                  }
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  const activeAnswer = getActive()

  return (
    <div className="multiple-question">
      {/* 题目描述 */}
      {ExamDescription(subject, activeAnswer)}

      {/* 公用 */}
      <p className="current-test">
        当前第{current + 1}题，共 {total} 题
        {/* <CountDownTime endTimeStamp={endTimeStamp} callback={() => endTimeCallBack()} /> */}
        <span>{showEndTime ? '，剩余时间：' + showEndTime : ''}</span>
      </p>

      {/* 题目示意 包含变化区和展示区 */}
      {DescAndAction(subject, activeAnswer)}

      {/* 底部提交按钮 */}
      <div className="footer">
        <div className="no-answer">{isRes && !select ? <span>（本题未作答）</span> : ''}</div>
        {current < total - 1 ? (
          <div>
            <Button
              type="primary"
              disabled={!select && !isRes}
              loading={saveLoading}
              onClick={() => handleNext()}
            >
              下一题
            </Button>
            {isDebug && (
              <Button type="primary" onClick={() => setIsShowSnapshots(true)}>
                查看推导
              </Button>
            )}
          </div>
        ) : isRes ? (
          isExam ? (
            <Button type="primary" onClick={() => onCancel()}>
              下一题
            </Button>
          ) : (
            <Button type="primary" onClick={() => handleBack()}>
              回到首页
            </Button>
          )
        ) : (
          <Button type="primary" loading={saveLoading} onClick={() => handleSubmit()}>
            提交答案
          </Button>
        )}
      </div>
      <Drawer
        title="推导过程"
        height={280}
        width={1024}
        placement="top"
        visible={isShowSnapshots}
        onClose={() => {
          setIsShowSnapshots(false)
        }}
      >
        <div className="imagess">
          {activeAnswer?.snapshots &&
            activeAnswer.snapshots.map((item: any, key: number) => {
              return (
                <div className="snapshots-images" key={key}>
                  <Image width={30} src={item[0]} />
                  <Image width={30} src={item[1]} />
                  <Image width={30} src={item[2]} />
                  <Image width={30} src={item[3]} />
                </div>
              )
            })}
        </div>
      </Drawer>
    </div>
  )
}

export default withRouter(MultipleQuestion)
