import * as React from 'react'
import { createContext, useReducer } from 'react'
import { IUserInfo } from '../types/user'
type IAction = ISetUserInfo

interface ISetUserInfo {
  type: string
  payload: IStore['userInfo']
}

interface IStore {
  userInfo: IUserInfo
}

interface StoreContext {
  state: IStore
  dispatch(param: ISetUserInfo): void
}

export function stateReducer(draft: IStore, action: IAction): any {
  const { payload } = action
  switch (action.type) {
    case 'setUserInfo': {
      return { ...draft, userInfo: payload }
    }
    default:
      throw new Error('dispatch type is not exit!')
  }
}

const initialState: IStore = {
  userInfo: {} as IUserInfo,
}

export const GlobalStoreContext = createContext<StoreContext>({
  state: initialState,
  dispatch: () => {},
})

export const GlobalStoreProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const [state, dispatch] = useReducer(stateReducer, initialState)
  return (
    <GlobalStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStoreContext.Provider>
  )
}
export default { GlobalStoreProvider, GlobalStoreContext }
